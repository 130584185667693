import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { EditorState } from "draft-js";


//kp components
import { ButtonTertiary, KPIconBtn } from '../../generalUI/KPButtons'
import Loader from '../../generalUI/Loader';
//kp icons
import Delete from '../../icons/Delete';

//kp colors
import { greyColor10, white } from '../../../sass/vars.scss';
//utils
import { removeBlock, replaceEntityData } from '../../../utils/richInputUtils';
import { imageUploader } from '../../../utils/general'

const KPRichInlineImage = props => {



  const handleRemoveImage = () => {
    const { editor, block, } = props;
    removeBlock(editor, block);
  }

  useEffect(() => {
    if (props.formData) {
      imageUploader(props.formData, { type: 'images' }, (status, resData) => {
        replaceEntityData(
          props.editor,
          props.entityKey,
          { src: resData[0].publicUrl, loading: false } //replace the image blocks entity data with actual image data
        )
      })
    }
  }, [])

  return (
    <Fragment>
      {
        props.loading ?
          <div style={{ height: "10rem", borderRadius: '2px', backgroundColor: greyColor10, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader size='small' type="inline-loader" />
          </div> :
          <div className="kp-inline-img-wrapper">
            {props.readOnly === false &&
              <ButtonTertiary
                // className="kp-delete-inline-img-icon-btn" 
                onClick={handleRemoveImage}
                icon="Trash"
              >
                {/* <Delete stroke={white} /> */}
              </ButtonTertiary>}
            <img src={props.src} style={{ width: "100%" }} />
          </div>
      }
    </Fragment>
  );
};

export default KPRichInlineImage;
