import React, { useState, useEffect, Fragment } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import KPLabel from '../../generalUI/KPLabel';
import DisplayCompForTextInputs from './DisplayCompForTextInputs';


const KPTextArea = ({
  label,
  sublabel,
  id,
  name,
  className,
  value,
  placeholder,
  readOnly,
  onChange: parentOnChange
}) => {


  const genInputComp = () => (
    <Fragment>
      <TextareaAutosize
        minRows={1}
        name={name}
        id={id}
        className={`kp-textarea bodySerif--L ${className}`}
        value={value}
        placeholder={placeholder}
        aria-labelledby={`${id}_label`}
        onChange={(e)=> parentOnChange(id, e.target.value)}
      />
    </Fragment>
  )

  const genDisplayComp = () => (
    <DisplayCompForTextInputs
      id={id}
      className={className}
      value={value}
    />
  )

  return (
    <div
      className={`kp-text-area-wrapper ${className.split(' ').map(d => d + '-wrapper').join(' ')}`}>
      <KPLabel
        id={`${id}_label`}
        label={label}
        sublabel={sublabel}
        readOnly={readOnly}
      />
      { readOnly ? genDisplayComp() : genInputComp()}
    </div>
  );
}

KPTextArea.defaultProps = {
  value: "",
  id: "", //required to send out the id via the onChange function
  //label is optional
  //sublabel is optional
  name: "Add a name via the 'name prop'", //optional, i think...
  className: "", //optional
  placeholder: "Write Here", //optional
  // onChange and value need to be handled by the onChange function in the parent
}

export default KPTextArea; 