import React, {Fragment} from "react";


const TitleSans__S__Bold = (props) => {

  

  return (
    <h3 className={`titleSans--S--Bold ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h3>
  );

}



export default TitleSans__S__Bold;
