import React, {Fragment} from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AdminRoute = ({
  component: Component,
  auth: { isAuthenticated, loading, user },
  location,
  match,
  ...rest
}) => {


  return(
    <Fragment>
    { !loading && 
      <Route
      {...rest}
      render={props =>
        !user 
        ? (
          <Redirect to={{
              pathname: '/login_registration',
              state: {
                from: location.pathname+location.search,
                authType: 'login'
              }
            }}
          />
        )
        : (
          user.role !== 'superAdmin'
          ? <Redirect to={{pathname: '/'}}/>
          : <Component {...props} />
        )
      }
    />
      }
    </Fragment>
    
  )
};

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(AdminRoute));
