import React, { useState, Fragment, useRef, useEffect } from "react";
import KPImageBlock from '../../inputs/KPImageBlock';
import KPImageInput from '../../inputs/KPImageInput';
import {KPTextArea, KPTextInput} from '../../inputs/KPInputs';

import { convertToGcsStructure, injectHttps } from '../../../utils/general';

/*
*
* @NOTE
* This is used only in News Tpl I think. 
* Cross-check
* 
*/

const ExternalLinkCard = ({
  className,
  img,
  title,
  description,
  backgroundColor,
  url,
  mode, //cant use the usual readOnly prop to gen input / display comp, since this one can show up in display mode OR input mode within the editor itself ( based on context )
  onChange,
  value,
  noHover
}) => {

  let cardRef = useRef(null);

  const genInputComp = () => (
    <div className={`kp-external-link-card no-hover`}>
      <div className='kp-external-link-card__img'>
        <KPImageInput
            id='kp_external_link__img___input_mode'
            className='kp-external-link__img--input_mode'
            multiple={false}
            value={convertToGcsStructure(value.img)}
            onChange={(k,v) => onChange(k,v, 'img')}
            />
      </div>
      <div className='kp-external-link-card__text-group'>

          <KPTextInput
            value={value.title}
            onChange={(k,v) => onChange(k,v, 'title')}
            placeholder='Write a link title'
            />

          <KPTextArea
            value={value.desc}
            onChange={(k,v) => onChange(k,v, 'desc')}
            placeholder='Write a link description'
            />
          <h6 className="h6 kp-external-link-card__url">{value.url}</h6>
      </div>
    </div>
  )

  const genDisplayComp = () => (
    <a href={value && injectHttps(value.url)} target="_blank">
      <div ref={cardRef} className={`kp-external-link-card ${noHover ? 'no-hover': ''}`} style={backgroundColor && {backgroundColor}}>
      { value && convertToGcsStructure(value.img).length > 0 &&

        <div className='kp-external-link-card__img'>
          <KPImageBlock
            image = {convertToGcsStructure(value.img)[0]}
            readOnly = {true}
            />
        </div>  }
        <div className='kp-external-link-card__text-group'>
          { value && value.source && <h6 className="h6 kp-external-link-card__source">{value && value.source}</h6> }
          { value && value.title && <h4 className="h4 serif semibold kp-contr kp-external-link-card__title">{value && value.title}</h4> }
          { value && value.desc && <h4 className="serif h5 kp-external-link-card__description">{value && value.desc}</h4> }
          { value && value.url && <h6 className="h6 kp-external-link-card__url">{value && value.url}</h6> }
        </div>
      </div>
    </a>
  )

  return (
    <div className={`kp-external-link-card-block ${className}`}>
    { mode === 'display'
      ? genDisplayComp()
      : genInputComp() }
    </div>
  );
}

ExternalLinkCard.defaultProps = {
  mode: 'display'
}


export default ExternalLinkCard;
