import React, {Fragment} from "react";


const BodySerif__L = (props) => {

  

  return (
    <p className={`bodySerif--L ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</p>
  );

}



export default BodySerif__L;
