import React, {Fragment} from "react";

const PageRow = (props) => (
    <div 
      className={`pageRow ${props.className || ''}`} 
      style={props.style}>
        {props.children}
    </div>
)

export default PageRow;
