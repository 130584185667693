import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { ButtonTertiary, KPIconBtn } from '../../generalUI/KPButtons';
import Delete from '../../icons/Delete';
import { removeBlock } from '../../../utils/richInputUtils';

import { white } from '../../../sass/vars.scss'

const ContentBlockShell = ({
  richInputProps,
  readOnly,
  className,
  onFocus,
  onBlur,
  children,
  id
}) => {

  //actions only when block is rendered in editor and is not readonly
  const [showBlockActions, setShowBlockActions] = useState(false);
  const handleMouseEnter = () => setShowBlockActions(true)
  const handleMouseLeave = () => setShowBlockActions(false)
  const handleRemoveBlock = () => {
    const { editor, block } = richInputProps;
    removeBlock(editor, block);
    richInputProps.handleSetTempReadOnly(false); //remove readOnly the minute block is deleted;
  }

  const handleOnFocus = (e) => {
    richInputProps.handleSetTempReadOnly &&
      richInputProps.handleSetTempReadOnly(true);
  }

  const handleOnBlur = (e) => {
    richInputProps.handleSetTempReadOnly &&
      richInputProps.handleSetTempReadOnly(false);
  }

  return (
    <div
      className={`kp-content-block-shell ${className} ${showBlockActions ? 'show-block-actions' : ''}`}
      id={id}
      onMouseEnter={richInputProps.isInEditor && !readOnly && handleMouseEnter}
      onMouseLeave={richInputProps.isInEditor && !readOnly && handleMouseLeave}
      onFocus={(e) => handleOnFocus(e)}
      onBlur={(e) => handleOnBlur(e)}
    >
      { children}
      { showBlockActions &&
        <div className='block-actions-wrapper'>
          <ButtonTertiary
            // type='danger'
            // className="kp-delete-in-editor-block-icon-btn"
            onClick={handleRemoveBlock}
            icon="Trash"
          >
            {/* <Delete stroke={white} /> */}
          </ButtonTertiary>
        </div>}
    </div>
  );
};

export default ContentBlockShell;
