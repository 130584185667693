import React, { useState, useEffect, Fragment } from "react";
import KPFAQSingleBlock from '../KPFAQSingleBlock';
import AddLG from '../../icons/AddLG';
import {ButtonTertiary, KPIconBtn} from '../../generalUI/KPButtons';
import KPLabel from '../../generalUI/KPLabel';
import ContentBlockShell from '../../generalUI/ContentBlockShell';
//utils
import {replaceEntityData} from '../../../utils/richInputUtils';

const KPFAQComp = props => {

  const q_id = 'faq_q'; const a_id = 'faq_a';
  const makeEmptyDataObj = (idx) => { return { [`${q_id}_${idx}`] : null, [`${a_id}_${idx}`] : null } }
  //shape =
  //  [
  //    { faq_q_0 : null, faq_a_0 : null, },
  //    { faq_q_1 : null, faq_a_1 : null, }
  //    ...
  //  ]

  const [data, setData] = useState( { val: props.value || [ makeEmptyDataObj(0) ] } )

  const addNewBlock = () => {
    let tempData = data.val;
    let idx = data.val.length;
    tempData.push(makeEmptyDataObj(idx));
    setData({ val : tempData});
  }

  const handleChange = (blockInputStateObj) => {
    let tempData = data.val;
    tempData.map((d,i) => {
      if(Object.keys(d)[0] === Object.keys(blockInputStateObj)[0]){
        tempData.splice(i,1,blockInputStateObj);
      }
    })
    setData({val : tempData});
    props.onChange && props.onChange(props.id, tempData)

    //this only runs if comp is rendered inside rich editor
    if(props.isInEditor === true){
      replaceEntityData(props.editor, props.entityKey, {value: tempData })
    }

    
  }


  return (
    <div>
    <ContentBlockShell
      richInputProps = {{
        isInEditor : props.isInEditor,
        editor: props.editor,
        block: props.block,
        onEditorChange: props.onEditorChange,
        handleSetTempReadOnly: props.handleSetTempReadOnly
      }}
      readOnly = { props.readOnly }
      className= { `kp-faq-comp ${props.className}`  }
      >
      <KPLabel
        className="kp-faq-comp-label"
        label={props.label}
        sublabel={props.sublabel}
        isRequired={props.isRequired}
        readOnly={props.readOnly}
        />
      <div className='kp-faq-blocks-wrapper'>
      { data.val.map((d,i) => (
        <Fragment key={i}>
          <KPFAQSingleBlock
            value = {d}
            readOnly={props.readOnly}
            onChange = {(blockInputStateObj) => handleChange(blockInputStateObj)}
            />
        </Fragment>
      )) }
      </div>
    { !props.readOnly &&
      <div className='add-new-block-btn-wrapper'>
        <ButtonTertiary 
          // className='add-new-block-btn' 
          onClick={addNewBlock}
          icon="Plus"
          />
      </div> }

    </ContentBlockShell>
    </div>

  )
}

KPFAQComp.defaultProps = {
  id : 'kp_faq_comp',
  label: 'faqs',//label is optional
  //sublabel is optional
  readOnly: false
}

export default KPFAQComp;
