import React, {Fragment} from "react";


const Sublabel = (props) => {

  

  return (
    <h6 className={`sublabel ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h6>
  );

}



export default Sublabel;
