import {
  UPDATE_SCREEN_WIDTH,
  SET_MODAL_OVERLAY,
  INIT_NESTED_PUBLISHING,
  RESET_NESTED_PUBLISHING,
  SET_ANNOUNCEMENT,
  REMOVE_ANNOUNCEMENT,
  SET_ENV_CONFIG,
  ENV_CONFIG_LOADING,
  SET_ENV_CONFIG_ERROR,
  SET_APP_ONLINE_STATUS

} from '../actions/types';

const initialState = {
  screenWidth : null,
  modalOverlay : false,
  nestedPublishing : {
    mode : 'inactive', //alt : active
    contentBlockId : null,
    redirectPath : null,
    prefillBlock : null
  },
  announcement : [],
  envConfigLoading: true,
  envConfigError: [],
  envConfig: null,
  onlineStatus : null

};

export default function(state = initialState, action) {
  const {type, payload} = action

  switch (type) {

    case SET_ENV_CONFIG: 
      return {
        ...state,
        envConfig: payload,
        envConfigLoading : false
      }
    case ENV_CONFIG_LOADING:
      return {
        ...state,
        envConfigLoading: payload,
      }
    case SET_ENV_CONFIG_ERROR:
      return {
        ...state,
        envConfigError : payload,
        envConfigLoading : false
      }

    case SET_APP_ONLINE_STATUS:
    return {
      ...state,
      onlineStatus : payload
    }

    case UPDATE_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth : payload
      }
    case SET_MODAL_OVERLAY:
      return {
        ...state,
        modalOverlay : payload
      }
    case INIT_NESTED_PUBLISHING:
    return {
      ...state,
      nestedPublishing : {
        mode : 'active',
        contentBlockId : payload.contentBlockId,
        redirectPath : payload.redirectPath,
        prefillBlock : payload.prefillBlock,
        alertMsg : payload.alertMsg
      }
    }
    case SET_ANNOUNCEMENT:
    return {
      ...state,
      announcement : handleSetAnnouncement(state, payload)
    }
    case REMOVE_ANNOUNCEMENT:
    return {
      ...state,
      announcement : state.announcement.filter(d => d.id !== payload.id)
    }
    default:
      return state;
  }
}


const handleSetAnnouncement = (state, payload) => {
  let prevAnnouncements = [...state.announcement];
  let newAnnouncementData = {
    id : payload.id,
    text : payload.text,
    type : payload.type,
    options : payload.options
  }
  let clashIdx = prevAnnouncements.findIndex(d => d.id === payload.id);
  if(clashIdx !== -1){
    prevAnnouncements[clashIdx] = newAnnouncementData
  }else{
    prevAnnouncements.push(newAnnouncementData);
  }
  return prevAnnouncements; 
}
