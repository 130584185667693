import React, { Fragment } from "react";
import { Link } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from 'react-redux';

import { ButtonGhost, ButtonTertiary, KPIconBtn } from '../../generalUI/KPButtons';

import Close from '../../icons/Close';
import Alert__Success from '../../icons/Alert__Success';
import Alert__Danger from '../../icons/Alert__Danger';
import Alert__Neutral from '../../icons/Alert__Neutral';

import { removeAlert } from '../../../actions/alert';
import { generateTpl } from '../../../actions/contributions';

import { black, white } from '../../../sass/vars.scss';
import BodySans__M__Bold from "../../typo/BodySans__M__Bold";

const Alert = ({ alerts, removeAlert }) => {

  //reallly hacky shit
  const dispatch = useDispatch();
  const { Contribution } = useSelector(state => state.contributions);
  const { user } = useSelector(state => state.auth);

  const Icons = {
    success: Alert__Success,
    danger: Alert__Danger,
    neutral: Alert__Neutral
  }

  const handleCloseAlert = id => removeAlert(id);

  let Actions = {
    generateTpl
  }

  let ActionArgs = {
    generateTpl: [Contribution, user, {queryString: '?ignoreDuplicateInterviewDraft=true'}]
  }


  return (
    <div className="kp-alerts-wrapper">
      {
        alerts !== null && alerts.length > 0 && alerts.map(alert => {
          let Icon = Icons[alert.alertType];
          return (
            <div key={alert.id} className={`kp-alert kp-alert--${alert.alertType}`}>
              <div className='kp-alert__icon-wrapper'><Icon /></div>
              <BodySans__M__Bold className='Alert__text'>
              { alert.msg }
              { alert.options && 
                alert.options.linkText && 
                <Link className='kp-inline-link' to={alert.options.linkUrl}>{alert.options.linkText}</Link> }
              { alert.options && 
                alert.options.links && 
                alert.options.links.map(link => (
                  <Fragment>
                { link.url &&
                  <Link className='kp-inline-link' to={link.url}>{link.text}</Link> }
                  { link.action &&
                    <div onClick={() => dispatch(Actions[link.action](...ActionArgs[link.action]))}>
                      <Link className='kp-inline-link' to={'#'}>
                        {link.text}
                      </Link>
                    </div> }
                  </Fragment>
                )) }
              </BodySans__M__Bold>

              <ButtonGhost
                // className='kp-alert__remove-alert-btn'
                // type="tertiary"
                onClick={() => handleCloseAlert(alert.id)}
                icon="X"
              >
                {/* <Close /> */}
              </ButtonGhost>
            </div>)
        })
      }
    </div>
  )

}

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  alerts: state.alert
})

export default connect(mapStateToProps, { removeAlert })(Alert);
