import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import {
  ButtonGhost,
  KPIconBtn,
  ButtonSecondary,
} from "../../generalUI/KPButtons";
import KPLink from "../../generalUI/KPLink";
import Close from "../../icons/Close";

import { white } from "../../../sass/vars.scss";
import TitleSans__M from "../../typo/TitleSans__M";

const ModalHeader = (props) => {
  return (
    <div className={`kp-modal-header ${props.className}`}>
      <div className="kp-modal-header__content">
        {props.title && (
          <TitleSans__M style={{ color: white }}>{props.title}</TitleSans__M>
        )}
        {props.headerLink && (
          <KPLink
            style={{ paddingLeft: "2rem" }}
            linkType={props.headerLink.linkType}
            link={props.headerLink.link}
            text={props.headerLink.text}
          />
        )}
        {props.cta && props.cta}
      </div>
      <div className="kp-modal-header__actions-group">
        <ButtonGhost
          // className='kp-modal-header__action--close'
          // type='tertiary'
          invert={true}
          icon="X"
          onClick={props.onClose}
        />
      </div>
    </div>
  );
};

export default ModalHeader;
