import React, { Fragment, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { removeBlock, replaceEntityData } from '../../../utils/richInputUtils';
import Loader from '../../generalUI/Loader';
import { ButtonTertiary, KPIconBtn } from '../../generalUI/KPButtons';
import ChevronLeft from '../../icons/ChevronLeft';
import ChevronRight from '../../icons/ChevronRight';

import { imageUploader } from '../../../utils/general';
import { greyColor10, white } from '../../../sass/vars.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const KPPDFDisplay = props => {

  const [pageData, setPageData] = useState({
    numPages: null,
    pageNumber: 1,
  })

  useEffect(() => {
    if (props.formData) {
      imageUploader(props.formData, { type: 'files' }, (status, resData) => {
        replaceEntityData(
          props.editor,
          props.entityKey,
          { src: resData[0].mediaLink, loading: false } //replace the image blocks entity data with actual image data
        )
      })
    }
  }, [])

  const handleRemovePDF = () => {
    const { editor, block } = props;
    removeBlock(editor, block);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPageData({ ...pageData, numPages });
  }

  const handlePageChange = (direction) => {
    const { pageNumber, numPages } = pageData;
    let newPageNumber = pageNumber;
    if (direction === 'back' && pageNumber !== 1) {
      newPageNumber = pageNumber - 1;
    } else if (direction === 'forward' && pageNumber !== numPages) {
      newPageNumber = pageNumber + 1;
    }
    setPageData({
      ...pageData,
      pageNumber: newPageNumber
    })
  }

  return (
    <div className={`kp-pdf-display ${props.className}`}>
      { props.loading
        ? <div style={{ height: "10rem", borderRadius: '2px', backgroundColor: greyColor10 }}>
          <Loader size='small' type="inline-loader" text="Loading PDF..." />

        </div>
        : <Fragment>
          <Document
            file={props.src}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page pageNumber={pageData.pageNumber} />
          </Document>
          <div style={{ display: 'flex' }}>
            <ButtonTertiary
              // size='small'
              onClick={() => handlePageChange('back')}
              icon="CaretLeft"
            >
              {/* <ChevronLeft stroke={white} /> */}
            </ButtonTertiary>
            <p style={{ padding: '0 1rem' }}>Page {pageData.pageNumber} of {pageData.numPages}</p>
            <ButtonTertiary
              // size='small'
              icon="CaretRight"
              onClick={() => handlePageChange('forward')}>
              {/* <ChevronRight stroke={white} /> */}
            </ButtonTertiary>
          </div>
        </Fragment>}
    </div>
  );
}

export default KPPDFDisplay;
