import * as React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function ChevronDownSM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M4.5 8l4.793 4.793a1 1 0 001.414 0L15.5 8"
        stroke={props.stroke || primaryColor100}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChevronDownSM;



