import {
  SET_INIT_RESYNTH_DATA_STATE,

  SET_INTERVIEW_DATA,
  LOADING_INTERVIEW_DATA,
  ERROR_GET_INTERVIEW_DATA,
  STORE_FETCH_INTERVIEW_DATA,
  UPDATE_INTERVIEW_DATA,

  LOADING_ALL_INTERVIEWS_DATA,
  ERROR_GET_ALL_INTERVIEWS_DATA,

} from '../actions/types';

const initialState = {
  error : undefined,
  loading : true
};


/**
 * initialState for the interviewTypes data is set in action>getClientConfig ( since that is where we first access the _ContentTypes config )
 */

export default function(state = initialState, action) {
  const {type, payload, key} = action

  switch (type) {

    case SET_INIT_RESYNTH_DATA_STATE:
      return {
        ...state,
        ...payload
      }

    case LOADING_INTERVIEW_DATA:
    return {
      ...state,
      [key] : {
        ...state[key],
        loading: payload
      }
    }
    case SET_INTERVIEW_DATA:
    return {
      ...state,
      [key] : {
        ...state[key],
        data: { ...payload },
        loading: false
      }
    }

    case STORE_FETCH_INTERVIEW_DATA:
    return {
      ...state,
      [key]: {
        ...state[key],
        loading: false
      }
    };

    case UPDATE_INTERVIEW_DATA:
      return {
        ...state,
        [key] : {
          ...state[key],
          data: {
            ...state[key].data,
            ...payload
          }
        }
      }

    case ERROR_GET_INTERVIEW_DATA:
    return {
      ...state,
      [key] : {
        ...state[key],
        error : payload,
        loading: false
      }
    }

    case LOADING_ALL_INTERVIEWS_DATA:
      return {
        ...state,
        loading: payload
      }

    case ERROR_GET_ALL_INTERVIEWS_DATA:
      return {
        ...state,
        error: payload
      }

    default:
    return state;
  }
}
