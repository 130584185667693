export default [
  {
    email: 'abrar@ooloilabs.in',
    interviewerCode: 'PT'
  },
  {
    email: 'akshay@ooloilabs.in',
    interviewerCode: 'PT'
  },
  {
    email: 'pradhan.thandra@vihara.asia',
    interviewerCode: 'PT'
  },
  {
    email: 'kislay.yadav@vihara.asia',
    interviewerCode: 'KY'
  },
  {
    email: 'raikamal.roy@vihara.asia',
    interviewerCode: 'RR'
  },
  {
    email: 'jafar.zaidi@vihara.asia',
    interviewerCode: 'JZ'
  },
  {
    email: 'srividya.venkataraman@vihara.asia',
    interviewerCode: 'SV'
  },
  {
    email: 'namita.mohandas@vihara.asia',
    interviewerCode: 'NM'
  },
  {
    email: 'yogesh.saini@vihara.asia',
    interviewerCode: 'YS'
  },
  {
    email: 'kapil.vachhar@vihara.asia',
    interviewerCode: 'KV'
  },
  {
    email: 'rasikab@gmail.com',
    interviewerCode: 'RB'
  },
  {
    email: 'yashna@ooloilabs.in',
    interviewerCode: 'PT'
  },
  {
    email: 'ravi.standered@gmail.com',
    interviewerCode: 'ravi'
  },
  {
    email: '110amarsingh1@gmail.com',
    interviewerCode: 'amarjeet'
  },
  {
    email: 'abdulkadirak6782004@gmail.com',
    interviewerCode: 'abdul'
  },
  {
    email: 'rajasthanbgvs@gmail.com',
    interviewerCode: 'anil'
  },
  {
    email: 'sindhusamariya94099@gmail.com',
    interviewerCode: 'sindhu'
  },
  {
    email: 'mahichnaveen906@gmail.com',
    interviewerCode: 'naveen'
  },
  {
    email: 'harishchauhan20jan@gmail.com',
    interviewerCode: 'harish'
  },
  {
    email: 'amit.mahishii@gmail.com',
    interviewerCode: 'amit'
  },
  {
    email: 'mdf24222@gmail.com',
    interviewerCode: 'firoz'
  },
  {
    email: 'sanjeetsmp3410@gmail.com',
    interviewerCode: 'sanjit'
  }
]