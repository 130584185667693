import React, { Fragment } from 'react';

 const DisplayCompForTextInputs = ({ id, className, value, suffix, inputType }) => ( 
  //applies for textInput & textArea
  <Fragment>
  { value &&
    <h4
      aria-labelledby={`${id}_label`}
      id={id}
      className={`input kp-text-input-display ${className}`}
    >
      {inputType === 'number' ? parseInt(value).toLocaleString('en-IN') : value}
    <span style={{paddingLeft: '1rem'}}>{suffix ? suffix : ''}</span></h4> }
  </Fragment>
)

export default DisplayCompForTextInputs;