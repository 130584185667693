import React from "react"

function DistrictDashboardIcon(props) {
  return (
    <svg width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M7.627 10.873v.5h8.11a7.627 7.627 0 11-8.11-8.11v7.61z"
        fill="none"
        stroke="#E3F2FD"
      />
      <path
        d="M18.484 7.627h-7.11V.517a7.627 7.627 0 017.11 7.11z"
        fill="none"
        stroke="#E3F2FD"
      />
    </svg>
  )
}

export default DistrictDashboardIcon
