import React, { Fragment } from "react";

const CardTag = props => {

  return (
    <div className={`kp-content-card__tag-wrapper ${props.className}`} style={props.style}>
    { Array.isArray(props.value) ?
      props.value.map((t,i) => <Fragment>{ i <= 1 && <h4 className='h4 kp-content-card__tag'>{t}</h4> }</Fragment> )
      :
      <h4 className='h4 kp-content-card__tag'>{props.value}</h4>
      }
    </div>
  );
}

export default CardTag
