import React, { useState, Fragment, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import history from "../../../history";
//comps
import MetaBlock from "../../generalUI/MetaBlock";
import MetaBlock__Toolkits from "../../generalUI/MetaBlock__Toolkits";
import CardTag from "../CardTag";
import { KPIconBtn, ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../generalUI/KPButtons";

import KPLabel from "../../generalUI/KPLabel";
import KPImageInput from "../../inputs/KPImageInput";
import KPVideoEmbed from '../../inputs/KPVideoEmbed';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import KPMultiProfilesDisplay from '../../generalUI/KPMultiProfilesDisplay';
import KPMetaPrimary from "../../generalUI/KPMetaPrimary";
import KPMetaSecondary from "../../generalUI/KPMetaSecondary";
import KPLink from '../../generalUI/KPLink';
import KPMetaHero from "../../generalUI/KPMetaHero";
import ExternalLinkCard from "../ExternalLinkCard";
import KPInlineAlert from '../../generalUI/KPInlineAlert';
//icons
import Delete from "../../icons/Delete";
import Close_S from "../../icons/Close_S";
import Edit from "../../icons/Edit";
import KPTagsInput from "../../inputs/KPTagsInput";
import Download__nofill from '../../icons/Download__nofill';
import LinkOutSM from '../../icons/LinkOutSM';

//sass
import { white } from "../../../sass/vars.scss";
import store from "../../../store";
//actions
import { deleteDraft } from "../../../actions/profiles";
import CardTitle from "../../typo/CardTitle";
import BodySerif__L from "../../typo/BodySerif__L";

import {mediaQuery} from '../../../utils/general';



const CardEmbedContent = ({
  colSetting,
  className,
  contentType,
  link,
  image, // of the format {backgroundImage : x, backgroundPosition : y}
  videoEmbed,
  imgContainerStyle,
  listElemLabel,
  title,
  metaBlockData, //of the format [{icon: xComp, text: y},...]
  resourceLink,
  metaHero,
  actions,
  tags,
  multiProfilesDisplayData,
  cardLink,
  style,
  readOnly,
  inlineAlert,
  onRemoveCard,
  customClickHandler
}) => {

  const MetaBlockComps = {
    MetaBlock,
    MetaBlock__Toolkits,
  };

  const { screenWidth } = useSelector(state => state.environment);

  const MetaBlockComp = metaBlockData && MetaBlockComps[metaBlockData.comp];

  
  const cardActionExists = resourceLink && resourceLink.displayType !== 'inline';
  const cardActionsRef = useRef(null);

  const {_CaptureDownloaderDeets} = useSelector((state) => state.environment.envConfig.deployment);
  const [triggerForm, setTriggerForm] = useState(false);

  //check to see if click hasnt been on an action within the card. in which case, follow the card link.
  const handleCardClick = (e) => {
    if (!cardActionsRef.current || !cardActionsRef.current.contains(e.target)) {
      customClickHandler
      ? customClickHandler()
      : link.type === "internal"
        ? history.push(link.url)
        : window.open(link.url, "_blank");
      
    }
  };

  const handleCardActionClick = () => {
    (contentType === 'resources' && _CaptureDownloaderDeets) 
    ? setTriggerForm(true)
    : window.open(resourceLink.value, '_blank');
  }

  const genCardActions = () => (
    <div ref={cardActionsRef} className='CardEmbed__action'>
      <ButtonSecondary
        onClick={() => handleCardActionClick()}
        icon={resourceLink.type ==="uploaded"? "DownloadSimple":"ArrowLineUpRight"}
      />
    </div>
  )

  const genRmCardBtn = (onRemoveCard) => (
    <ButtonPrimary
      // className='kp-content-card-wrapper__remove-btn'
      // type='danger'
      // size='small'
      onClick={onRemoveCard}
      icon="Close_S"
    >
      {/* <Close_S fill={white} /> */}
    </ButtonPrimary>
  )

  return (
    <div className={`${colSetting} ${className} CardEmbed-Wrapper--Content`} style={style}>
    { onRemoveCard && !readOnly && //only show if a remove card function is passed AND we are not in read-only mode
      <ButtonPrimary
        className='kp-content-card-wrapper__remove-btn'
        // type='danger'
        // size='small'
        onClick={onRemoveCard}
        icon="Close_S"
        >
        {/* <Close_S fill={white} /> */}
      </ButtonPrimary>
      }
      <div className='CardEmbed--Content' onClick={ handleCardClick }>
        <div className='CardEmbed__contentModule-1'>
          {videoEmbed &&
            <div className="CardEmbed__video-container">
              <KPVideoEmbed
                value={videoEmbed.value}
                readOnly={true}
                light={true}
              />
            </div>}
          {image &&
            <div className="CardEmbed__image-container" style={imgContainerStyle}>
              <KPImageInput
                value={image.data}
                heightRatio={image.heightRatio && image.heightRatio}
                placeholderColor={image.placeholderColor}
                containerShape={image.containerShape}
                height={image.height && image.height}
                width={image.width && image.width}
                imgBlockComp={image.imgBlockComp}
                backgroundSize={image.backgroundSize && image.backgroundSize}
                placeholderInitial={image.placeholderInitial}
                readOnly={true}
              />
            </div>}
        </div>
        <div className='CardEmbed__contentModule-2'>
        { listElemLabel &&
          <KPMetaPrimary className='CardEmbed__label' data={ listElemLabel } style={cardActionExists && { paddingRight: '4rem' }} /> }
        { metaHero && 
          <KPMetaHero className='CardEmbed__date' data={ metaHero } /> }
          <BodySerif__L className={`CardEmbed__title`}><span className='CardEmbed__title-span'>{ title }</span></BodySerif__L>
          {metaBlockData && (
            <MetaBlockComp
              className="CardEmbed__metaBlock"
              data={metaBlockData.data}
            />)}
        { cardActionExists &&
          genCardActions() }
        </div>
      </div>
    </div>

  );
};



export default CardEmbedContent;
