import React, {Fragment} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { removeAnnouncement } from '../../../actions/alert';
import BodySans__M from "../../typo/BodySans__M";
import { ButtonSecondary} from "../../generalUI/KPButtons";

const Announcement = (props) => {

  const dispatch = useDispatch();
  const announcement = useSelector((state) => state.environment.announcement);

  console.log('sw', navigator.serviceWorker);
  // console.log("annoucement.options)",announcement && announcement.options);

  let actions = {
    skipWaitingAndReload : (options) => {
      let skipWaitingMessageChannel = new MessageChannel();
      options.sw.postMessage({type: 'SKIP_WAITING_AND_RELOAD'}, [skipWaitingMessageChannel.port2]);

      skipWaitingMessageChannel.port1.onmessage = (event) => {
        switch(event.data.type){
          case "REFRESH_PAGE":
          window.location.reload();
          break;
        }
      }
      
    },
    reload : () => {
      window.location.reload();
    },
    removeAnnouncement : (options) => {
      dispatch(removeAnnouncement(options.id))
    }
  }
  
  return (
    <Fragment>
    { announcement.map( an => (
      <Fragment>
      { an.text &&
        <div id="OKE_Announcement" className={`Announcement ${an.type || ''}`} >
          <BodySans__M className='Announcement__text'>{an.text}</BodySans__M> 
        { an.options && an.options.cta && 
          <ButtonSecondary 
            invert={true}
            className='Announcement__action'
            onClick={() => an.options.cta.action && actions[an.options.cta.action](an.options)}
            >
            {an.options.cta.text}
          </ButtonSecondary> }
        </div> }
      </Fragment>
    ))}
    </Fragment>
  )
}

export default Announcement;
