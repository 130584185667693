import React from "react"

function RichFormatToolH2(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M7.27 19.277H5.463V6.582H1V5h10.703v1.582H7.27v12.695zM20.825 19.277h-1.296v-7.452H16.96v-1.116h6.432v1.116h-2.568v7.452z"
        fill="#fff"
      />
    </svg>
  )
}

export default RichFormatToolH2;
