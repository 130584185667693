import React, {Fragment} from "react";


const Label__L = (props) => {

  

  return (
    <h6 className={`label--L ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h6>
  );

}



export default Label__L;
