import * as React from "react"

function CheckboxCheck(props) {
  return (
    <svg width={14} height={10} viewBox="0 0 14 10" fill="none" {...props}>
      <path
        d="M1 5.522L4.478 9l8-8"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckboxCheck;
