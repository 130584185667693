import React from "react"

function Person14(props) {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <circle
        cx={7.24}
        cy={3.912}
        r={2.245}
        stroke= {props.stroke || "#9CA3A6"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.73 12.834a5.49 5.49 0 00-10.98 0"
        stroke= {props.stroke || "#9CA3A6"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Person14
