import React, { Fragment } from 'react';
import MarkdownParser from '../../generalUI/MarkdownParser';

const TplStaticText = ({ id, className, markdownText }) =>  {
  //applies for textInput & textArea
  
  
  return (
    <div className={`TplStaticText ${className || ''}`}>
    { (markdownText) && 
      // value.split('\n').filter(Boolean).map(val => {
      //   return(
        <MarkdownParser>
        {markdownText}
        </MarkdownParser>
      // )}) 
    }
    </div>
  )
}

export default React.memo(TplStaticText);