import React, {Fragment} from 'react';
import axios from 'axios';
import TitleSerif__M from '../components/typo/TitleSerif__M';
import TitleSerif__S from '../components/typo/TitleSerif__S';
import {
  set_getValOffQueryString as s_gVal, 
  blockIsPopulatedConditions
} from './general';



const genTitleModule = subSectionDetails => (
  <>
  { (subSectionDetails.subSectionTitle || subSectionDetails.subSectionSubtitle) &&
    <div className='Tpl__subsectionTitleModule'>
      { subSectionDetails.subSectionTitle && 
        <TitleSerif__M className='Tpl__subsectionTitle'>{subSectionDetails.subSectionTitle}</TitleSerif__M> }
      { subSectionDetails.subSectionSubtitle && 
        <TitleSerif__S className='Tpl__subsectionSubtitle' >{subSectionDetails.subSectionSubtitle}</TitleSerif__S> }
    </div> }
  </> )

const genSubSectionWrapper = (subSectionDetails, depthIdx, children) => {
  if(depthIdx === 0 ){
    return (
      <div className={`Tpl__subsection-container  ${subSectionDetails.subSectionClassName}-container`}>
        <div className={`Tpl__subsection-row  ${subSectionDetails.subSectionClassName}-row`}>
          { children }
        </div>
      </div>
    )
  }else{
    return children;
  }
}

export const drilldownSubSection = (blockData, generateContentBlock, depthIdx, options = {}) => {


  let html = blockData.map((block, blockI) => {
    let isSubSection = !!block.subSectionName; //double exclamation will return the truthy / false value of the variable : https://medium.com/better-programming/javascript-bang-bang-i-shot-you-down-use-of-double-bangs-in-javascript-7c9d94446054
    options.subSectionTree = depthIdx === 0 ? [] : options.subSectionTree;
    if (isSubSection === true) {

      const setLocalizedSubSectionTitle = value => {
        return (
          value && 
          (value.locale && options.tplLang
          ? value.locale[options.tplLang.value]
          : value)
        )
      }

      let subSectionDetails = {
        subSectionName : block.subSectionName,
        subSectionClassName : block.subSectionClassName,
        subSectionTitle : setLocalizedSubSectionTitle(block.subSectionTitle),
        subSectionSubtitle : setLocalizedSubSectionTitle(block.subSectionSubtitle),
      }

      options.subSectionTree[depthIdx] = subSectionDetails

      let shouldIndexSubSection = (
        options.indexType === 'subSection' &&
      ( options.hasOwnProperty('showIndexInContributeTpl') 
        ? options.showIndexInContributeTpl 
        : true ) &&
        depthIdx === 1 && //curently we oonly index second level subsections
        options.subSectionTree[0] &&
        options.subSectionTree[0].subSectionName === 'body'
      )
      
      if(shouldIndexSubSection) options.indexedBlocks.push(subSectionDetails);

      const blockIsConsideredPopulated = (block, valuePath) => {
        let value = valuePath && s_gVal('get', options.Contribution, valuePath);
        let isConsideredPopulated = blockIsPopulatedConditions(block, value);
        return isConsideredPopulated;
      }

      const shouldGenerateSubSection = blocks => {
        let should = blocks && blocks.length > 0 && blocks.some(block => {
          return  !!block.subSectionName === true //if even one block is a subsection in itself, then show that subsection
                  ||
                  (
                    options.mode === 'input' 
                    ? block.displayOnly !== true 
                    : options.mode === 'display' &&  //explicitly check if mode === 'display', cuz its possible sometimes mode may equal to undefined, cuz drilldown is used for validation check as well.
                      block.inputOnly !== true &&
                      blockIsConsideredPopulated(block, block.valuePath)
                  )
                  
        })
        
        return should;
      }

      

      return (
        <Fragment key={block.subSectionName}>
          {shouldGenerateSubSection(block.blocks) &&
            <section
            className={`Tpl__subsection${depthIdx > 0 ? '__' : ''} ${block.subSectionClassName}`}
            id={`${block.subSectionName}`}
            ref={(el) => {
              if (shouldIndexSubSection && el !== null) options.indexedBlockRefs.push(el);
            }}
            >
          { genSubSectionWrapper(
              subSectionDetails,
              depthIdx,
              <Fragment>
              { genTitleModule(subSectionDetails)}
              { drilldownSubSection(block.blocks, generateContentBlock, depthIdx + 1, options) }
              </Fragment>
            ) }
          </section>} 
        </Fragment>
      )
           
    } else {
      return (
        <Fragment key={(block.props && block.props.id) ? block.props.id : blockI}>
          {generateContentBlock(block, [...options.subSectionTree])}
        </Fragment>
      ) 
    }
  })
  return html;
}


export const generateMultiSubjectTplData = (contentType, segrigatedData, contentSubTypeCategory) => {
  
  // let mergedTplDataObj = (tplsData) => {
  //   let newObj = {};
  //   tplsData.map(d => {
  //     newObj[d._id] = d.kp_templates.data;
  //   })
  //   return newObj;
  // } 

  let newData = {
    kp_content_type : contentType,
    kp_settings : [
      {
        sectionName : "settings1",
        sectionTitle : "Setup Your Story",
        comp: "ContributeConfigureBody",
        blocks: [
            {
                comp: "KPRadioCardBlock",
                blockType: "single",
                isRequired: true,
                setValuePath: [
                  {pathToSet: "meta.kp_content_tpl", pathToGet: "tplId"},
                  "meta.kp_content_sub_type.info"
                ],
                valuePath: "meta.kp_content_sub_type.info",
                props: {
                    id: "subjectSelect",
                    colSettings: "col-md-4",
                    options : segrigatedData[contentSubTypeCategory].map(d => {
                      let {value, title, subtitle, status} = d.kp_content_sub_type.info;
                      return {
                        tplId: d._id,
                        value,
                        title,
                        subtitle,
                        status
                      }
                    })
                }
            }
        ]
      }
    ],
    kp_templates : {
      comp: "ContributeTplBody",
      data: []
      // data: mergedTplDataObj(segrigatedData[contentSubTypeCategory])
    }
  }

  return newData;
}