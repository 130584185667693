import React from "react"

function RichFormatToolBlockquote(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5 13.773c0-1.413.256-2.247 1.09-3.6.86-1.38 2.546-2.734 3.772-3.364l.391.758c-1.055.75-1.737 1.337-2.486 2.775a4.207 4.207 0 00-.4 1.09c.162-.034.34-.051.52-.051 1.549 0 2.8 1.26 2.8 2.81 0 1.549-1.251 2.809-2.8 2.809a2.797 2.797 0 01-2.7-2.06A3.49 3.49 0 015 13.773zM12.313 13.773c0-1.413.255-2.247 1.09-3.6.86-1.38 2.545-2.734 3.771-3.364l.392.758c-1.056.75-1.737 1.337-2.486 2.775a4.205 4.205 0 00-.4 1.09c.161-.034.34-.051.519-.051 1.55 0 2.801 1.26 2.801 2.81C18 15.74 16.748 17 15.199 17a2.797 2.797 0 01-2.7-2.06 3.49 3.49 0 01-.186-1.167z"
        fill="#fff"
      />
    </svg>
  )
}

export default RichFormatToolBlockquote;
