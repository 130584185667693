import React, { Fragment } from "react";
import { createContentCardDate } from "../../../utils/momentManipulate";

const BodySans__M = (props) => {
  
  let { value } = props;

  return (
    <h5
      className={`bodySans--M ${props.className || ""}`}
      style={props.style}
      id={props.id || ""}
    >
      {(props.children || value) + (props.suffix || "")}
    </h5>
  );
};

export default BodySans__M;
