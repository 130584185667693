import {
    set_getValOffQueryString as s_gVal,
    propertyExists,
    convertToGcsStructure,
    convertResToGcsStructure
  } from './general';
  
  //we only pass the highest level property into the getters.
  //picking and choosing between nested properties is dealt by the getter
  
  export const getUrl = property => property && property.url
  
  export const getAvatar = property => property && property.avatar;
  
  export const getName = property => property && property.name;

  export const getShortenedName = property => {
    if(property && property.name){
      let nameSplit = property.name.split(' ');
      if(nameSplit[1]) return `${nameSplit[0]} ${nameSplit[1][0]}.` // e.g. John Doe becomes :  John D.
      //else
      return nameSplit[0]; //e.g if name is a single word like 'Ronaldo' then this fn returns Ronaldo
    } 
  }
  
  export const getFromMultiValPaths = (property, valuePath) => { //first path in array is prioritized if valuer is found
    let value = null;
    if(Array.isArray(valuePath)){
      for(var i = 0 ; i < valuePath.length ; i++){
        value = s_gVal('get', property, valuePath[i]);
        if(value) break;
      }
    }else{
      value = s_gVal('get', property, valuePath);
    }
    return value;
  }

  export const getVal = (property, valuePath = null, count = null ) => {
  
    if(!propertyExists(property)) return null;
  
    if(count === null && valuePath === null){
      return propertyExists(property) && property;
    }
  
    if(valuePath && count === null){
      return getFromMultiValPaths(property, valuePath);
    }
  
    //else both valPath & count exist
    let tempVal = [];
    let loopLimit = count === 'all' ? property.length : count;
    let leftOutCount = property.length - count;
    for(var i = 0; i < loopLimit; i++){
      property[i] && tempVal.push( getFromMultiValPaths(property[i], valuePath))
    }
  
    return tempVal.join(', ')+(leftOutCount > 0 ? ` ...+${leftOutCount}` : '');
  
  }
  
  // *** can phase out eventually, alt fn: __GetText
  export const getRichInputText = property => {
    let val = null;
    if(Array.isArray(property)){ //we can pass multiple properties as array, giving this function the option to pick any one that has a readable value in order of preference from first to last.
      for(var i = 0; i < property.length; i++){
        val = getTextFromAnyBlock(property[i]);
        if(val !== null ) break;
      }
  
    }else{
      val = property ? getTextFromAnyBlock(property) : null;
    }
  
    return val;
  }
  
  //getRichInputText util : 
  // *** can phase out eventually, alt fn: __ExtractRichOrPlainText
  const getTextFromAnyBlock = property => {
    let val = null;
    if(property){
      if(typeof property === 'string') return property; //meaning the property isnt rich text but rather plain text.
      //else
      for (var i  = 0; i < property.blocks.length; i++){
        if(property.blocks[i] && property.blocks[i].text && property.blocks[i].text.length > 0 ){
          val = property.blocks[i].text;
          break;
        }
      }
    }
  
    return val;
  }
  
  //
  
  //phase out eventually, new function in gettersV2 : __GetImageData
  export const getImgData = property => {
    let val = null;
    val = (property) && convertToGcsStructure(property);
    return val;
  }
  
  //
  
  export const getLocation = (property, subPropertiesAry) => {
    let val = null;
    if(property){
      if(subPropertiesAry.length > 0){
        val = subPropertiesAry.map(d => {
          return s_gVal('get', property, d);
        })
        val = val.join(', ');
      }else{ //
        val = property;
      }
    }
    return val;
  }
  
  //phase out eventually now we have __GetResourceLink in v2
  
  export const getResourceLink = property => {
    let val = null;
    if(!propertyExists(property)) return;
    //else
    let uploadedResourceObj = property && property.KPResourceUpload && convertResToGcsStructure(property.KPResourceUpload);
    let linkedResource = property && property.KPLinkAsButtonInput;
  
    if(uploadedResourceObj){
      let tempVal = uploadedResourceObj.length > 0 && uploadedResourceObj.mediaLink
      val = { type: 'uploaded', value: tempVal}
    }else{ //it is linkedResource
      val = { type: 'linked', value: linkedResource };
    }
  
    return val;
  }
  
  export const getSpeaker = property => {
    let val = null;
    if(property){
      if(Array.isArray(property)){
        if(!property[0]){
          val = null;
        }else{
          val = 
              property[0].selected.length > 0
              ? property[0].selected[0].user.name
              : property[0].nameString.length > 0
                ? property[0].nameString
                : null
        }
        
      }else{ //property isnt an array --> i think this is for backwards compatibility
        val = property.selected.length > 0
              ? property.selected[0].user.name
              : property.nameString.length > 0
                ? property.nameString
                : null
      }
    }
  
    return val;
  }
  
  export const getOrgAndDesig = property => {
    let val = null;
    if(!property) return val;
    let org = property.name.internal.length > 0 ? property.name.internal[0].name : (property.name.external.length > 0  ? property.name.external[0].string : null );
    let linkId = property.name.internal.length > 0 ? property.name.internal[0]._id : null
    let desig = (property.designation && property.designation.length > 0) ? property.designation : null ;
  
    if(org){
      val = {
        value: desig ? `${desig} ` : '',
        key: org,
        // link: { id: linkId, resType: 'organisations'}
      }
    }
  
    return val;
  }
  
  
  export const getPlacesAPILocation = property => {
    let val = null;
    if(!property || property.length === 0 || Object.values(property).length === 0) return val;
    if(property.length === 1) return ( val = property[0].description );
    if(property.length > 1) return ( val = property.map(d => d.description).join(', ') );
  }
  
  export const getVillageAndPlaces = property => {
    if(!property) return;
    let villageStr = getVal(property.village);
    villageStr = villageStr ? villageStr : '';
    let placesStr  = getPlacesAPILocation(property.location);
    placesStr = placesStr ? placesStr : '';
    let separator = villageStr && placesStr && ', ';
    return villageStr + separator + placesStr;
  }
  
  export const getGroupAdmin = (property, valPath) => {
    return (property && property.internal[0])
           ? s_gVal('get',property.internal[0], valPath)
           : null;
  }
  
  export const getDyRes = (property, options = {}) => {
    if(!property) return null;
    let res = property.internal ? property.internal : [];
    if(options.external && property.external) res = [...res, ...property.external];
    return res;
  }
  
  export const getNoOfMembers = property => {
    let totInternal = propertyExists(property.internal) ? property.internal.length : 0;
    let totExternal = propertyExists(property.external) ? property.external.length : 0;
    return (totInternal + totExternal)
  }
  
  const namesToShowString = (ary, limit) => {
    let string = '';
    for(var i = 0; i < limit; i++){
      string = `${string}, ${ary[i]}`
    }
    return string;
  }
  
  const andString = (ary, limit) => {
    if(ary[limit]){
      return `and ${ary.length-1} other${ary[limit+1] ? 's' : ''}`
    }
  }
  
  export const getDyResName = (property, intValPath, extValPath, limit) => {
    let internal = propertyExists(property.internal) ? property.internal.map(d => s_gVal('get', d, intValPath)) : [];
    let external = propertyExists(property.external) ? property.external.map(d => s_gVal('get', d, extValPath)) : [];
    let mergedAry = [...internal, ...external];
  
    return mergedAry.length > 0
           ? `${namesToShowString(mergedAry, limit)} ${andString(mergedAry, limit)}`
           : null;
  }

// the single use of this function is that, if an argsArray doesnt exist, 
// then it will safely spread out an empty array
// hence avoiding errors
// its a sanitization thing
export const getArgsArray = (argsArray) => [...(argsArray ? argsArray : [])];
  