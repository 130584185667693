import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function ChevronRight(props) {
  return (
    <svg width={7} height={12} viewBox="0 0 7 12" fill="none" >
      <path d="M1 1l5 5-5 5" stroke={props.stroke || primaryColor100} strokeWidth={2} />
    </svg>
  )
}

export default ChevronRight
