import { getDaysDiff } from './momentManipulate';
import { set_getValOffQueryString as s_gVal, toArray, mathRound } from './general';
import { __GetValsFromValPaths } from './gettersV2';
import { customAlphabet } from 'nanoid';
import { getPublishedListing } from '../actions/listings';
import { triggerEndTpl, resetEndTpl } from '../actions/contributions';
import store from '../store';


export const modifierFunctions = {
  deleteValuePath: (Contribution, valPath) => {
    s_gVal('delete', Contribution, valPath)
  },
  multiply: (Contribution, tplBlocks, options) => {
    const { valuePathToGet, valuePathToSet, multiplier } = options;
    let val = s_gVal('get', Contribution, valuePathToGet);

    val = val ? mathRound.two(val * multiplier) : null;
    s_gVal('set', Contribution, valuePathToSet, val);
  },
  ADD: (Contribution, tplBlocks, options) => {
    const { subSectionPathToGet, propertyPathToGet, valuePathToSet, exceptionBlockIds } = options;

    let subSectionToSumUp = s_gVal('get', tplBlocks, subSectionPathToGet);

    let valToSet = 0;
    subSectionToSumUp.map(block => {
      if (!exceptionBlockIds.includes(block.props.id) && !!block.props.id !== false) {
        let value = s_gVal('get', Contribution, block.valuePath);

        if (value) {
          valToSet = valToSet + s_gVal('get', value, propertyPathToGet);
        }
      }
    })

    // let initVal = s_gVal('get', Contribution, valuePathToSet) || 0;
    // let valToAdd = s_gVal('get', Contribution, `${valuePathToGet}.${propertyPathToGet}`);
    // let valToSet = initVal + valToAdd;
    s_gVal('set', Contribution, valuePathToSet, valToSet);
  },
  ANSWERED_COUNT: (Contribution, tplBlocks, options) => {
    const { subSectionPathToGet, propertyPathToGet, valuePathToSet, exceptionBlockIds } = options;

    let subSectionToCount = s_gVal('get', tplBlocks, subSectionPathToGet);

    let answeredCount = 0;
    subSectionToCount.map(block => {
      if (!exceptionBlockIds.includes(block.props.id) && !!block.props.id !== false) {
        let value = s_gVal('get', Contribution, block.valuePath);

        if (value) {
          if (s_gVal('get', value, propertyPathToGet) !== 0) {
            answeredCount = answeredCount + 1
          }
        }
      }
    })

    s_gVal('set', Contribution, valuePathToSet, answeredCount);
  },
  addAndMultiply: (Contribution, tplBlocks, options) => {
    const { valuePathsToGet, valuePathToSet, multiplier } = options;
    let valAry = [];
    for (let i = 0; i < valuePathsToGet.length; i++) {
      let dVal = s_gVal('get', Contribution, valuePathsToGet[i]);
      if (dVal) {
        valAry.push(dVal)
      } else {
        valAry.push(0);
        break;
      }
    }

    let val = valAry.reduce((a, b) => { return a + b }, 0);
    val = mathRound.two(val * multiplier);
    s_gVal('set', Contribution, valuePathToSet, val);
  },
  GEN_PARTICIPANT_ID_PAGOC : (Contribution, tplBlocks, options) => {
    const { valuePathsToGet, valuePathsToSet} = options;
    //TO GET
    //meta.firstName
    //meta.lastName
    //meta.siteCode.value

    //TO SET
    //meta.participantId | main.subtitle

    const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const nanoid = customAlphabet(alphabet, 6);

    let currValuesToGet = __GetValsFromValPaths(Contribution, valuePathsToGet);
    
    currValuesToGet = currValuesToGet.map(v => !v ? 'x' : v.charAt(0) );

    let toSetVal = currValuesToGet.join('-') + '-' + nanoid(); //=> "sc8OKp"

    valuePathsToSet.map(path => s_gVal('set', Contribution, path, toSetVal) )

  },
  TRIGGER_END_TPL : (Contribution, tplBlocks, options, value) => {
    if(value.value === false){
      store.dispatch(triggerEndTpl());
    }else{
      store.dispatch(resetEndTpl());
    }
    
  }
}

export const contextualBlockMod = {

  //returned true = generate block
  //returned false = dont generate block
  //props is passed into this function if in case we want to modify some props to modifying whats displayed
  

  // events: (Contribution, block, props) => {

  //   const modObjects = {
  //     block,
  //     props
  //   }

  //   const isDateOrDateRange = dateTimeObj => {
  //     return dateTimeObj.date
  //       ? dateTimeObj.date
  //       : dateTimeObj.dateRange[1]
  //   }

  //   switch (true) {

  //     case Contribution.main && Contribution.main.kp_date_time && getDaysDiff(isDateOrDateRange(Contribution.main.kp_date_time)).dateIsPassed === true: //means today IS AFTER EVENT
  //       switch (true) {
  //         case block.displayCondition === 'show-after-event':
  //           return true;
  //         case block.displayCondition === 'hide-after-event':
  //           return false;
  //         case block.displayCondition === 'mod-after-event':
  //           modObjects[block.mod.modWhat][block.mod.key] = block.mod.modValue;
  //           return true;
  //         case block.displayCondition === 'mod-after-event-and-hide':
  //           modObjects[block.mod.modWhat][block.mod.key] = block.mod.modValue;
  //           return false;
  //         default:
  //           return true;
  //       }

  //     default: //means today IS BEFORE EVENT
  //       switch (true) {
  //         case block.displayCondition === 'show-after-event':
  //           return false;
  //         case block.displayCondition === 'show-before-event':
  //           return true;
  //         case block.displayCondition === 'mod-after-event':
  //           modObjects[block.mod.modWhat][block.mod.key] = undefined;
  //         case block.displayCondition === 'mod-after-event-and-hide': //need to reverse whatever mod is made above
  //           modObjects[block.mod.modWhat][block.mod.key] = block.mod.ogValue;
  //           return true;
  //         default:
  //           return true;
  //       }
  //   }

  // },


  //WHEN WORKING ON MILLETS SEE IF THIS CAN BE SHIFTED TO DISPLAY CONDITIONS
  questionnaires: (Contribution, block, displayConditions) => {

    const operators = {
      //toArray wraps the answer obj in an array, if it isnt already. this standardizes some operations
      SOME_IN: (answer, conditionValue /*is an array */) => toArray(answer).some(d => conditionValue.includes(d.value)),
      NOT_IN: (answer, conditionValue) => toArray(answer).every(d => !conditionValue.includes(d.value))
    }

    if (displayConditions) {
      let shouldDisplay = (
        displayConditions.every(condition => {
          return operators[condition.operator](s_gVal('get', Contribution, condition.valuePath), condition.value)
        })
      )

      if (!shouldDisplay) s_gVal('delete', Contribution, block.valuePath) //basically, if we are hiding this block, then delete the value saved against it in the Contribution Object 

      return shouldDisplay;

    } else {
      return true //always return true if there is no condition at all.
    }
  }

}

//CHECK ON WQN TPLs IF THIS IS EVEN USED ANYMORE. I THINK IT IS. OR NOT.
export const contextualProfileBlockMod = {

  userProfiles: (Profile, block, props, activeView) => {

    // if(block.displayCondition === 'show-if-content-view-active'){
    //   if(activeView !== 'profile'){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // }else{
    //   return true
    // }

    // console.log(activeView);

    if (block.displayCondition === 'only-for-small-screen') {
      // console.log(block)
      // console.log(activeView)

      if (activeView === 'small-screen') {
        return true;
      } else {
        return false;
      }
    }
    if (block.displayCondition === 'only-for-large-screen') {
      // console.log(block)
      // console.log(activeView)

      if (activeView.screenSize === 'large-screen') {
        return true;
      } else {
        return false;
      }
    } if (block.displayCondition === 'show-when-expanded') {
      // console.log(block)
      // console.log(activeView.expanded)

      if (activeView.expanded === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return true
    }
  },

  organisations: (Profile, block, props, activeView) => {

    if (block.displayCondition === 'show-if-content-view-active') {
      if (activeView !== 'profile') {
        return true;
      } else {
        return false;
      }
    } else {
      return true
    }
  },


}


/**
 * handle 2 UI state changes in ViewUserProfile / ViewOrgProfile / ViewGroupProfile : 
 * [UIViewStates, setUIViewStates ] = useState({ activeTab : xx, expandedHead : false })
 * 
 * make sure to pass this state object to the <ViewProfileTplBlockGenerator/>
 * then inside <ViewProfileTpl... />, 
 * you will have to completely change the code between line 52-55, to make this work,
 * HINT: refer to line 116-118 in ContributeTplBody
 * ill leave it up to you, to solve this :)
 * 
 * Now in the template, to use this modifier all you have to do is this:
 * 
 * //content block:
 * {
 *  comp: xx,
 *  valuePath: xx,
 *  displayConditions : [
 *  { 
 *    operator : "ACTIVE_UI_STATE_IS",
 *    activeStateValuePath : 'expandedHead',
 *    value : 'true'
 *  }
 * ]
 * }
 */

// UNUSED AT THE MOMENT

// export const contextualProfileBlockModV2 = ( activeStates, displayConditions ) => {


//   const operators = {
//     //toArray wraps the answer obj in an array, if it isnt already. this standardizes some operations
//     ACTIVE_UI_STATE_IS : (activeValue, conditionValue) => activeValue === conditionValue
//   }

//   if(displayConditions){
//     let shouldDisplay = (
//       displayConditions.every(condition => {
//         return operators[condition.operator](s_gVal('get', activeStates, condition.activeStateValuePath), condition.value)
//       })
//     )

//     return shouldDisplay;

//   }else{
//     return true //always return true if there is no condition at all.
//   }

// }



