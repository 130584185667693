import React, {Fragment} from "react";


const Meta = (props) => {

  

  return (
    <h6 className={`meta ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children || props.value}</h6>
  );

}


export default Meta;
