import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Instagram_solid_SM(props) {

  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.694 7.358A3.453 3.453 0 016.09 3.962c1.188-.02 2.496-.036 3.566-.036 1.07 0 2.377.016 3.565.036a3.453 3.453 0 013.398 3.396c.02 1.188.037 2.496.037 3.567 0 1.06-.017 2.355-.037 3.535a3.476 3.476 0 01-3.43 3.411l-3.533.055-3.534-.055a3.476 3.476 0 01-3.43-3.41c-.02-1.18-.036-2.476-.036-3.537 0-1.07.016-2.378.037-3.566zm11.622-.496a.91.91 0 11-1.818 0 .91.91 0 011.818 0zm-2.11 4.064a2.55 2.55 0 11-5.1 0 2.55 2.55 0 015.1 0zm1.2 0a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
        fill={props.fill || primaryColor100}
      />
    </svg>
  )
}

export default Instagram_solid_SM
