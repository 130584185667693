import {
  UPDATE_SCREEN_WIDTH,
  SET_MODAL_OVERLAY,
  SET_APP_ONLINE_STATUS
}
from './types';

import {setAnnouncement, removeAnnouncement} from './alert';

export const updateOnlineStatus = (status) => dispatch => {
  dispatch({ type: SET_APP_ONLINE_STATUS, payload: status })
  if(status === 'online'){
    dispatch(setAnnouncement(
      "App is back online!",
      'success',
      3000
    ))
    dispatch(removeAnnouncement('offline'))
  }else 
  if( status === 'offline'){
    dispatch(setAnnouncement(
      "App is Offline :(",
      'danger',
      null,
      { id: 'offline' } //we need the name so that can can remove this announcement by name when status changes to online
    ))
  }
}

// export const setAppOffline = () => dispatch => {
//   dispatch({ type: SET_APP_ONLINE_STATUS, payload: 'offline' })
//   dispatch(setAnnouncement(
//     "App is Offline :(",
//     'danger',
//     null
//   ))
// };



// export const setAppBackOnline = () => dispatch => {
//   dispatch({ type: SET_APP_ONLINE_STATUS, payload: 'online' })
//   dispatch(setAnnouncement(
//     "App is back online!",
//     'success',
//     3000
//   ))
// };

export const updateScreenWidth = (width) => dispatch => dispatch({ type: UPDATE_SCREEN_WIDTH, payload: width });

export const setModalOverlay = bool => dispatch => dispatch({type: SET_MODAL_OVERLAY, payload: bool})
