import React, {Fragment} from "react";

import { red, green, yellow } from '../../../sass/vars.scss';

const KPTooltip = ({className, style, text, type}) => {

  const typeMapping = {
    'danger' : red
  }

  return (
  <div className={`kp-tooltip-wrapper ${className}`} style={style}>
    <h5 className='h6' style={type && {color: typeMapping[type]}}>{ text }</h5>
  </div>
)}

export default KPTooltip;
