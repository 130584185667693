import React from "react";
import { toArray } from "../../../utils/general";
import Meta from "../../typo/Meta";

export const StatusTag = (props) => {
  //this passes a props.status.type which gives success | failed | inactive , but we are not using it right now

  const statusTypeMap = props.statusTypeMap || {
    neutral: null,
    success: true,
    danger: false,
  };

  const getStatusType = () => {
    let type = "";
    Object.values(statusTypeMap).map((val, i) => {
      if(Array.isArray(val)){
        if(val.includes(props.value.value)){
          type = Object.keys(statusTypeMap)[i];
        }
      }else{ //else it is string | boolean | null
        if(val === props.value.value){
          type = Object.keys(statusTypeMap)[i];
        }
      }
    });
    return type;
  };

  return (
    <div
      className={`statusTag-wrapper ${getStatusType()} ${props.className}`}
      style={props.style}
      >
      {props.value && (
        <Meta className="statusTag" style={{ fontWeight: "normal" }}>
          {props.value.display}
        </Meta>
      )}
    </div>
  );
};

export const StatusTagOutlineStyle = (props) => {
  return <StatusTag {...props} className="statusTag-wrapper--outlineStyle" />;
};
