import React, { Fragment } from "react";
import { ButtonSecondary } from "../../generalUI/KPButtons";
import KPLink from "../../generalUI/KPLink";
import "./_style.scss";
const ModalButtonGroup = ({ btnConfig, confirmDisabled }) => {
  return (
    <div className="kp-modal__btn-group">
    { btnConfig.reject && <KPLink className='kp-modal__btn' onClick={btnConfig.reject} linkType="action" text="Cancel" /> }
      <ButtonSecondary className='kp-modal__btn' onClick={btnConfig.accept} disabled={confirmDisabled}>
        {btnConfig.acceptBtnText || 'Delete'}
      </ButtonSecondary>

    </div>
  );
};

export default ModalButtonGroup;
