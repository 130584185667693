import React, { useState, Fragment } from "react";

import { set_getValOffQueryString as s_gVal } from '../../../utils/general';
import { getOrgAndDesig, getVal } from '../../../utils/getters';
import Location14 from '../../icons/Location14';
import Label__M from '../../typo/Label__M';

const KPMetaPrimary = ({ className, data, metaObj, value : masterVal, style }) => { //masterVal is nothing but the 'main value' that is passed through by the tpl block generator, which feeds of the valuePath defined in the template files on postman
  //can either pass value which has to be a text string or an array of text strings.
  //or can pass valuePath+metaObj, which would only come from templates (sometimes)
  //now can also passs icon name as string

  let functions = {
    getOrgAndDesig : (property) => getOrgAndDesig(property),
    makeMasterValDataVal : (masterVal) => { return {value : masterVal}}
  }

  const Icons = {Location14}
  let Icon = data.icon && Icons[data.icon];

  let value = Array.isArray(data.value) ? data.value.join(', ') : data.value;
  let key = data.key;
  if(metaObj && data.keyPath){ // in the case of community, meta obj is the whole Profile obj. in knowledge it is only the meta obj. but ths has to change.
    key = s_gVal('get', JSON.parse(metaObj), data.keyPath);
  }
  if(metaObj && data.valuePath){
    value = getVal(JSON.parse(metaObj), data.valuePath)
  }
  
  if(metaObj && data.keyValFn){
    key = functions[data.keyValFn](masterVal).key
    value = functions[data.keyValFn](masterVal).value;
  }

  if(data.keyValFn){
    key = functions[data.keyValFn](masterVal).key
    value = functions[data.keyValFn](masterVal).value;
  }

  return (
    <Fragment>
    { ( value || key ) &&
      <div className={`kp-meta-primary ${className}`} style={style}>
      { key &&
        <Label__M className='kp-meta-primary__text kp-meta-primary__text--key'>{ key }</Label__M> }
      { value &&
        <Label__M className='kp-meta-primary__text kp-meta-primary__text--value'>{ value }</Label__M> }
      </div> }
    </Fragment>
  );
}

export default KPMetaPrimary
