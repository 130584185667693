import React, { Fragment, useState, useEffect } from "react";
import InputText from "../../typo/InputText";
import Label__L from "../../typo/Label__L";
import Meta from "../../typo/Meta";
import TextLoader from "../../loaders/TextLoader";
import { isStringMatch } from "../../../utils/general";
import { getArgsArray } from "../../../utils/getters";
import { __ExtractRichOrPlainText } from "../../../utils/gettersV2";
import {
  genYearsArray_v2,
  genMonthsArray_v2,
} from "../../../utils/momentManipulate";
import CheckboxElement from "../../generalUI/CheckboxElement";

import { greyColor40, greyColor70, white } from "../../../sass/vars.scss";
import ProfileImgDisplay from "../../generalUI/ProfileImgDisplay";

export const Options = React.forwardRef((props, ref) => {
  const {
    //from root
    options,
    optionsFn,
    variant,
    lightboxHeight,
    optionsAnchor,
    value,
    id,
    injectOtherOption,
    //from Dropdown
    searchString,
    yOrientation,
    showOptions,
    handleSelect,
    isMultiSelect,
    isCombobox,
    isTagsInput,
  } = props;

  const [pValue, setPValue] = useState(value);

  useEffect(() => {
    //update only when options are shut
    if (!showOptions) setPValue(value);
  }, [showOptions]);

  const filterOptions = (options, variant, searchString) => {
    if (["combobox--single", "combobox--multi"].indexOf(variant) === -1)
      return options;
    if (options.some((d) => d.loading === true)) return options; //if any of the options are still loading, then dont even try to do this filter thing
    //else
    return options.filter((d) => isStringMatch(d.display, searchString));
  };

  const optionsFns = {
    genYearsArray: (...args) => genYearsArray_v2(...args),
    genMonthsArray: (...args) => genMonthsArray_v2(...args),
  };

  const genOptionsData = (options, optionsFn) => {
    switch (true) {
      case !!options:
        let otherOp = { display: "Other", value: "other" };
        return injectOtherOption ? [...options, otherOp] : options;
      case !!optionsFn:
        return optionsFns[optionsFn.fn](...getArgsArray(optionsFn.args));
      default:
        return { display: "Options Undefined", value: "undefined" };
    }
  };

  const opIsSelected = (thisOpData, options = {}) => {
    let valueToConsider = options.pValue || value;
    // console.log({valueToConsider})
    if (!valueToConsider) return false;
    return isMultiSelect
      ? valueToConsider.some((d) => d.value === thisOpData.value)
      : valueToConsider.value === thisOpData.value;
  };

  const setLightboxHeight = () => {
    if (lightboxHeight) return `-height-${lightboxHeight}-`; //override via props
    //else
    let optionsLength = filterOptions(
      genOptionsData(options, optionsFn),
      variant,
      searchString
    ).length;
    if (optionsLength <= 8) {
      return "-height-auto-";
    } else if (optionsLength > 8) {
      return "-height-medium-";
    } //default
  };

  const createOption = (d) => {
    if (isMultiSelect) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {!isTagsInput && (
            <Fragment>
              <div className="OKE-Dropdown__checkboxClickArea"></div>
              <CheckboxElement
                isSelected={opIsSelected(d)}
                style={{ marginRight: "2rem" }}
              />
            </Fragment>
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {d.image && (
              <ProfileImgDisplay
                avatar={d.image}
                size={"2rem"}
                style={{ marginRight: "1rem" }}
                placeholderInitial={d.display[0]}
                className="OKE-Dropdown__optionImg"
              />
            )}
            {/* same for d.icon */}
            <div>
              <InputText className="OKE-Dropdown__optionText">
                {__ExtractRichOrPlainText(d.display)}
              </InputText>
              {d.desc && (
                <Meta className="OKE-Dropdown__optionDesc">
                  {__ExtractRichOrPlainText(d.desc)}
                </Meta>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <InputText className="OKE-Dropdown__optionText">
            {__ExtractRichOrPlainText(d.display)}
          </InputText>
          {d.desc && (
            <Meta className="OKE-Dropdown__optionDesc">
              {__ExtractRichOrPlainText(d.desc)}
            </Meta>
          )}
        </div>
      );

      // <InputText className='OKE-Dropdown__optionText'>{d.display}</InputText>
    }
  };

  const createSelectedOptions = () => (
    <div
      style={{
        borderBottom: `1px solid ${greyColor40}`,
        display: pValue && pValue.length > 0 ? "block" : "none",
      }}
    >
      <Label__L
        style={{ padding: "2rem", paddingBottom: 0, color: greyColor70 }}
      >
        selected
      </Label__L>
      {pValue &&
        pValue.length > 0 &&
        pValue.map((d) => (
          <li
            className="OKE-Dropdown__option -selected-"
            onClick={(e) => !d.loading && handleSelect(id, d)}
            style={{ backgroundColor: white }}
          >
            {d.loading ? (
              <TextLoader
                style={{ width: "5rem", height: "1.5rem", margin: "0 1rem" }}
              />
            ) : (
              createOption(d)
            )}
          </li>
        ))}
    </div>
  );

  return (
    <ul
      ref={ref}
      className={`OKE-Dropdown__Options ${setLightboxHeight()}`}
      style={{
        display: showOptions ? "block" : "none",
        ...(optionsAnchor ? { [optionsAnchor]: 0 } : { left: 0 }),
        ...(yOrientation === "bottom"
          ? { bottom: 0, transform: "translateY(100%)" }
          : { top: 0, transform: "translateY(-100%)" }),
      }}
    >
      {isMultiSelect && createSelectedOptions()}
      {filterOptions(
        genOptionsData(options, optionsFn),
        variant,
        searchString
      ).map((d) => {
        if (
          !isMultiSelect ||
          !pValue ||
          pValue.length === 0 ||
          !opIsSelected(d, { pValue })
        ) {
          //if is not multiselect then just render the option. but if it is, then render only the unselected options.
          return (
            <li
              className={`OKE-Dropdown__option ${
                opIsSelected(d) ? "-selected-" : ""
              }`}
              onClick={(e) => !d.loading && handleSelect(id, d)}
              key={d.value}
            >
              {d.loading ? (
                <TextLoader
                  style={{ width: "5rem", height: "1.5rem", margin: "0 1rem" }}
                />
              ) : (
                createOption(d)
              )}
            </li>
          );
        }
      })}
    </ul>
  );
});
