import React from "react"
import {primaryColor100, white} from '../../../sass/vars.scss';

function Download(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props}>
      <circle cx={21} cy={21} r={21} fill={white} />
      <path
        d="M17.052 20.053L21 24l4-4"
        stroke={primaryColor100}
        strokeLinecap="round"
      />
      <path
        stroke={primaryColor100}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.982 23.502v-9.857M27.285 22.144v5.714H14.714v-5.714"
      />
    </svg>
  )
}

export default Download
