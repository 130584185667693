import React, { Fragment } from "react";

import {primaryColor10} from '../../../sass/vars.scss'

const TextLoader = ({
  style
}) => {

  return (
    <div className='textLoader' style={{backgroundColor : primaryColor10, ...style}}></div>
  );
}

export default TextLoader
