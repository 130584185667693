import { set_getValOffQueryString as s_gVal, toArray} from './general';
import { getDaysDiff } from './momentManipulate';

export default (Contribution, block, displayConditions) => {

    const operators = {
      //toArray wraps the answer obj in an array, if it isnt already. this standardizes some operations
      SOME_IN : (answer, conditionValue /*is an array */) => toArray(answer).some(d => conditionValue.includes(d.value))  , 
      NOT_IN : (answer, conditionValue) => {
        if(!!answer === false) return false //dont show block
        //else
        let showBlock = toArray(answer).every(d => !conditionValue.includes(d.value));

        return showBlock; 
      },
      EVENT_DATE_CONDITION : (Contribution, block, options) => {
        
        const modObjects = {
          block,
          props : block.props
        }
    
        const isDateOrDateRange = dateTimeObj => {
          return dateTimeObj.date
            ? dateTimeObj.date
            : dateTimeObj.dateRange[1]
        }
    
        switch (true) {
    
          case Contribution.main && Contribution.main.kp_date_time && getDaysDiff(isDateOrDateRange(Contribution.main.kp_date_time)).dateIsPassed === true: //means today IS AFTER EVENT
            switch (true) {
              case options.modifier === 'show-after-event':
                return true;
              case options.modifier === 'hide-after-event':
                return false;
              case options.modifier === 'mod-after-event':
                modObjects[options.modWhat][options.key] = options.modValue;
                return true;
              case options.modifier === 'mod-after-event-and-hide': //probably unnecesary now
                modObjects[options.modWhat][options.key] = options.modValue;
                return false;
              default:
                return true;
            }
    
          default: //means today IS BEFORE EVENT
            switch (true) {
              case options.modifier === 'show-after-event':
                return false;
              case options.modifier === 'show-before-event':
                return true;
              case options.modifier === 'mod-after-event':
                modObjects[options.modWhat][options.key] = undefined;
                return true;
              case block.displayCondition === 'mod-after-event-and-hide': //probably unnecesary now
                modObjects[options.modWhat][options.key] = options.ogValue;
                return true;
              default:
                return true;
            }
        }
      }
    }
    
    //sometimes we have more complex, custom functions which decide whether to display a block or not
    //or sometimes even modify the data of another block.
    //this currently occurs only in the events template
    if(displayConditions && displayConditions.length === 1 && displayConditions[0].custom){
      return operators[displayConditions[0].operator](Contribution, block, displayConditions[0].custom) 
    } else
    if(displayConditions){
      let shouldDisplay = (
        displayConditions.every(condition => {
          return operators[condition.operator](s_gVal('get', Contribution, condition.valuePath), condition.value)
        })
      )

      if(!shouldDisplay) s_gVal('delete', Contribution, block.valuePath) //basically, if we are hiding this block, then delete the value saved against it in the Contribution Object 

      return shouldDisplay;
      
    }else{
      return true //always return true if there is no condition at all.
    }
}