import store from '../store';
import axios from "axios";

import history from "../history"; //history object that allows for routing from outside components.
import { setAlert } from "./alert";
import { getTPL } from "./contributions";
import { checkObjEquivalent } from "../utils/general";
import {
  INIT_NESTED_PUBLISHING,
  RESET_NESTED_PUBLISHING

} from "./types";



import { formValidationCheck, set_getValOffQueryString } from '../utils/general';

import {__GetContentTypeConfig} from '../utils/gettersV2';

export const resetNestedPublishing = () => dispatch => dispatch({type: RESET_NESTED_PUBLISHING});

export const triggerNestedPublishing = (
  contentType, //content type to be embedded
  Profile, //all profile data  wherein it is being embedded
  contentBlockId //content block in profile, wherein it is being embedded
) => async dispatch => {
  try{

    dispatch({
      type : INIT_NESTED_PUBLISHING,
      payload : {
        contentBlockId,
        prefillBlock : {
          id : 'kp_community_tag',
          value : {
            external: [],
            internal: [
              {
                resId : Profile,
                resModel : Profile.meta.profile_type.substring(0, Profile.meta.profile_type.length - 1)
              }
            ],
            searchString: ''
          }
        },
        alertMsg : { success : 'Your update was posted successfully!'},
        redirectPath : {pathname: `/edit/${Profile.meta.profile_type}/${Profile._id}`} //#${contentBlockId} : later
      }
    })

    await dispatch(
      getTPL(
        contentType,
        null /*clearToProceed : redundant*/,
        'edit',
        store.getState().auth.user,
        store.getState().auth.roles,
        true, /*redirectToSettingsPage : auto redirects after the tpl is fetched from server*/
        'contribute', /* tpl category*/
        (err) => { if(err) throw err; }
      ))

    
  }catch(err){
    if(err) console.log('err in triggerNestedPublishing', err);
    dispatch({
      type : RESET_NESTED_PUBLISHING
    })
  }
}

//PENDING //the above action and this should ideally be one and the same
export const triggerUpdatePublishing = (contentType, subType, id) => async dispatch => {
  try{

    dispatch({
      type : INIT_NESTED_PUBLISHING,
      payload : {
        redirectPath : {pathname: `/published-page/${contentType}`, search: `id=${id}` } //#${contentBlockId} : later | for anchor linking
      }
    })

    await dispatch(
      getTPL(
        contentType,
        null /*clearToProceed : redundant*/,
        'edit',
        store.getState().auth.user,
        store.getState().auth.roles,
        true, /*redirectToSettingsPage : auto redirects after the tpl is fetched from server*/
        'contribute', /* tpl category*/
        (err) => { if(err) throw err; },
        { contentSubType : subType, parentResourceId : id }
      ))

    
  }catch(err){
    if(err) console.log('err in triggerUpdatePublishing', err);
    dispatch({
      type : RESET_NESTED_PUBLISHING
    })
  }
}
