import React from "react";
import { Route, Redirect, withRouter, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import {__ApiAccessCheck } from '../../../utils/accessControl';
import Loader from '../../generalUI/Loader';

const PrivateRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  location,
  apiAccessCheck, //0 = name of apiAccessConfig key in backend ; 1 = paramString
  path,
  ...rest
}) => {

  //YOU HAVE TO PASS apiAccessCheck if you want this Route to be private in anyway
  //ELSE it will be a public route

  let {_ApiAccess} = useSelector(s => s.environment.envConfig);

  const hasAccess = () => {
    if(!apiAccessCheck) return isAuthenticated;
    //else
    let paramIdx = path.split('/').indexOf(apiAccessCheck[1]);
    let param = location.pathname.split('/')[paramIdx];
    return __ApiAccessCheck(param, isAuthenticated, _ApiAccess, apiAccessCheck[0])
  }

  return(
    <Route
      {...rest}
      render={props =>
        loading
        ? <Loader type='page-loader'/>
        : !hasAccess()
          ? <Redirect 
              to={{
                pathname: '/login_registration',
                state: {
                  from: location.pathname+location.search,
                  authType: 'login'
                }
              }}
              />
          : <Component {...props} />
      }
    />
  )
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
