import React, {Fragment} from "react";

const Loader = (props) => (
  <div className={
      `kp-loader-wrapper
      ${props.className}
      ${props.type || 'page-loader'}
      ${props.size || 'large'}
      color-${props.color || 'primary'}`}
      style={props.style}
      >
    <div className='kp-loader'/>
  { props.text && <h6 className='h6 medium' style={{marginTop: '1rem'}}>{props.text}</h6> }
  </div>
)

export default Loader;
