import * as React from "react"

function XScr(props) {
  return (
    <svg width={12} height={24} viewBox="0 0 12 24" fill="none" {...props}>
      <path
        d="M4.208 16.843L8 13.05a1 1 0 000-1.414L4.208 7.843"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default XScr
