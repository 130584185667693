import React, { Fragment, useEffect, useState, useRef, forwardRef } from "react";

import {
  EditorState,
  CompositeDecorator,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

import { debounce } from 'lodash';
//COMPONENTS
//general UI
import KPLabel from '../../generalUI/KPLabel';
import KPLinkInput from '../../inputs/KPLinkInput'
import KPHintsBlock from '../../hints/KPHintsBlock';
import KPRichEditor from '../KPRichEditor';
import KPRichToolbar from '../KPRichToolbar';
import KPRichInlineToolbar from '../KPRichInlineToolbar';
import KPResourceSelectPopUp from '../../modals/KPResourceSelectPopUp';

//utils
import {
  useTrackEditorFocus,
  createAtomicBlockEntity,
  renderAtomicBlock,
  setClassNamesToBlockTypes,
  handleKeyCommand,
  hideShowInlineToolbar,
  validateCharLimit,
  enforceFixedBlockType,
  handleInsertCTA
} from '../../../utils/richInputUtils';

import { handleUploadChange } from '../../../utils/richInputUtils'
import { insertLink, convertUrlStringsToInlineLinks } from '../../../utils/richInlineLinkUtils';

import { checkIfRichInputIsPopulated, injectHttps } from '../../../utils/general';

//sass var exports
import {  
  greyColor100
} from '../../../sass/vars.scss';

import KPRichInlineImage from "../KPRichInlineImage";

import { getLabelProps } from "../../../utils/contentBlock";

var changeTriggeredByEditorBlur = false;

const debouncedOnChange = debounce(
  (newEditorState, onChange, id, changeHandlerOps) => handleOnChange(newEditorState, onChange, id, changeHandlerOps),
  400,
  { leading: false, trailing: true }
);

const handleOnChange = (newEditorState, onChange, id, changeHandlerOps) => {
  let contentStateRaw = convertToRaw(newEditorState.getCurrentContent());
  onChange && onChange(id, contentStateRaw, changeHandlerOps);
}

const KPRichInput = props => {

  console.log('RERENDER RICHINPUT : ' + props.id);
  
  //#1 : init refs for editor & editor-wrapper. wrapper ref helps us track focus change. editor ref literally helps us control focus change.
  const editorRef = useRef(null)
  const wrapperRef = useRef(null)
  const inlineToolbarRef = useRef(null)

  const [tempReadOnly, setTempReadOnly] = useState(false); //for when the editor's child comp is focussed
  const [showCardLinkInput, setShowCardLinkInput] = useState(false)
  const [showCharLimitAlert, setShowCharLimitAlert] = useState(false)
  const [inlineToolbar, setInlineToolbar] = useState({ show: false, position: {} })
  const [blockHideInlineToolbar, setBlockHideInlineToolbar] = useState(false);
  const [cursorToEnd, setCursorToEnd] = useState(0);

  const handleCloseCardLinkInput = () => setShowCardLinkInput(false)
  const handleSetTempReadOnly = (boolean) => setTempReadOnly(boolean)
  const handleCloseLinkInput = () => setTextLinkState({ ...textLinkState, showLinkInput: false })

  //temp hack to push cursor to end after link is inserted
  useEffect(() => { setEditorState(EditorState.moveFocusToEnd(editorState)) }, [cursorToEnd]);

  const embedCardLinks = (val) => {
    createAtomicBlockEntity(
      { editorState, onEditorChange },
      'KPCardLinksDisplay',
      'IMMUTABLE',
      { 
        value: val, 
        contentTypeEmbedOptions: props.contentTypeEmbedOptions,
        profileTypeEmbedOptions: props.profileTypeEmbedOptions 
      }
    )
    setShowCardLinkInput(false);
  }


  const [
    editorFocussed, 
    setEditorFocussed, 
    textLinkState, 
    setTextLinkState
  ] = useTrackEditorFocus(wrapperRef, props.trackEditorFocus);

  const [linkInputOnConfirmHandler,setLinkInputOnConfirmHandler] = useState('insertLink');

  //-------------- START SET INIT EDITOR STATE
  //draftjs basic data structure:
  /*{
    block : [
      ...
    ],
    entityMap : {}
  }*/

  const decorator = new CompositeDecorator([
    { strategy: findLinkEntities, component: RichInlineLink },
  ]);

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(decorator) //we cant createWithContent here, because if we have a LLLOT of content, then the convertFromRaw fires everytime on each render which is expensive.
  );

  const [firstUpdateHappened, setFirstUpdateHappened] = useState(false);

  //-------------- END SET INIT EDITOR STATE


  //-------------- START HANDLING EDITOR CHANGE

  // useImperativeHandle(ref, () => ({
  //   onEditorChange: (key, editorState) => onEditorChange(key, editorState)
  // }));

  const onEditorChange = (key, editorState) => {
    
    if(!changeTriggeredByEditorBlur){

      changeTriggeredByEditorBlur = false;

      let newEditorState = editorState;
      
      //-------------------------------------------------------------------//
      //NOTE: THIS WAS FOR CONVERTING TYPED TEXT INTO LINKS WHEN NECESSARY. VERY EXPENSIVE OPERATION. GOTTA BE A BETTER WAY TO DO THIS.

      // let editorStateWithLink = convertUrlStringsToInlineLinks(
      //                             {editorState, setEditorState},
      //                             setTextLinkState,
      //                             setCursorToEnd,
      //                             cursorToEnd
      //                           );


      // // this returns only if link is inserted so...
      // newEditorState = editorStateWithLink !== 'unchanged' ? editorStateWithLink : newEditorState;


      //-------------------------------------------------------------------//


      if (!!props.charLimit === true) {
        newEditorState = validateCharLimit(
          editorState,
          { showCharLimitAlert, setShowCharLimitAlert },
          { cursorToEnd, setCursorToEnd },
          props.charLimit
        );
      }

      setEditorState(newEditorState);
      // setTimeout(() => debouncedOnChange(newEditorState, props.onChange, props.id), 0);
      // debouncedOnChange(newEditorState, props.onChange, props.id, props.passChangeHandlerOps && props);
      
      setTimeout(() => handleOnChange(newEditorState, props.onChange, props.id, props.passChangeHandlerOps && props), 0);
    }else{
      changeTriggeredByEditorBlur = false; //reset it.
    }
    
  }

  useEffect(() => {
    if (props.forceValue) {
      
      if (!props.forceValue.entityMap) props.forceValue.entityMap = {};
      const newEditorState = EditorState.push(
        editorState,
        convertFromRaw(props.forceValue),
        "update-editor-text"
      );

      setEditorState(newEditorState)
    }
  }, [props.forceValue])

  const clickHideInlineToolbar = e => {
    if (inlineToolbar.show === true) {
      
      if (!inlineToolbarRef.current.contains(e.target)) {
        setInlineToolbar({ show: false, position: {} });
      }
    }
  }

  const keyupHideInlineToolbar = (e) => {
    if (inlineToolbar.show === true &&
      window.getSelection().anchorOffset === window.getSelection().focusOffset) {
      
      setInlineToolbar({ show: false, position: {} });
    }
  }

  useEffect(() => {
    if (props.forceFocus === true) { //used only in comments. but this can probably be removed once we implement new 'controlled' structure
      editorRef.current.focus();
    }
  }, [props.forceFocus])

  useEffect(() => {
    document.addEventListener("click", clickHideInlineToolbar);
    document.addEventListener("keyup", keyupHideInlineToolbar);

    return () => {

      document.removeEventListener("click", clickHideInlineToolbar);
      document.removeEventListener("keyup", keyupHideInlineToolbar);
    }
  }, [inlineToolbar])

  //side effects when editorState changes
  useEffect(() => {

    // --- enforce fixed block type if prop says so
    !props.readOnly && 
    props.fixedBlockType && 
    firstUpdateHappened && 
    enforceFixedBlockType(editorState, onEditorChange, props.fixedBlockType); 
    // ^^^ these 10,000 useeffects are causing a lot of problems. Cant predict which one fires when, so data updates aren't reflecting in other use effects. for example, in readonly mode, the editorState update that happens in componentMount useffect, doesnt reflect here. 

    // --- hide / show inline toolbar
    (props.inlineFormattable || props.richFormattable || props.superRichFormattable || ['textFormatting', 'textAndImageFormatting'].indexOf(props.formattingOptions) !== -1) &&
      hideShowInlineToolbar(setInlineToolbar, blockHideInlineToolbar, wrapperRef);

    // --- setting custom placeholder
    props.getEditorState && props.getEditorState(editorState);

    //-------------------------------------------------------------------//
    //NOTE: THIS WAS FOR CUSTOM PLACEHOLDER. VERY EXPENSIVE OPERATION

    //   let contentStateRaw = convertToRaw(editorState.getCurrentContent());
    // ( contentStateRaw.blocks.length > 1 &&
    //   checkIfRichInputIsPopulated(contentStateRaw) === false  )
    //   ? setShowCustomPlaceholder(true)
    //   : setShowCustomPlaceholder(false)

    //-------------------------------------------------------------------//

  }, [editorState])

  useEffect(() => {
    let initEditorValue = props.value //parent value
    if (initEditorValue) {
      if (!initEditorValue.entityMap) initEditorValue.entityMap = {}; //for cases when data fetched from the DB doesnt already have the entitymap obj
      // let newEditorState = EditorState.set(editorState, { currentContent: convertFromRaw(initEditorValue) });
      let newEditorState = EditorState.push(editorState, convertFromRaw(initEditorValue), '');
      setEditorState(newEditorState);
      setFirstUpdateHappened(true);

      //for now it is important to repeat this here as well ( along with the editorState useEffect );
      //because on first load, the editorState useEffect fires, the editorState is empty, so the fixedBlockEnforcing erases whatever value that may be being fed into the editor.
      //to sort that out, we ensure in this useEffect ( which fires only on first load ) that the props.value is indeed passed into the editor while the fixedBlockType is being enforced
      !props.readOnly && props.fixedBlockType && enforceFixedBlockType(newEditorState, onEditorChange, props.fixedBlockType); 
    }else{
      !props.readOnly && props.fixedBlockType && enforceFixedBlockType(editorState, onEditorChange, props.fixedBlockType); 
    }

    
  }, [])

  const [showCustomPlaceholder, setShowCustomPlaceholder] = useState(false);

  const handleEditorBlur = () => {
    
    changeTriggeredByEditorBlur = true;

    //-------------------------------------------------------------------//
    // ACTIVATE THIS IF USING DEBOUNCE > READ INLINE COMMENT
    // handleOnChange(editorState, props.onChange, props.id, props.passChangeHandlerOps && props); //now, when you deselect the editor realllly quickly without giving time for the debounceOnChange to run (in editorOnChange), (this can happen if you write something and really quickly hit 'tab'), then the debounce doesn't run, and that stuff that you really quickly typed. doesnt get sent to the parent. which is why we have added this line. maybe there is a more elegant solution, but for later..
    //-------------------------------------------------------------------//


    //-------------------------------------------------------------------//
    //NOTE: THIS WAS FOR CUSTOM PLACEHOLDER. VERY EXPENSIVE OPERATION

    //   let contentStateRaw = convertToRaw(editorState.getCurrentContent());
    // ( contentStateRaw.blocks.length > 1 &&
    //   checkIfRichInputIsPopulated(contentStateRaw) === false )
    //   ? setShowCustomPlaceholder(true)
    //   : setShowCustomPlaceholder(false)

    //-------------------------------------------------------------------//
  }

  //-------------- END HANDLING EDITOR CHANGE

  const handleDroppedFiles = (selection, files) => {
    
    if (files) {
      
      files.map(file => {

        handleUploadChange(
          { target: { files } },
          'KPRichInlineImage',
          `images`,
          ['jpg', 'jpeg', 'png'],
          { editorState, onEditorChange }
        )

      })
    }
    return 'handled';
  }


  const richEditor = (
    <KPRichEditor
      id={props.id}
      editorFocussed={editorFocussed}
      readOnly={props.readOnly === false ? tempReadOnly : props.readOnly}
      blockRendererFn={block => renderAtomicBlock(block, { editorRef, editorState, onEditorChange }, handleSetTempReadOnly, props.readOnly)}
      blockStyleFn={setClassNamesToBlockTypes}
      editorState={editorState}
      handleKeyCommand={(!props.readOnly && props.richFormattable) && ((command, editorState) => handleKeyCommand(command, editorState, onEditorChange))}
      handleDroppedFiles={(selection, files) => handleDroppedFiles(selection, files)}
      onChange={(editorState) => onEditorChange('', editorState)}
      onBlur={handleEditorBlur}
      ref={editorRef}
      placeholder={props.placeholder}
    />
  )

  //#8 : finally render the html
  return (
    <div className={`kp-rich-editor-wrapper ${props.className} ${props.fixedBlockType ? props.fixedBlockType + '-fixed-editor' : ''}`}>
      <div className='kp-rich-editor__label-and-custom-placeholder-wrapper'>
        <KPLabel 
          {...getLabelProps(props) } 
          errorMsgs = {
            props.charLimit && showCharLimitAlert 
            ? [ ...getLabelProps(props).errorMsgs, 'Character Limit Reached!']
            : getLabelProps(props).errorMsgs
          }
          />
        {showCustomPlaceholder && <h4 className='serif h4 medium kp-rich-input__custom-placeholder'>{props.placeholder}</h4>}
      </div>
      <div ref={wrapperRef} className={`kp-editor-and-toolbar-wrapper ${props.listStyleImage ? props.listStyleImage : ""}`} style={{ position: 'relative' }}>
        <div
          ref={inlineToolbarRef}
          id='inline_toolbar'
          className='kp-inline-toolbar'
          style={{
            top: inlineToolbar.position.y,
            left: inlineToolbar.position.x,
            display: inlineToolbar.show ? 'block' : 'none',
            borderRadius: '5rem',
            padding: '0 2.2rem',
            backgroundColor: greyColor100,
            zIndex: 10
          }}>
          <KPRichInlineToolbar
            
            editor={{
              editorId: props.id,
              editorRef,
              editorState,
              onEditorChange,
            }}
            formattingOptions={props.formattingOptions}
            setTextLinkState={setTextLinkState}
            setInlineToolbar={setInlineToolbar}
          />
        </div>
        {richEditor}
        {!props.readOnly && (props.hints || props.richFormattable) &&
          <div
            className='kp-rich-format-toolbar__hints-and-toolbar-wrapper'
            style={{ height: (props.hints && editorFocussed) ? "5rem" : "auto" }}
          >
            {(!props.readOnly && props.hints) &&

              <KPHintsBlock
                className="kp-rich-format-toolbar__hints-block"
                hints={props.hints}
                editorFocussed={editorFocussed}
              // style={ `display: ${editorFocussed ? "block" : "none"}`}
              />

            }

            {(!props.readOnly && props.richFormattable) &&  //placing this outside the wrapper div, makes it disappear when we click on it. need to revisit the useTrackEditorFocus function to fix this
              <KPRichToolbar
                editorFocussed={editorFocussed}
                editor={{ editorId: props.id, editorRef, editorState, setEditorState }}
                onEditorChange={onEditorChange}
                textLinkState={textLinkState}
                setTextLinkState={setTextLinkState}
                handleCloseLinkInput={handleCloseLinkInput}
                handleShowCardLinkInput={() => setShowCardLinkInput(true)}
                setLinkInputOnConfirmHandler={setLinkInputOnConfirmHandler}
                superRichFormattable={props.superRichFormattable}
                formattingOptions={props.formattingOptions}
                hintsExists ={!!props.hints}
              />}
          </div>}

        {textLinkState.showLinkInput === true &&
          <KPLinkInput
            id='inline_link_input'
            textInputProps={{
              id: 'inline_link_input_external',
              value: textLinkState.value,
              /*onChange : (key, val) => updateTextLinkState(key, val),*/
              placeholder: 'write/paste your link here'
            }}
            onConfirm={(key, val, type, displayText) => {
              linkInputOnConfirmHandler === 'handleInsertCTA'
              ? handleInsertCTA('ButtonSecondary', val, type, displayText, { editorState, setEditorState, onEditorChange }, setTextLinkState, setLinkInputOnConfirmHandler)
              : insertLink(key, val, type, { editorState, setEditorState, onEditorChange }, setTextLinkState, setCursorToEnd, cursorToEnd)
            }}
            linkType={
              linkInputOnConfirmHandler === 'handleInsertCTA'
              ? 'button'
              : 'link' }
            //onConfirm for cta will fire 
            onClose={handleCloseLinkInput}
          />}

          

        {showCardLinkInput === true &&
          <KPResourceSelectPopUp
            onConfirm={(val) => embedCardLinks(val)}
            onCloseModal={handleCloseCardLinkInput}
            contentTypeEmbedOptions={props.contentTypeEmbedOptions}
            profileTypeEmbedOptions={props.profileTypeEmbedOptions}
          />}

      </div>

    </div>
  );
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const RichInlineLink = (props) => {
  const { url, type } = props.contentState.getEntity(props.entityKey).getData();

  const [showLinkPreview, setShowLinkPreview] = useState(false); //can be used later on to show some kind of preview component

  const showPreview = (url) => setShowLinkPreview(true)
  const hidePreview = (url) => setShowLinkPreview(false)

  return (
    <Fragment>
      <a
        className='bodySerif--L'
        onMouseEnter={() => showPreview(url)}
        onMouseLeave={() => hidePreview(url)}
        // onClick={() => window.open(injectHttps(url), "_blank")}
        href={injectHttps(url)}
        target="_blank"
        style={{ textDecoration: 'underline', color: greyColor100, position: 'relative' }}>
        {props.children}
        { /*showLinkPreview &&
        <div style={{position: 'absolute', top: '-100px', height: '100px', backgroundColor: 'white'}}>
          Show some preview here.
        </div> */}
      </a>

    </Fragment>
  );
};

KPRichInput.defaultProps = {
  id: 'generic_rich_input', //this is Very Very important to have. If multiple editors in a page. IDs should be different.
  //label is optional
  //sublabel is optional
  richFormattable: true,
  readOnly: false,
  trackEditorFocus: true
}

export default React.memo(KPRichInput);
