import React, { useState, Fragment } from "react";

//generalUI
import { KPIconBtn, KPFileUploadBtn } from '../../generalUI/KPButtons';

//icons
import LinkIcon from '../../icons/LinkIcon';
import ItalicBtn from '../../icons/ItalicBtn';
import UnderlineBtn from '../../icons/UnderlineBtn';
import BoldBtn from '../../icons/BoldBtn';

import { greyColor10, white } from '../../../sass/vars.scss';

import {
  applyInlineStyle,
} from '../../../utils/richInputUtils';

import {
  promptForLink
} from '../../../utils/richInlineLinkUtils';

const KPRichInlineToolbar = props => { //col settings must be the bootstrap codes

    const { editorId, editorState, editorRef, onEditorChange } = props.editor;

    return(
      <div className="kp-rich-inline-toolbar" onClick={props.onClick}>
        <div className="kp-rich-inline-toolbar__tools-group" style={{display: 'flex'}}>
          <KPIconBtn
            className="kp-rich-format-toolbar__tool"
            onClick={() => applyInlineStyle(
              'BOLD',
              editorState,
              onEditorChange,
              props.setInlineToolbar
            )}>
            <BoldBtn />
          </KPIconBtn>
          <KPIconBtn
            className="kp-rich-format-toolbar__tool"
            onClick={() => applyInlineStyle(
              'ITALIC',
              editorState,
              onEditorChange,
              props.setInlineToolbar
            )}>
            <ItalicBtn />
          </KPIconBtn>
          <KPIconBtn
            className="kp-rich-format-toolbar__tool"
            onClick={() => applyInlineStyle(
              'UNDERLINE',
              editorState,
              onEditorChange,
              props.setInlineToolbar
            )}>
            <UnderlineBtn />
          </KPIconBtn>
        { props.formattingOptions !== 'textFormatting' &&
          <KPIconBtn
            className="kp-rich-format-toolbar__tool"
            onClick={(e) => promptForLink(e, editorState, props.setTextLinkState )}>
            <LinkIcon fill={white} />
          </KPIconBtn> }
        </div>
      </div>
    )
  }


export default KPRichInlineToolbar;
