import {
  GET_RECOMS,
  GET_RECOMS_FROM_URL_PARAMS,
  SET_RECOMS_LOADING,
  RECOMS_ERROR,
  RESET_RECOMS
} from '../actions/types';

const initialState = {
  generatedFrom : null, //enum : userAnswers | urlParams --> this helps the recoPage component decide whether to fire the api which fetches the reco objects from the url param
  data : [],
  answerFilters : null,
  loading : false,
  errors : []
};

export default function(state = initialState, action) {
  const {type, payload} = action

  switch (type) {
    case GET_RECOMS:
    case GET_RECOMS_FROM_URL_PARAMS:  
    return {
      ...state,
      generatedFrom : payload.generatedFrom,
      data : payload.data,
      answerFilters : payload.answerFilters,
      loading : false
    }
    case SET_RECOMS_LOADING:
    return {
      ...state,
      loading : payload
    }
    case RECOMS_ERROR:
    return {
      ...state,
      errors : payload,
      loading : false
    }
    case RESET_RECOMS:
    return {
      ...state,
      data : [],
      answerFilters : null,
      loading : false
    }
    default:
    return state;
  }
}
