import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function EditSM(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props}>
      <path
        d="M13.394 24.542L24.3 13.291a3.142 3.142 0 114.478 4.408L17.563 28.915h-4.169v-4.373z"
        stroke={props.stroke || primaryColor100}
        strokeLinecap="round"
      />
      <path stroke={props.stroke || primaryColor100} d="M23.144 15.124l4.478 4.479" />
    </svg>
  )
}

export default EditSM;
