import React, { useRef } from "react";
import PropTypes from 'prop-types';
import Download__nofill from '../../icons/Download__nofill';
import Download from '../../icons/Download';

import UploadSM from '../../icons/UploadSM';
import AddXS from '../../icons/AddXS';
import EditSM from '../../icons/EditSM';
import Facebook_solid_SM from '../../icons/Facebook_solid_SM';
import Linkedin_solid_SM from '../../icons/Linkedin_solid_SM';
import Twitter_solid_SM from '../../icons/Twitter_solid_SM';
import Instagram_solid_SM from '../../icons/Instagram_solid_SM';
import Youtube_solid_SM from '../../icons/Youtube_solid_SM';
import Mail_solid_SM from '../../icons/Mail_solid_SM';
import Phone_solid_SM from '../../icons/Phone_solid_SM';
import Website_solid_SM from '../../icons/Website_solid_SM';
import KebabMenu from '../../icons/KebabMenu';
import UploadImage from '../../icons/UploadImage';
import OkeGoogle from '../../icons/New/OkeGoogle';
import Close_S from '../../icons/Close_S';
import Setting_SM from '../../icons/SettingsSM'
import SassVars, { primaryColor100, greyColor100, white, primaryColor40 } from '../../../sass/vars.scss'
import BodySans__M from "../../typo/BodySans__M";
import KPLabel from "../KPLabel";
import { injectHttps } from '../../../utils/general';
import loadable from '@loadable/component'
import {
  PencilSimple,
  PencilLine,
  Plus,
  Lightbulb,
  UploadSimple,
  CaretLeft,
  CaretRight,
  XCircle,
  DownloadSimple,
  ArrowLineUpRight,
  User,
  MapPinLine,
  WarningCircle,
  Paperclip,
  SpeakerHigh,
  ArrowLeft,
  CalendarBlank,
  Trash,
  DotsThreeVertical,
  CaretUp,
  CaretDown,
  ShareNetwork,
  X,
  WhatsappLogo,
  FacebookLogo,
  TwitterLogo,
  LinkedinLogo,
  YoutubeLogo,
  InstagramLogo,
  Globe,
  Phone,
  Envelope,
  Checks,
  ArrowsIn,
  ArrowsOut,
  ArrowClockwise,
  ArrowsClockwise,
  DotsThree
} from 'phosphor-react'



export const DisplayIcon = (props) => {

  const icons = {
    Setting_SM,
    Download__nofill,
    UploadSM,
    AddXS,
    EditSM,
    Facebook_solid_SM,
    Twitter_solid_SM,
    Linkedin_solid_SM,
    Instagram_solid_SM,
    Youtube_solid_SM,
    Phone_solid_SM,
    Mail_solid_SM,
    Website_solid_SM,
    KebabMenu,
    UploadImage,
    Download,
    PencilLine,
    Plus,
    PencilSimple,
    Lightbulb,
    UploadSimple,
    CaretLeft,
    CaretRight,
    OkeGoogle,
    Close_S,
    XCircle,
    DownloadSimple,
    ArrowLineUpRight,
    User,
    MapPinLine,
    WarningCircle,
    Paperclip,
    SpeakerHigh,
    ArrowLeft,
    CalendarBlank,
    Trash,
    DotsThreeVertical,
    CaretUp,
    CaretDown,
    ShareNetwork,
    X,
    WhatsappLogo,
    FacebookLogo,
    TwitterLogo,
    LinkedinLogo,
    YoutubeLogo,
    InstagramLogo,
    Globe,
    Phone,
    Envelope,
    Checks,
    ArrowsIn,
    ArrowsOut,
    ArrowClockwise,
    ArrowsClockwise,
    DotsThree
  }
  
  const IconComp = icons[props.icon];

  return (
    <IconComp
      strokeWidth="0"
      size={24}
      color={props.color}
      style={{ marginRight: !props.onlyIcon && "1rem" }}
      className="button__icon"
    />
  )
}

export const Button = (props) => {

  let invert = props.invert ? `button--invert` : "";
  let onlyIcon = (props.icon && (!props.value && !props.children));

  let displayText = (props.link && props.link.displayText) || props.children || props.value;

  const handleClick = () => {
    if (props.link) {
      window.open(props.link.val);
    } else {
      props.onClick && props.onClick(props.disabled);
    }
  }

  return (
    <button
      id={props.id}
      className={`${props.className} ${props.disabled ? 'disabled' : ''} button button--${props.type} ${invert} ${onlyIcon ? '-only-icon-' : ''}`}
      onClick={handleClick}
      style={{
        width: onlyIcon && "4rem",
        height: onlyIcon && "4rem",
        padding: onlyIcon && "0px",
        ...props.style
      }}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.icon &&
        <DisplayIcon icon={props.icon} onlyIcon={onlyIcon} />}
      {displayText}
    </button>
  )
}

export const ButtonPrimary = (props) => {
  return (
    <Button type="primary" {...props} />
  )
}

export const ButtonSecondary = (props) => {
  return (
    <Button type="secondary" {...props} />
  )
}

export const ButtonTertiary = (props) => {
  return (
    <Button type="tertiary" {...props} />
  )
}

export const ButtonGhost = (props) => {
  return (
    <Button type="ghost" {...props} />
  )
}




export const KPBtn = props => {

  const genIcon = (iconProp) => {
    let icon = null;
    if (typeof iconProp === 'string') {
      icon = <span>-</span>
    } else {
      icon = iconProp;
    }
    return icon;
  }


  return (
    // <div key={props.key} className={`kp-btn-wrapper ${props.className && props.className.split(' ').map(d => d+'-wrapper').join(' ')}`} style={props.align && {justifyContent: props.align}}>
    <button
      id={props.id}
      className={`kp-btn ${props.type} ${props.size} ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
    >
      { genIcon(props.icon) && !props.iconAfterText && <div className='kp-btn__icon-wrapper' style={{ paddingRight: '1rem' }}>{genIcon(props.icon)}</div>}
      {props.children || props.value}
      { genIcon(props.icon) && props.iconAfterText && <div className='kp-btn__icon-wrapper' style={{ paddingLeft: '1rem' }}>{genIcon(props.icon)}</div>}
    </button>
    // </div>
  )
}





KPBtn.defaultProps = {
  type: 'secondary',
  size: 'small',
  iconStroke: SassVars.white,
  iconFill: SassVars.white
}

export const KPIconBtn = props => {

  let btnRef = useRef(null);

  const genIcon = (iconProp) => {

    let icon = null;
    if (typeof iconProp === 'string') {
      
      icon = <span>-</span>
    } else {
      icon = iconProp;
    }
    return icon;
  }

  return (

    <button
      ref={btnRef}
      id={props.id}
      className={`kp-icon-btn ${props.type} ${props.shape} ${props.className} ${props.size} ${props.isActive ? "kp-icon-btn-active" : ''}`}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={props.style}
      disabled={props.disabled}
    >
      { genIcon(props.icon)
        ? <div className='kp-icon-btn__child-wrapper'>{genIcon(props.icon)}</div>
        : <div className='kp-icon-btn__child-wrapper'>{props.children}</div>}
    </button>

  );
}




KPIconBtn.defaultProps = {
  type: 'secondary',
  size: 'large'
}

export const KPFormSubmit = props => (
  <div className={`${props.className}-wrapper`}>
    <input
      type="submit"
      name={props.name}
      value={props.value}
      className={`h4 bold kp-btn primary large ${props.className}`}
    />
  </div>
);

//unique IDs are V. V important to prevent unexpected bugs
export const KPFileUploadBtn = props => {


  const Btns = { KPIconBtn, KPBtn, ButtonSecondary, ButtonPrimary, ButtonTertiary, ButtonGhost }
  
  const BtnComp = Btns[props.btnProps.comp];
  const openUploadWindow = () => document.getElementById(`${props.id}`).click()

  return (
    <div className='kp-file-upload-btn-container' onClick={openUploadWindow}>
      <input type='file' id={props.id} onChange={props.onChange} multiple={props.multiple} style={{ display: "none" }} />
      <BtnComp
        {...props.btnProps.props}
        className={`kp-file-upload-btn ${props.className}`}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.children}
      </BtnComp>
      {props.label && props.label}
    </div>
  )
}

KPFileUploadBtn.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  className: PropTypes.string,
  btnProps: PropTypes.shape({
    comp: PropTypes.string.isRequired,
    props: PropTypes.object
  })
}

KPFileUploadBtn.defaultProps = {
  btnProps: { comp: 'KPIconBtn' }
}

