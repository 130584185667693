import * as React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

function Website_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M11.672 18.17a16.002 16.002 0 001.771-4.77h4.235a8 8 0 01-6.006 4.77zM9.48 17.488a.94.94 0 001.654 0 15.201 15.201 0 001.492-4.089H7.989a15.2 15.2 0 001.491 4.089zM8.945 18.17a8 8 0 01-6.007-4.77h4.236a16 16 0 001.77 4.77zM13.58 7.976c.224 1.533.224 3.09 0 4.623h4.386a8.002 8.002 0 000-4.623H13.58zM12.771 12.599a15.202 15.202 0 000-4.623H7.844a15.2 15.2 0 000 4.623h4.927zM7.035 12.599a16 16 0 010-4.623H2.65a8 8 0 000 4.623h4.386zM11.672 2.403a16.002 16.002 0 011.771 4.772h4.235a8 8 0 00-6.006-4.772zM9.48 3.086A15.2 15.2 0 007.99 7.174h4.637a15.202 15.202 0 00-1.492-4.088.94.94 0 00-1.654 0zM2.938 7.175h4.236a16 16 0 011.77-4.772 8 8 0 00-6.006 4.772z"
        fill={props.fill || primaryColor100}
      />
    </svg>
  )
}

export default Website_solid_SM
