import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
//icons
import Phone24 from '../../icons/Phone24';
import Mail24 from '../../icons/Mail24';
// Hamburger Icons
import DistrictDashboardHamburgerIcon from "../../icons/DistrictDashboardIcon"
import ToolkitsHamburgerIcon from "../../icons/ToolkitsIcon"
import CommunityHamburgerIcon from "../../icons/PType_UserProfiles"
import StoriesHamburgerIcon from "../../icons/CType_Stories"
import Facebook_solid_SM from "../../icons/Facebook_solid_SM"
import Twitter_solid_SM from "../../icons/Twitter_solid_SM"
import Linkedin_solid_SM from "../../icons/Linkedin_solid_SM"
import Instagram_solid_SM from "../../icons/Instagram_solid_SM"
import Youtube_solid_SM from "../../icons/Youtube_solid_SM"
import Mail_solid_SM from '../../icons/Mail_solid_SM';
import Phone_solid_SM from '../../icons/Phone_solid_SM';
import Website_solid_SM from '../../icons/Website_solid_SM';
import EditSM from '../../icons/EditSM';
import AddXS from '../../icons/AddXS';
import Delete from '../../icons/Delete';


const KPLink = ({

  type,
  linkShell,
  className,
  textStyle,
  style,
  text,
  icon,
  iconStroke,
  iconFill,
  linkType, // internal | external | action //NOTE: even if it is an internal link, but if you want it to open in a different tab, just set linkType as external
  link,
  onClick
}) => {

  const [sanitizedExternalLink, setSanitizedExternalLink] = useState(null);
  useEffect(() => {
    linkType === 'external' && link &&

      setSanitizedExternalLink(
        (link.includes('https://') || link.includes('http://'))
          ? link
          : link.includes('mailto:') || link.includes('tel:')
            ? link
            : 'https://' + link
      )
  }, [])

  const Icons = {
    Phone_solid_SM,
    Mail_solid_SM,
    Website_solid_SM,
    DistrictDashboardHamburgerIcon,
    ToolkitsHamburgerIcon,
    CommunityHamburgerIcon,
    StoriesHamburgerIcon,
    Facebook_solid_SM,
    Twitter_solid_SM,
    Linkedin_solid_SM,
    Youtube_solid_SM,
    Instagram_solid_SM,
    EditSM,
    Delete,
    AddXS
  }

  let Icon = icon &&
    typeof icon === 'string'
    ? Icons[icon]
    : icon

  const linkComp = (
    <div className='kp-link-content'>
      { Icon &&
        <div className='kp-link-content__icon'>
         {<Icon 
            fill={iconFill && iconFill} 
            stroke={iconStroke && iconStroke} />}
            </div>
          }
      <h4 className={`kp-link-content__text ${textStyle}`}>{text}</h4>
    </div>
  );


  return (
    <Fragment>
      { linkShell === 'inline'
        ? <span className={`kp-link-wrapper ${type} ${className}`} style={style}>
          {linkType === 'internal'
            ? <Link to={link} className='kp-link'> {linkComp} </Link>
            : linkType === 'external'
              ? <a href={sanitizedExternalLink} target="_blank" className='kp-link'> {linkComp} </a>
              : <div onClick={onClick} target="_blank" className='kp-link link'> {linkComp} </div>}
        </span>
        : <div className={`kp-link-wrapper ${type} ${className}`} style={style}>
          {linkType === 'internal'
            ? <Link to={link} className='kp-link link'> {linkComp} </Link>
            : linkType === 'external'
              ? <a href={sanitizedExternalLink} target="_blank" className='kp-link link'> {linkComp} </a>
              : <div onClick={onClick} target="_blank" className='kp-link link'> {linkComp} </div>}
        </div>}
    </Fragment>

  )
}

KPLink.defaultProps = {
  type: 'secondary',
  textStyle: 'h4 bold',
  linkShell: 'block'
}

export default KPLink;
