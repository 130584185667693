import React, {Fragment} from "react";
import { makeAryFromLen } from '../../../utils/general';
import PageContainer from '../PageContainer';
import PageRow from '../PageRow';

const gridStyle = {
    backgroundColor: 'grey', 
    height: '800vh', 
    position: 'fixed', 
    top: 0, 
    left: '50%',
    width: '100%',
    zIndex: 10000000,
    opacity: 0.3,
    transform: "translateX(-50%)"
  }

const Grid = (props) => (
    <PageContainer style={gridStyle}>
        <PageRow style={{height: '100%'}}>
            { makeAryFromLen(20).map((d,i) => (
                <div className='oke-col oke-col-xl-1 oke-col-lg-1 oke-col-md-1' style={{backgroundColor: 'lightgrey'}}>
                <div style={{backgroundColor: '#f73232', height: '800vh'}}></div>
                </div>
            ))}
        </PageRow>
    </PageContainer>
)

export default Grid;
