
  import {toArray} from './general';
  
  export const checkAccessToContentType = (user, contentType, roles, userAction) => {
    return roles[user.role].knowledge.contentTypes[contentType].includes(userAction)
  }

  export const checkAccessToFeature = (user, roles, feature) => {

    return (
      user ? 
      (
        !roles.featuresAccess[user.role]  //if the config of this role has not been defined only, then allow access
        ? true 
        : roles.featuresAccess[user.role][feature] !== 'hidden'
      )
      : true
    ) 
    
  }
  
  export const _GetPublishOrSubmitAction = (user, contentType, roles) => {
  
    switch(true){
  
      case checkAccessToContentType(user, contentType, roles, 'submit' ) === true:
      return {
        action: 'submit',
        display: { onDraft: 'Submit', onEditPublished: 'Re-Submit' }
      }
  
      case checkAccessToContentType(user, contentType, roles, 'publish' ) === true:
      return {
        action: 'publish',
        display: { onDraft: 'Publish', onEditPublished: 'Publish Changes' }
      }
  
      default:
      return 'Error in get publish / submit action'
  
    }
  }


  export const __ApiAccessCheck = (contentType, isAuth, _ApiAccess, accessType) => {
    switch(accessType){
      case 'GET_publisheds':
      case 'GET_contribution_view':
        let accessConfig = _ApiAccess[accessType];
        if(!accessConfig) return true;
        //else
        let access = true;
        toArray(accessConfig).map(c => {
          let conf = c.middleware || c;
          if(
            conf === 'auth' && 
            !isAuth &&
            (!c.exceptionParams || !c.exceptionParams.includes(contentType))
          ){
            access = false
          }
        })
        return access;
    }
  }
  