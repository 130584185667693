import axios from 'axios';
import store from '../store';
import {
  getShortenedName,
  getVal,
  
} from './getters';

import { createContentCardDate } from './momentManipulate';
import { formatTitle, set_getValOffQueryString as s_gVal } from './general';
import {
  __GetText, 
  __GetValsFromValPaths, 
  __GetCardImagePropData, 
  __GetCardVideoPropData, 
  __GetContentTypeDisplay,
  __CustomGetter,
  __GetCardEventDatePropData,
  __GetResourceLink,
  __GetImageUrl
} from './gettersV2';


export const __CardPropsGenerator = d => {
  let { _ContentCardsConfig } = store.getState().environment.envConfig.cards
  let contentType = d.meta.kp_content_type;  
  let {
    title, 
    image, 
    video, 
    featuredCardLabel, 
    listElemLabel,
    metaPrimary, 
    metaHero,
    metaBlockData, 
    shareSummary, 
    resourceLink,
    
    //newsletter related
    nwLabel,
    nwImage,
    nwSummary,
    nwMetaBlockData,
    
    //share related
    shareImage
  } = _ContentCardsConfig[contentType];

  return ({
    contentType,
    title : title 
      ? title.fn 
        ? __CustomGetter(d, title) 
        : formatTitle(__GetText(__GetValsFromValPaths(d, title))) //required
      : undefined,
    image : image 
      ? image.fn
        ? __CustomGetter(d, image) 
        : __GetCardImagePropData(d, image)
      : undefined,
    videoEmbed : video 
      ? video.fn
        ? __CustomGetter(d, video) 
        : __GetCardVideoPropData(d, video)
      : undefined,
    featuredCardLabel : featuredCardLabel
      ? featuredCardLabel.fn
        ? __CustomGetter(d, featuredCardLabel)
        : { value: __GetContentTypeDisplay(d) }
      : undefined,
    listElemLabel : listElemLabel
    ? {
        key : listElemLabel.key && listElemLabel.key.fn ? __CustomGetter(d, listElemLabel.key) : undefined,
        value : listElemLabel.value && listElemLabel.value.fn ? __CustomGetter(d, listElemLabel.value) : __GetContentTypeDisplay(d),
      }
    : undefined,
    metaPrimary : metaPrimary
      ? __CustomGetter(d, metaPrimary)
        ? { value : __CustomGetter(d, metaPrimary) }
        : { value: __GetContentTypeDisplay(d) }
      : undefined,
    metaHero : metaHero
      ? metaHero.fn
        ? __CustomGetter(d, metaHero) 
        : __GetCardEventDatePropData(d, metaHero) 
      : undefined,
    shareSummary : shareSummary 
      ? shareSummary.fn
        ? __CustomGetter(d, shareSummary) 
        : __GetText(__GetValsFromValPaths(d, shareSummary))
      : undefined, //we add this undefined inorder to not pass this prop if this prop isnt defined at all in the cardConfig
    metaBlockData : !metaBlockData
      ? undefined
      : __GenMetaBlockDataProp(metaBlockData, d),
    resourceLink : resourceLink
      ? resourceLink.fn
        ? __CustomGetter(d, resourceLink)
        : __GetResourceLink(d, resourceLink)
      : undefined,
    
    //newsletter related
    nwImage : nwImage 
      ? nwImage.fn
        ? __CustomGetter(d, nwImage)
        : __GetImageUrl(d, nwImage)
      : undefined,
    nwLabel : nwLabel
    ? {
        key : nwLabel.key && nwLabel.key.fn ? __CustomGetter(d, nwLabel.key) : undefined,
        value : nwLabel.value && nwLabel.value.fn ? __CustomGetter(d, nwLabel.value) : __GetContentTypeDisplay(d),
      }
    : undefined,
    nwSummary : nwSummary 
      ? nwSummary.fn 
        ? __CustomGetter(d, nwSummary) 
        : __GetText(__GetValsFromValPaths(d, nwSummary)) //required
      : undefined,
    nwMetaBlockData : !nwMetaBlockData
    ? undefined
    : __GenMetaBlockDataProp(nwMetaBlockData, d),
    
    //share realated
    shareImage : shareImage
      ? shareImage.fn
        ? __CustomGetter(d, shareImage)
        : __GetImageUrl(d, shareImage)
      : undefined
  })
}

function __GenMetaBlockDataProp( metaBlockData, d){
  return({
      comp : 'MetaBlock',
      data : [
        ...(
          metaBlockData[0] 
          ? [{ 
              text: metaBlockData[0].fn
              ? __CustomGetter(d, metaBlockData[0]) 
              : getShortenedName(s_gVal('get', d, metaBlockData[0])) 
            }]
          : []
        ),
        ...(
          metaBlockData[1]
          ? [{ 
              text: metaBlockData[1].fn 
              ? __CustomGetter(d, metaBlockData[1]) 
              : createContentCardDate(getVal(d, metaBlockData[1])) 
            }]
          : []
        )
        
      ]
  })
}

export const refreshCardsData = async (cardsData, setCardsData, setLoading) => {
  if(cardsData && cardsData.type === 'internal' && cardsData.selectedAry.length > 0){
    const promises = cardsData.selectedAry.map( async d => {
      try{
        // console.log('d', d)
        let contentType = d.meta.kp_content_type;
        let profileType = d.meta.profile_type;
        if(profileType){
          let id = ['userProfiles', 'board'].indexOf(profileType) !== -1
                    ? (d.user && d.user._id)
                    : d._id
          let res;
          if(['userProfiles', 'organisations'].indexOf(profileType) !== -1 ){
            res = await axios.get(`/api/${profileType}/${id}`);
          }else{
            res = await axios.get(`/api/groups/${profileType}/${id}`);
          }
          return res.data.profile;
        }else{
          const res = await axios.get(`/api/contributions/view/${contentType}?id=${d._id}`);
          return res.data.contribution;  
        }
        
        
      }catch(err){
        if(err) console.log('err in cardUtils > refreshCardsData', err);
      }
    })
    const results = await Promise.all(promises);
    
    setCardsData({ ...cardsData, selectedAry: results});
    setLoading(false);
  }else{
    setLoading(false);
  }
}
