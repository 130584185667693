import {
  GET_MOD_QUEUE,
  CLEAR_MOD_QUEUE,
  MOD_ERROR,
  ACCEPT_SUB,
  GET_SUB_FOR_EDIT,
  SET_MOD_LOADING
} from "../actions/types";

let initialState = {
  awaitingMod : [],
  underModeration : [],
  error : {},
  loading : true
};


export default function(state = initialState, action) {
  const { type, payload, stateObj } = action;

  switch (type) {

    case GET_MOD_QUEUE:
    return {
      ...state,
      awaitingMod : payload.awaitingMod,
      underModeration : payload.underModeration,
      loading : false
    };

    case CLEAR_MOD_QUEUE:
    return {
      ...state,
      awaitingMod: [],
      underModeration: []
    };

    case MOD_ERROR:
    return {
      ...state,
      error: payload,
      loading: false
    };

    case SET_MOD_LOADING:
    return {
      ...state,
      loading: payload
    };

    default:
    return state;
  }
}
