import {
  SET_INIT_CONTENT_TYPE_LISTING_STATE,

  GET_PUBLISHED_LISTING,
  SKIP_GET_LISTING,
  GET_PUBLISHED_LISTING_SHORT,

  GET_USER_PROFILE_LISTING,
  GET_USER_PROFILE_LISTING_SHORT,

  GET_USERS_LISTING,

  GET_MIXED_LISTING,

  GET_TAGS_LISTING,
  UPDATE_TAG_COUNT,
  UPDATE_TAG_COUNT_ERROR,
  ADD_TAG,
  ADD_TAG_ERROR,
  SET_ADD_TAG_LOADING,

  UPDATE_LISTING_DATA,
  RESET_LISTING_TO_INIT_DATA,

  FETCH_LISTING_FROM_STORE,

  CLEAR_USER_PROFILE_LISTING,
  CLEAR_PUBLISHED_LISTING,
  SET_LISTING_LOADING,
  GET_LISTING_ERROR,

  SET_KNOWLEDGE_API_QUEUE_LOADING,

  GET_PARTICIPANTS_LISTING,
  PARTICIPANTS_LOADING,

  SET_SUB_RESOURCE_LISTING,
  REFETCH_SUB_RESOURCE,

  SET_GROUPED_PUBLISHED_LISTING_LOADING,
  GET_GROUPED_PUBLISHED_LISTING,
  GET_GROUPED_PUBLISHED_LISTING_ERROR,
  RESET_GROUPED_PUBLISHED_LISTING,
  UPDATE_GROUPED_LISTING_DATA,
  RESET_GROUPED_LISTING_TO_INIT_DATA,
  FETCH_GROUPED_LISTING_FROM_STORE,

  SET_ACTIVE_SORT,
  SET_GROUPED_LISTING_ACTIVE_SORT

} from "../actions/types";

import { _ProfileTypes } from '../_configs/profileTypes/config';


let initialState = {};

//community listings
_ProfileTypes.map(d => {
  initialState = {
    ...initialState,
    [d.reduxListingKey]: {
      profileType: null,
      initData: [], data: [],
      initFilters: [], filters: [], activeFilters: {},
      searchString: '',
      [d.id+'Loading']: true
    }
  }
})

/**
 * initialState for the content-type listings is set in action>getClientConfig ( since that is where we first access the _ContentTypes config )
 */

initialState = {
  ...initialState,
  groupedPublishedListing : {
    // insert listing and filter data here
    loading: true,
    error: []
  },
  usersListing: {
    data: [], initData: [],
    initFilters: [], filters: [], activeFilters: {},
    usersLoading: false
  },

  participantsListing : {
    data: [], initData: [],
    initFilters: [], filters: [], activeFilters: {},
    participantsLoading: false
  },
  subResourceListing : {
    loading : false,
    data : []
  },
  publishedListingShort: {
    contentType: null,
    data: []
  },
  userProfilesListingShort: {
    profileType: null,
    data: []
  },
  organisationsListingShort: {
    profileType: null,
    data: []
  },
  workingGroupsListingShort: {
    profileType: null,
    data: []
  },
  stateNetworksListingShort: {
    profileType: null,
    data: []
  },
  mixedListing: {
    type: 'mixed',
    data: [],
    loading: true
  },
  placesApiListing: {
    type: 'externalApi',
    data: []
  },
  tagsListing: {
    data: []
  },
  expertiseAreasListing: {
    data: []
  },
  govtDepartmentsListing: {
    data: []
  },
  interestAreasListing: {
    data: []
  },
  problemAreasListing: {
    data: []
  },
  loading: true,
  knowledgeApiQueueLoading : true,
  error: {},
  
}


export default function(state = initialState, action) {
  const { type, payload, stateObj, listingName } = action;

  switch (type) {

    case SET_INIT_CONTENT_TYPE_LISTING_STATE:
      return {
        ...state,
        ...payload
      }

    case REFETCH_SUB_RESOURCE:
    return {
      ...state,
      subResourceListing : {
        data: [],
        loading : payload
      }
    }

    case SET_SUB_RESOURCE_LISTING:
    return {
      ...state,
      subResourceListing : {
        data : payload,
        loading: false
      }
    }
    
    case PARTICIPANTS_LOADING: 
    return {
      ...state,
      participantsListing: {
        ...state.participantsListing,
        participantsLoading: true
      },
      loading: true
    };

    case GET_PARTICIPANTS_LISTING: 
    return {
      ...state,
      participantsListing: {
        ...state.participantsListing,
        data: payload.data,
        initData: payload.data,
        participantsLoading: false
      },
      loading: false
    }

    case SET_GROUPED_PUBLISHED_LISTING_LOADING:
    return {
      ...state,
      groupedPublishedListing : {
        ...state.groupedPublishedListing,
        loading: payload.loading
      } 
    }

    case GET_GROUPED_PUBLISHED_LISTING:
    return {
      ...state,
      groupedPublishedListing : {
        ...state.groupedPublishedListing,
        loading: false,
        [stateObj] : {
          initData: payload.data,
          data: payload.data,
          initFilters: payload.initFilters,
          filters: payload.filters
        }
      }
    }

    case UPDATE_GROUPED_LISTING_DATA:
    return {
      ...state,
      groupedPublishedListing : {
        ...state.groupedPublishedListing,
        [stateObj]: {
          ...state.groupedPublishedListing[stateObj],
          activeFilters: payload.activeFilters ? payload.activeFilters : state.groupedPublishedListing[stateObj].activeFilters,
          data: payload.data ? payload.data : state.groupedPublishedListing[stateObj].data,
          searchString: payload.searchString ? payload.searchString : state.groupedPublishedListing[stateObj].searchString,
          activeSort: payload.activeSort ? payload.activeSort : state.groupedPublishedListing[stateObj].activeSort
        }
      }
    }


    case UPDATE_LISTING_DATA:
    return {
      ...state,
      [stateObj]: {
        ...state[stateObj],
        activeFilters: payload.activeFilters ? payload.activeFilters : state[stateObj].activeFilters,
        data: payload.data ? payload.data : state[stateObj].data,
        searchString: payload.searchString ? payload.searchString : state[stateObj].searchString,
        activeSort: payload.activeSort ? payload.activeSort : state[stateObj].activeSort
      }
    }

    case SET_ACTIVE_SORT:
    return {
      ...state,
      [stateObj]: {
        ...state[stateObj],
        activeSort: payload
      }
    }

    case SET_GROUPED_LISTING_ACTIVE_SORT:
    return {
      ...state,
      groupedPublishedListing : {
        ...state.groupedPublishedListing,
        [stateObj]: {
          ...state.groupedPublishedListing[stateObj],
          activeSort: payload
        }
      } 
    }

    case RESET_GROUPED_LISTING_TO_INIT_DATA:
    return {
      ...state,
      groupedPublishedListing : {
        ...state.groupedPublishedListing,
        [stateObj]: {
          ...state.groupedPublishedListing[stateObj],
          activeFilters: payload.activeFilters,
          data: payload.data,
          filters: payload.filters
        }
      }
      
    }

    case GET_GROUPED_PUBLISHED_LISTING_ERROR:
    return {
      ...state,
      groupedPublishedListing : {
        ...state.groupedPublishedListing,
        error: payload.error,
        loading: false,
      }
    }

    case RESET_GROUPED_PUBLISHED_LISTING:
    return {
      ...state,
      groupedPublishedListing: {
        loading: true,
        error: []
      }
    }

    case FETCH_GROUPED_LISTING_FROM_STORE:
    return {
      ...state
    }

    case GET_PUBLISHED_LISTING:
    return {
      ...state,
      [stateObj]: {
        ...state[stateObj],
        contentType: payload.contentType,
        initData: payload.data,
        data: payload.data,
        initFilters: payload.initFilters,
        filters: payload.filters,
        [payload.contentType + 'Loading']: false
      },
      loading: false
    };

    case GET_PUBLISHED_LISTING_SHORT:
    return {
      ...state,
      publishedListingShort: {
        contentType: payload.contentType,
        data: payload.data
      },
      loading: false
    };

    case GET_USER_PROFILE_LISTING:
    return {
      ...state,
      [stateObj]: {
        ...state[stateObj],
        profileType: payload.profileType,
        initData: payload.data,
        data: payload.data,
        [payload.profileType + 'Loading']: false
      },
      loading: false
    };

    case GET_USERS_LISTING:
    return {
      ...state,
      usersListing: {
        ...state.usersListing,
        initData: payload.data,
        data: payload.data,
        usersLoading: false
      },
      loading: false
    }

    case GET_USER_PROFILE_LISTING_SHORT:
    return {
      ...state,
      [stateObj]: {
        ...state[stateObj],
        profileType: payload.profileType,
        data: payload.data
      },
      loading: false
    };

    case GET_MIXED_LISTING:
    return {
      ...state,
      mixedListing: {
        ...state.mixedListing,
        data: payload,
        loading: false
      },
      loading: false
    }

    case GET_TAGS_LISTING:
    return {
      ...state,
      [listingName]: {
        data: payload
      },
      loading: false
    }

    case ADD_TAG:
    return {
      ...state,
      [listingName]: {
        ...state[listingName],
        data: [ ...state[listingName].data, payload ]
      }
    }

    

    case UPDATE_TAG_COUNT:
    return {
      ...state,
      [listingName]: {
        ...state[listingName],
        data: [ ...state[listingName].data.filter(d => d._id !== payload._id), payload ]
      }
    }

    case FETCH_LISTING_FROM_STORE:
    return {
      ...state
    };

    case SKIP_GET_LISTING:
    return {
      ...state
    };


    case RESET_LISTING_TO_INIT_DATA:
    return {
      ...state,
      [stateObj]: {
        ...state[stateObj],
        activeFilters: payload.activeFilters,
        data: payload.data,
        filters: payload.filters
      }
    }

    case CLEAR_PUBLISHED_LISTING:
    return {
      ...state,
      [stateObj]: {
        contentType: null,
        initData: [],
        data: [],
        initFilters: [],
        filters: []
      },
    };

    case CLEAR_USER_PROFILE_LISTING:
    return {
      ...state,
      userProfilesListing: {
        data: []
      }
    };

    case UPDATE_TAG_COUNT_ERROR:
    case ADD_TAG_ERROR:
    case GET_LISTING_ERROR:
    return {
      ...state,
      error: payload,
      loading: false
    };

    case SET_ADD_TAG_LOADING:
    case SET_LISTING_LOADING:
    return {
      ...state,
      loading: true
    };

    case SET_KNOWLEDGE_API_QUEUE_LOADING:
    return {
      ...state,
      knowledgeApiQueueLoading: payload
    }



    default:
    return state;
  }
}
