import * as React from "react"

function Alert__Neutral(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <circle cx={15} cy={15} r={15} fill="#FC0" />
      <path
        d="M16.38 17.737h-2.145l-.448-8.587h3.041l-.448 8.587zm-2.628 3.006c0-.275.038-.507.114-.694.082-.194.194-.349.334-.466.14-.117.305-.202.492-.255.188-.053.39-.079.607-.079.205 0 .398.026.58.08.187.052.351.137.492.254.14.117.252.273.334.466.082.187.123.419.123.694 0 .264-.04.49-.123.677a1.25 1.25 0 01-.826.73 1.878 1.878 0 01-.58.087c-.217 0-.42-.029-.607-.088a1.25 1.25 0 01-.826-.73 1.796 1.796 0 01-.114-.676z"
        fill="#fff"
      />
    </svg>
  )
}

export default Alert__Neutral;
