export const datePresets = [
  {
    value: 'all',
    display: 'Since Beginning'
  },
  {
    value: 'thisWeekPreset',
    operator: 'this',
    timeUnit: 'week',
    display: 'This Week'
  },
  {
    value: 'thisMonthPreset',
    operator: 'this',
    timeUnit: 'month',
    display: 'This Month'
  },
  {
    value: 'prevMonthPreset',
    operator: 'prev',
    prevCount: 1,
    timeUnit: 'month',
    display: 'Last Month'
  },
  {
    value: 'prev3MonthPreset',
    operator: 'prev',
    prevCount: 3,
    timeUnit: 'month',
    display: 'Last 3 Month'
  },
  {
    value: 'prev6MonthPreset',
    operator: 'prev',
    prevCount: 6,
    timeUnit: 'month',
    display: 'Last 6 Month'
  }
]
