import axios from 'axios';
import {
    ENV_CONFIG_LOADING,
    SET_ENV_CONFIG_ERROR,
    SET_ENV_CONFIG,

    SET_INIT_CONTENT_TYPE_LISTING_STATE,
    SET_INIT_RESYNTH_DATA_STATE
}
from './types';

export const getClientConfig = () => async dispatch => {

    try {
      dispatch({ type: ENV_CONFIG_LOADING, payload: true})    // loading
      let res = await axios.get("/api/config");
      
      let {_ContentTypes} = res.data;
      //set initial structure for content-type listings data in store
      let initContentTypeListingState = {};
      _ContentTypes.map(d => {
        initContentTypeListingState[d.reduxListingKey] = {
          contentType: null,
          initData: [], data: [],
          initFilters: [], filters: [], activeFilters: {},
          searchString: '',
          activeSort: undefined,
          [d.id+'Loading']: true
        }
      })

      //set init synthesis data state. it should be an either or ideally between content-type listing setting and this.
      let initReSynthDataState = {};
      _ContentTypes.filter(c => c.id !== 'staticPages').map(d => {
        initReSynthDataState[d.id] = {
          loading: true
        };
      })

      
      dispatch({ type: SET_INIT_RESYNTH_DATA_STATE, payload: initReSynthDataState });
      dispatch({ type: SET_INIT_CONTENT_TYPE_LISTING_STATE, payload: initContentTypeListingState });  // send clientInstance in later Single CodeBase implementation.
      dispatch({ type: SET_ENV_CONFIG, payload: res.data });  // send clientInstance in later Single CodeBase implementation.
      return res.data;
    } catch (err) {
    //   dispatch(setAlert('Oops! Something went wrong :(. Refresh the page and try again.', 'danger')) // temp error message. // change this later if needed.
      if(err.response){
        dispatch({
          type: SET_ENV_CONFIG_ERROR,
          payload: err.response.data
        })
      }else{
        console.log('Error Fetching Configurations',err);
      }
      return 'setConfigError'
    }
  }