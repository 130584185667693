import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function KebabMenu(props) {
  
  return (
    <svg width={11} height={24} viewBox="0 0 11 24" >
      <path
        d="M5.581 7.16a1.505 1.505 0 100-3.01 1.505 1.505 0 000 3.01zM5.581 13.525a1.505 1.505 0 100-3.01 1.505 1.505 0 000 3.01zM5.581 19.89a1.505 1.505 0 100-3.01 1.505 1.505 0 000 3.01z"
        fill={ props.fill || primaryColor100}
      />
    </svg>
  )
}

export default KebabMenu
