import axios from "axios";
import { setAlert } from "./alert";
import store from '../store';
import {getProfilesListing} from './listings'
import history from "../history"; //history object that allows for routing from outside components.

import {
  GET_PROFILE,
  GET_PROFILE_TPL,
  GET_PROFILE_REL_CONTENT,

  CLEAR_PROFILE,

  UPDATE_CLEAR_TO_PROCEED_USER_PROFILE,
  UPDATE_PROFILE,
  UPDATE_USER,

  RESET_USER_PROFILE_TPL,
  SAVE_CHANGES_TO_PROFILE,

  UPDATE_MODE,

  PROFILE_ERROR,
  PROFILE_LOADING,
  CONTRS_BY_PROFILE_LOADING,

  PROFILE_SAVING,

  UPDATE_DRAFT_CONTRIBUTIONS_BY_PROFILE,
  DELETE_DRAFT_ERROR,

  DELETE_PROFILE,
  DELETE_PROFILE_ERROR

 } from "./types";

import { formValidationCheck, set_getValOffQueryString } from '../utils/general'

export const updateMode = mode => dispatch => dispatch({ type: UPDATE_MODE, payload: mode })

export const getProfileTpl = profileType => async dispatch => {
  
  try{
    dispatch({ type: PROFILE_LOADING, payload: true })
    const res = await axios.get(`/api/tpl/${profileType}`);
    const payload = {};
    payload.tpl = res.data[0];
    payload.mode = 'edit' //cuz at this point i think this action will only get fired when an admin is trying to create an org/group page
    dispatch({ type: GET_PROFILE_TPL, payload: payload });

  }catch(err){
    dispatch({
      type: PROFILE_ERROR,
      payload: err
    });
  }
}
//Get profile by id
export const getProfile = (id, mode, Profile, profileType) => async dispatch => {

  try {
    dispatch({ type: PROFILE_LOADING, payload: true })
    dispatch({ type: CONTRS_BY_PROFILE_LOADING, payload: true })
      
      let res = null;
      if(['userProfiles', 'organisations'].indexOf(profileType) !== -1 ){
        res = await axios.get(`/api/${profileType}/${id}`);
      }else{
        res = await axios.get(`/api/groups/${profileType}/${id}`);
      }
      
      if(mode === 'edit' && res.data.currentUserInfo.isEditAuth.isEditAuth === false){
        history.push(`/profile/${profileType}/${id}`)
      }

      const payload = {};
      payload.tpl = res.data.tpl;
      payload.Profile = res.data.profile;
      // payload.contributionsByProfile = res.data.contributionsByProfile;
      payload.isEditAuth = res.data.currentUserInfo.isEditAuth;
      payload.mode = mode;

      //@NOTE
      //no need for form validation on load of edit page, because we dont want to activate the 'save changes'
      //button unless changes are actually made

      //@REL-CODE
      // if( formValidationCheck( payload.tpl.kp_templates.blocks.user_profile_english, payload.Profile, 0).emptyFieldFound === false ){
      //   payload.clearToProceed = true;
      // }else{
      //   payload.clearToProceed = false;
      // }

      dispatch({ type: GET_PROFILE, payload: payload });

      let relContentRes = null;
      if(['userProfiles', 'organisations'].indexOf(profileType) !== -1 ){
        relContentRes = await axios.get(`/api/${profileType}/relContent/${id}`);
      }else{
        relContentRes = await axios.get(`/api/groups/${profileType}/relContent/${id}`);
      }

      dispatch({ type: GET_PROFILE_REL_CONTENT, payload: relContentRes.data });


  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: err.response
    });
  }
};

export const deleteProfile = ( profileType, id ) => async dispatch => {
  try{
    
    dispatch({  type: PROFILE_LOADING, payload: true });
    const isGroupType = ['userProfiles', 'organisations'].indexOf(profileType) === -1;
    const deleteRes = await axios.delete(
      `api/${isGroupType ? 'groups/' : ''}${profileType}/${id}`
    );
    
    dispatch(setAlert(deleteRes.data.msg, "success"))
    dispatch(getProfilesListing(profileType, "long", ''))
    history.push('/')
    dispatch({
      type: DELETE_PROFILE
    })
  }catch(err){
    dispatch({
      type: DELETE_PROFILE_ERROR,
      payload: err
    })
  }
}

export const deleteDraft = ( contentType, id, draftsByProfile ) => async dispatch => {
  try{
    const deleteRes = await axios.delete(`/api/contributions/${contentType}?id=${id}`);
    draftsByProfile && draftsByProfile.map((d,i) => {
      if(id === d._id){
        draftsByProfile.splice(i, 1); //remove that particular draft entry from the drafts array
      }
    })
    dispatch({
      type: UPDATE_DRAFT_CONTRIBUTIONS_BY_PROFILE,
      payload: {draft: draftsByProfile}
    })
    dispatch(setAlert(`contribution successfully deleted!`, "success"));

  }catch(err){
    dispatch({
      type: DELETE_DRAFT_ERROR,
      payload: err
    })
  }
}

export const updateProfile = (
  key,
  val,
  Profile,
  valuePath,
  tplBlockArray,
  totBlocksArray
) => async dispatch => {

  try {
    
    set_getValOffQueryString('set', Profile, valuePath, val);
    //continuous validation check -- run this only if the proceedToNext redux var (which we are yet to pass in here) isn't already set to true).
    let formValidationRes = formValidationCheck(totBlocksArray, Profile, 0);
    if(formValidationRes.emptyFieldFound === false){
      
      dispatch({ type: UPDATE_CLEAR_TO_PROCEED_USER_PROFILE, payload: { clearToProceed: true, emptyFields: [] } })
    }else{
      dispatch({ type: UPDATE_CLEAR_TO_PROCEED_USER_PROFILE, payload: { clearToProceed: false, emptyFields: formValidationRes.emptyFields } })
    }
    dispatch({type: UPDATE_PROFILE, payload: Profile })
  }catch(err){
    if(err) console.log('err from updateProfile', err);
  }
}

//#3 Save Changes to profile
export const saveChanges = (userId, Profile, clearToProceed, profileType, isEditAuth, inNestedPublishing = false, callback = null) => async dispatch => {
  try{

    if(clearToProceed === true ){
      dispatch({type: PROFILE_SAVING, payload: true})
      const config = { headers: { "Content-Type": "application/json" }};
      //LATER: fire the POST user update API only if any changes have been made to the user data.
      let userRes = null;
      let profileRes = null;
      if(profileType === 'userProfiles') userRes = await axios.post(`/api/users/update/details/${userId}`, Profile.user, config);
      if(['userProfiles', 'organisations'].indexOf(profileType) !== -1 ){
        profileRes = await axios.post(`/api/${profileType}`, Profile, config);
      }else{
        profileRes = await axios.post(`/api/groups/${profileType}`, Profile, config);
      }

      Profile = profileRes.data;

      dispatch({type: SAVE_CHANGES_TO_PROFILE, payload: Profile });
      if(profileType === 'userProfiles' && isEditAuth.msg?.name !== 'isSuperAdmin'){
        dispatch({type: UPDATE_USER, payload: Profile.user });
      }

      let profileId = profileType === 'userProfiles' ? Profile.user._id : Profile._id;
      
      if(!inNestedPublishing) {
        history.push(`/profile/${profileType}/${profileId}?activeTab=about`);
        // history.push(`/community-listing/${profileType}`)
      }else{
        callback(null, profileRes.data)
      }

      dispatch(setAlert('changes saved successfully to your profile!', "success"))
    }else{
      let emptyFields = store.getState().profiles.validationRel.emptyFields;
      let strAry = emptyFields.map(emptyFieldData => `'${emptyFieldData.block.props.label}'` || `'${emptyFieldData.block.props.id}'`);
      let emptyFieldsString = strAry.length === 1 ? strAry[0] : strAry.slice(0,-1).join(", ") + "&" + strAry[strAry.length - 1];
      let alertText = `you need to fill out ${emptyFieldsString} in this profile before proceeding.`
      dispatch(setAlert(alertText, 'danger', 4000));
      throw(`you need to fill out ${emptyFieldsString} in this profile before proceeding.`);
    }
  }catch(err){
    //PENDING : change the published flag back to "draft" in the redux store. IF the error happens while doing the post.
    if(err) console.log('err from saveChanges', err);
    if(callback) callback(err, null);
  }
}

export const clearProfile = () => dispatch => dispatch({ type: CLEAR_PROFILE })
