import React, { Fragment, useEffect, useState, useRef, useLayoutEffect } from "react";
import _ from 'lodash'
import store from '../store';
import { getPublishedListing } from '../actions/listings';
import gridVars from '../sass/gridVars.scss';
import { convertPxStringToInt, getQueryParam } from './general';
import { useLocation } from "react-router";

export const useTrackDdFocus = (ref, setShowOptions) => {

  const handleClickOutside = (event) => {
    
    if (ref.current && !ref.current.contains(event.target)) { //clicked outside editor
      setShowOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); //its very important for this to be mousedown. 'click' acts unpredictable 
    return () => document.removeEventListener("mousedown", handleClickOutside);
  },[]);

}

export const useFetchPblListingsInQueue = (listings, setApiQueueLoading) => {

  const {_ContentTypes} = store.getState().environment.envConfig;

  useEffect(() => {

    setApiQueueLoading(true);

    let apiCallQueue = [];
    _ContentTypes.filter(d => d.id !== 'staticPages' ).map(d => {
      apiCallQueue.push({ apiFunc: () => getPublishedListing(d.id, 'long', listings)});
    })

    const fetchApisInQueue = async (idx) => {
    		await store.dispatch(apiCallQueue[idx].apiFunc());
    		if(idx+1 !== apiCallQueue.length){
    			fetchApisInQueue(idx+1);
    		}else{
          setApiQueueLoading(false);
        }
    }

    fetchApisInQueue(0);

  },[])
}

export const useComponentWillMount = (func) => {
    const willMount = useRef(true)

    if (willMount.current) func()

    willMount.current = false;
}


export const useParseContentBlockValue = (valFromParent, sanitizeVal) => {
  const [val, setVal] = useState(sanitizeVal(valFromParent)); //sanitize val has to be declared by each component. here you have the logic that deals with settings a 'empty val data structure', should the valFromParent be falsy
  useEffect(() => {
    setVal(sanitizeVal(valFromParent))
  },[valFromParent])

  return [val, setVal];
}

export const useScroll = (scrollFn) => {
  useLayoutEffect(() => {
    scrollFn(); //run the scroll fn once before any scroll to set initial states

    let throttledScrollFn = _.throttle(scrollFn, 100);
    window.addEventListener('scroll', throttledScrollFn);
    return () => window.removeEventListener('scroll', throttledScrollFn);
  },[])
}



export const useHorizontalScroll = (colSetting, screenWidth, data) => {

  const [disableAction, setDisableAction] = useState({left: true, right: false});
  const [showScrollActions, setShowScrollActions] = useState(false);
  const [activateSwipable, setActivateSwipable] = useState(false);
  const [totVisibleCards, setTotVisibleCards] = useState(null);
  const [totCardsGroups, setTotCardsGroups] = useState(1);
  const [visibleCardsGroupIndex, setVisibleCardsGroupIndex] = useState(0);
  const [scrolledLeftPercent, setScrolledLeftPercent] = useState(0);
  const [compsHiddenLeft, setCompsHiddenLeft] = useState(0);

  useEffect(() => {
    if(!data) return;
    //else

    //reset hor scroll on screen resize
    setScrolledLeftPercent(0);
    setCompsHiddenLeft(0)
    setVisibleCardsGroupIndex(0);
    setDisableAction({left: true, right: false});

    let colSettingAry = colSetting.split(' ');
    colSettingAry = colSettingAry.filter(colSetting => colSetting !== 'oke-col');
    let noOfCardsPerBp = {};
    
    colSettingAry.map(colSetting => {
      let bp = colSetting.split('-')[2];
      let bpWidth =  convertPxStringToInt(gridVars[`${bp}_bp`]);
      let noOfCards = parseInt(gridVars[`${bp}_cols`]) / parseInt(colSetting.split('-')[3]);
      noOfCardsPerBp[bp] = { bpWidth, noOfCards};
    })

    let currBp = null;
    let valToBeat = 0;
    Object.values(noOfCardsPerBp).map((d,i) => {
      if(screenWidth > d.bpWidth && d.bpWidth > valToBeat){
        currBp = Object.keys(noOfCardsPerBp)[i];
        valToBeat = d.bpWidth;
      }

    })

    let newTotVisibleCards = currBp === null //temp while we dont have a 0-600 Breakpoint
                              ? 1
                              : noOfCardsPerBp[currBp].noOfCards

    setShowScrollActions(newTotVisibleCards < data.length ? true : false); 

    let newTotCardsGroups = Math.ceil(data.length / newTotVisibleCards);
    setTotCardsGroups(newTotCardsGroups);

    setTotVisibleCards( newTotVisibleCards );

    //figure whether to activate swipable
    const mdBpWidth = convertPxStringToInt(gridVars.md_bp);
    setActivateSwipable(screenWidth < mdBpWidth ? true : false)

  },[screenWidth, data])

  const handleScrollRight = () => {
    let totCards = data.length;
    let totRightHiddenCards = totCards - totVisibleCards - compsHiddenLeft;
    if(totRightHiddenCards === 0) return;

    let scrollDistance = totRightHiddenCards >= totVisibleCards 
                          ? -100 
                          : -((100/totVisibleCards)*totRightHiddenCards);

    setScrolledLeftPercent(scrolledLeftPercent+scrollDistance);
    setCompsHiddenLeft(
       totRightHiddenCards >= totVisibleCards 
       ? compsHiddenLeft + totVisibleCards
       : compsHiddenLeft + totRightHiddenCards
    )

    //used to set the pagination indicator
    setVisibleCardsGroupIndex(visibleCardsGroupIndex+1);

    //disable the right action button, if the last set of cards was just scrolled into view.
    let isLastHiddenSetOfCards = totRightHiddenCards <= totVisibleCards;
    setDisableAction(
      isLastHiddenSetOfCards 
      ? {left: false, right: true}
      : {left: false, right: false}
      //right scroll just happened. so left has to stay active in either case.
    ) 
  }

  const handleScrollLeft = () => {
    if(compsHiddenLeft === 0) return;

    if(scrolledLeftPercent < -100){
      setScrolledLeftPercent(scrolledLeftPercent + 100);
      setCompsHiddenLeft(compsHiddenLeft - totVisibleCards);
    }else{
      setScrolledLeftPercent(0);
      setCompsHiddenLeft(0);
     
    }

    setDisableAction({...disableAction, left: scrolledLeftPercent < -100 ? false : true}) 
    setDisableAction(
      scrolledLeftPercent < -100 
      ? {left: false, right: false}
      : {left: true, right: false}
      //left scroll just happened. so right has to stay active in either case.
    )

    //used to set the pagination indicator
    setVisibleCardsGroupIndex(visibleCardsGroupIndex-1);
  }

  return {
    handleScrollLeft,
    handleScrollRight,
    scrolledLeftPercent,
    showScrollActions,
    activateSwipable,
    disableAction,
    visibleCardsGroupIndex,
    totCardsGroups
  }

}

export const useSetIndexedBlocksData = (tplLang) => {
  const indexedBlocks = [];
  const indexedBlockRefs = [];
  const [indexedBlocksData, setIndexedBlocksData] = useState(null);
  const pTplLangRef = useRef(tplLang);

  const langChanged = () => {
    if(!tplLang) return false;
    //else
    return tplLang.value !== pTplLangRef.current.value;
  }

  useLayoutEffect(() => {
    
    if ((!indexedBlocksData || langChanged()) && indexedBlocks.length > 0) {
      let toSet = {
        indexedBlocks,
        indexedBlockRefs
      }
      
      setIndexedBlocksData(toSet)
      if(langChanged()) pTplLangRef.current = tplLang;
    }
  })

  return { indexedBlocks, indexedBlockRefs, indexedBlocksData };
}

export const useActiveTabFromQueryParam = (
  loading, 
  tabBarOptions, //tabBarOptions,
  options = {}
) => {
  let location = useLocation();

  let [activeTab, setActiveTab] = useState(options.tabBarOptionsFn ? {display: '', value: ''} : tabBarOptions[0]);

  // console.log({location, loading});

  useEffect(() => {

    if(location.search && !loading){
      let tabOps = options.tabBarOptionsFn ? options.tabBarOptionsFn() : tabBarOptions;
      let newActiveTabValue = getQueryParam(location.search, 'activeTab');
      let newActiveTab = tabOps.filter(op => op.value === newActiveTabValue)[0];
      if(newActiveTab) setActiveTab(newActiveTab);
    }
  },[location.search, loading])

  return activeTab;
}
