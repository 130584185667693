import {
  getRichInputText,
  getVal,
  getImgData,
  getResourceLink,
  getOrgAndDesig,
  getGroupAdmin,
  getNoOfMembers,
  getDyResName,
  getDyRes
} from '../../../utils/getters'


//overrides on og card config

export const USER_PROFILES = (d) => ({ //the admindb card config for users has be be configured keeping in mind that d = a user model . not a userProfile model
  profileImage : { avatar : d.avatar },
  title : d.name,
  role : d.role,
  // org : 'org',
  // wg: 'wg',
  // sn: 'sn'
})

export const ORGANISATIONS = (d) => ({

})

export const WORKING_GROUPS = (d) => ({
  title : d.name,
  cellData : [
    // getGroupAdmin(d.convener, 'name'),
    getNoOfMembers(d.members),
    // getDyResName(d.anchor_orgs, 'name', 'string', 1)
  ],
  groupLeadData: getDyRes(d.convener), //null will give the whole user obj
  membersData: getDyRes(d.members, {external: true})
})

export const STATE_NETWORKS = (d) => ({
  title : d.name,
  cellData : [
    getGroupAdmin(d.convener, 'name'),
    getNoOfMembers(d.members),
    getDyResName(d.anchor_orgs, 'name', 'string', 1)
  ],
  groupLeadData: getDyRes(d.convener), //null will give the whole user obj
  membersData: getDyRes(d.members, {external: true})
})

export const PARTNER_NETWORKS = (d) => ({
  title : d.name,
  cellData : [
    getGroupAdmin(d.convener, 'name'),
    getNoOfMembers(d.members),
    getDyResName(d.anchor_orgs, 'name', 'string', 1)
  ],
  groupLeadData: getDyRes(d.convener), //null will give the whole user obj
  membersData: getDyRes(d.members, {external: true})
})
