import { sortDataV2 } from './general';

export const setInitActiveSorts = (headerConfig) => {
            
  let activeSorts = {};
  headerConfig.cellData.map(d => {
      if(d.sortConfig && d.defaultSort === true){ 
          activeSorts[d.colId] = 'a' 
      } else
      if (d.sortConfig) activeSorts[d.colId] = 'none';
  })
  return activeSorts;
}

//V2 can take an extra arg of data, which will be sorted to its initial sort state, should a default sort be provided
export const setInitActiveSortsV2 = (headerConfig, data = undefined) => {
    let activeSorts = {};
    headerConfig.cellData.map(d => {
        if(d.sortConfig && d.defaultSort === true){ 
            
            let sortBy = d.defaultSortBy || 'a';
            console.log({sortBy, d})
            activeSorts[d.colId] = sortBy 
            data && sortDataV2(data, d.sortConfig, sortBy)
        } else
        if (d.sortConfig) activeSorts[d.colId] = 'none';
    })
return activeSorts;
}

export const updateActiveSorts = (activeSorts, d) => {
  let prev = { ...activeSorts };
  
  let newActiveSorts = {};
  Object.keys(prev).map(prevK => {
      if(prevK === d.colId){
          newActiveSorts[prevK] = (
              prev[prevK] === 'none'
              ? 'a' 
              : prev[prevK] === 'a' 
                  ? 'b' 
                  : 'a'
          )
      } else {
          newActiveSorts[prevK] = 'none';
      }
  })

  return newActiveSorts;
}

export const __handleTableSort = (
    data,
    thisColConfig,
    activeSorts,
    callback = undefined
  ) => {
    let newActiveSorts = updateActiveSorts(activeSorts, thisColConfig);
    let sortBy = newActiveSorts[thisColConfig.colId];
    sortDataV2(data, thisColConfig.sortConfig, sortBy);
    data = JSON.parse(JSON.stringify(data)); //this parse + stringify is so that we create a new 'obj' which rerenders the component

    callback && callback(data, newActiveSorts);
}