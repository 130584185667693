import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Facebook_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M6.27 10.816h1.644v6.769c0 .133.109.242.242.242h2.788a.242.242 0 00.242-.242v-6.737h1.89a.242.242 0 00.24-.214l.288-2.492a.242.242 0 00-.24-.27h-2.178V6.31c0-.471.254-.71.754-.71h1.423a.242.242 0 00.242-.242V3.071a.242.242 0 00-.242-.242h-1.961a1.92 1.92 0 00-.09-.002c-.34 0-1.524.067-2.458.927-1.036.953-.892 2.093-.858 2.291v1.827H6.27a.242.242 0 00-.242.242v2.46c0 .134.109.242.242.242z"
        fill={props.fill || primaryColor100}
      />
    </svg>
  )
}

export default Facebook_solid_SM
