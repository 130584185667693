import React, {Fragment, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import axios from 'axios';
import { KPTextInput } from '../KPInputs';
import KPLabel from '../../generalUI/KPLabel';
import Loader from '../../generalUI/Loader';
import { KPFileUploadBtn, ButtonSecondary} from '../../generalUI/KPButtons';
import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData} from '../../../utils/richInputUtils';
import {imageUploader} from '../../../utils/general';
import {getLabelProps} from '../../../utils/contentBlock';

const AudioEmbed = (props) => {

  const {
    id,
    className,
    label,
    sublabel,
    isRequired,
    errorMsgs,
    
    value,
    onChange: parentOnChange,
    multiple,
  
    isInEditor,
    editor,
    entityKey,
    block,
    handleSetTempReadOnly,
  
    placeholder,
    readOnly,

    folderName,
    allowedFormats,
    
    uploadBucketName,
    passChangeHandlerOps
    
  } = props;

  const audioData = value || [];

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleUploadProgess = (loadedPercent) => {
    setUploadProgress(loadedPercent);
  }

  const handleErrorFeedback = (msg) => {
    setErrorMsg(msg);
    //reset other states
    setUploading(false);
    setUploadProgress(null);
  }

  // const [audioData, setAudioData] = useState(initVal);

  const callback = (status, resData) => {
    // setAudioData(resData)
    if(status === 'success'){
      parentOnChange && parentOnChange(id, resData, passChangeHandlerOps && props);
      if(isInEditor === true){
        replaceEntityData(editor, entityKey, {value: resData })
      }
    }
    setUploading(false);
    setUploadProgress(null);
  }

  const handleChange = (e) => {
    const files = Array.from(e.target.files)
    setUploading(true);
    setErrorMsg(null);

    const formData = new FormData()
    // console.log({files})
    
    files.forEach((file, i) => { formData.append(i, file) })
    formData.set("folderName", folderName);
    formData.set("allowedFormats", allowedFormats);
    if(uploadBucketName){
      formData.set("uploadBucketName", uploadBucketName);
    }
    
    imageUploader(
      formData, 
      { 
        type: 'files',
        jsonFileData : files,
        folderName,
        allowedFormats,
        handleUploadProgess, 
        handleErrorFeedback 
      }, 
      callback
    );
  }

  const genAudioPlayer = () => (
    <Fragment>
    { audioData.map((d,i) => (
        <audio controls src={d.mediaLink} >check</audio>
      )) }
    </Fragment>
  )

  const genInputBlock = () => (
    <Fragment>
      { audioData.length === 0
        ? <KPFileUploadBtn 
            onChange={handleChange}
            multiple={multiple} 
            btnProps={{
              comp: 'ButtonSecondary',
              props: {
                value: uploading && uploadProgress === 100
                  ? 'Almost Done...'
                  : uploading 
                    ? `Uploading... ${uploadProgress ? '( ' + uploadProgress + ' / 100 )' : ''}` 
                    : errorMsg
                      ? errorMsg
                      : 'Upload Audio' 
              }
            }}
            id={`${id}_audioUploadBtn`}
            />
        : <div style={{display: 'flex', alignItems: 'center'}}> 
            { genAudioPlayer() } 
            <KPFileUploadBtn 
              onChange={handleChange}
              multiple={multiple} 
              btnProps={{
                comp: 'ButtonSecondary',
                props: {
                  value: uploading && uploadProgress === 100
                  ? 'Almost Done...'
                  : uploading 
                    ? `Uploading... ${uploadProgress ? '( ' + uploadProgress + ' / 100 )' : ''}` 
                    : errorMsg
                      ? errorMsg
                      : 'Replace Audio' 
                }
              }}
              id={`${id}_audioUploadBtn`}
              />
          </div> }
      
    </Fragment>
  )

  const genDisplayBlock = () => (
    <div>{ genAudioPlayer() }</div>
  )

  const genBlock = () => (
    <Fragment>
    { readOnly ? genDisplayBlock() : genInputBlock()}
    </Fragment>
  )
  
  return (
    <ContentBlockShell
      richInputProps = {{
        isInEditor,
        editor,
        block,
        handleSetTempReadOnly
      }}
      readOnly = { readOnly }
      className={`AudioEmbed ${className}`}
      id={id}
    >
      <KPLabel {...getLabelProps(props)} />
      {genBlock()}
    </ContentBlockShell>
  )
};

AudioEmbed.defaultProps = {
  multiple : false,
  folderName : 'audio',
  allowedFormats : ['mp3', 'wav', 'm4a', 'aac', 'amr']
}


export default React.memo(AudioEmbed);