import {
  getImgData,
  getLocation,
  getRichInputText,
  getAvatar,
  getName,
  getVal,
  getResourceLink,
  getSpeaker,
  getUrl,
  getOrgAndDesig,
  getPlacesAPILocation,
  getGroupAdmin
} from '../../../utils/getters';

import { createContentCardDate } from '../../../utils/momentManipulate';
import { formatTitle } from '../../../utils/general';



export const USER_PROFILES = d => ({
  colSetting : 'col-sm-3',
  profileImage : {
    avatar : d.user && d.user.avatar
  },
  label : {
    value : getVal(d, ["organisation.name.internal.0.name", "organisation.name.external.0.string"])
  },
  title : formatTitle(d.user && d.user.name),
  summary : getRichInputText(d.bio),
  bottomKPTags : getVal(d.areas_of_expertise)
})

export const ORGANISATIONS = d => ({
  colSetting : 'col-sm-3',
  image : {
    data : d.logo,
    imgBlockComp : "KPBgImageBlock",
    backgroundSize: "contain",
		height: "12rem",
    width: "12rem",
    // containerShape : 'shape-soft-square',
    placeholderInitial : d.name && d.name.charAt(0).toUpperCase(),
  },
  title : formatTitle(d.name),
  label : getPlacesAPILocation(d.head_office_location) && {
    icon: "Location14",
    key: getPlacesAPILocation(d.head_office_location)
  },
  summary : getRichInputText(d.description),
  bottomKPTags : getVal(d.areas_of_expertise)
  //multiprofiles display pending
})

export const WORKING_GROUPS = d => ({
  colSetting : 'col-sm-3',
  image : {
    data : d.kp_cover_image,
    imgBlockComp : "KPBgImageBlock",
		height: "12rem",
    width: "100%"
  },
  title : formatTitle(d.name),
  // metaPrimary : {key: d.formed_in.year.length > 0 && 'formed in ' + (d.formed_in.month.length > 0 ? `${d.formed_in.month[0]} ` : '') + d.formed_in.year[0]},
  summary : getRichInputText(d.about),
  // metaBlockData : d.convener && d.convener.internal.length > 0 && {
  //   comp: 'MetaBlock',
  //   data: [{ label: 'convener:', img: d.convener.internal[0].user.avatar, text: d.convener.internal[0].user.name }]
  // },
  multiProfilesDisplayData: {
    noOfNamesToDisplay: 1,
    value: [ ...(d.convener && d.convener.internal[0] && d.convener.internal[0] ? [d.convener.internal[0]] : []), ...(d.members ? d.members.internal : [])],
    imgValPath: 'avatar.userUploaded',
    nameValPath: 'name',
    andXOthersText: ['and', 'other members'],
    linkPath: { id: '_id', resType: 'meta.profile_type'},
    textStyle: 'h6'
  }

})

export const STATE_NETWORKS = d => ({
  colSetting : 'col-sm-3',
  image : {
    data : d.kp_cover_image,
    imgBlockComp : "KPBgImageBlock",
		height: "12rem",
    width: "100%"
  },
  title : formatTitle(d.name),
  // metaPrimary : {key: d.formed_in.year.length > 0 && 'formed in ' + (d.formed_in.month.length > 0 ? `${d.formed_in.month[0]} ` : '') + d.formed_in.year[0]},
  summary : getRichInputText(d.about),
  // metaBlockData : d.convener.internal.length > 0 && {
  //   comp: 'MetaBlock',
  //   data: [{ label: 'convener:', img: d.convener.internal[0].user.avatar, text: d.convener.internal[0].user.name }]
  // },
  multiProfilesDisplayData: {
    noOfNamesToDisplay: 1,
    value: [ ...(d.convener && d.convener.internal[0] && d.convener.internal[0] ? [d.convener.internal[0]] : []), ...(d.members ? d.members.internal : [])],
    imgValPath: 'avatar.userUploaded',
    nameValPath: 'name',
    andXOthersText: ['and', 'other members'],
    linkPath: { id: '_id', resType: 'meta.profile_type'},
    textStyle: 'h6'
  }
})

export const PARTNER_NETWORKS = d => ({
  colSetting : 'col-sm-3',
  image : {
    data : d.kp_cover_image,
    imgBlockComp : "KPBgImageBlock",
		height: "12rem",
    width: "100%"
  },
  title : formatTitle(d.name),
  summary : getRichInputText(d.about),
  multiProfilesDisplayData: {
    noOfNamesToDisplay: 1,
    value: [ ...(d.convener && d.convener.internal[0] && d.convener.internal[0] ? [d.convener.internal[0]] : []), ...(d.members ? d.members.internal : [])],
    imgValPath: 'avatar.userUploaded',
    nameValPath: 'name',
    andXOthersText: ['and', 'other members'],
    linkPath: { id: '_id', resType: 'meta.profile_type'},
    textStyle: 'h6'
  }
})
