import React from "react"

function SlideIndicator(props) {
  return (
    <svg width={7} height={7} viewBox="0 0 7 7" fill="none" {...props}>
      <circle cx={3.5} cy={3.5} r={3.5} fill={props.fill || "#D8DADC"} />
    </svg>
  )
}

export default SlideIndicator
