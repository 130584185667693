import React, { useState, useRef, Fragment } from 'react';

import {useSelector} from 'react-redux';
import {DownloadSimple, TrashSimple, PencilSimple, ArrowLineUpRight} from 'phosphor-react';


import { KPIconBtn, KPFileUploadBtn, ButtonSecondary } from '../../generalUI/KPButtons';
import Download from '../../icons/Download';
import Delete from '../../icons/Delete';
import Edit from '../../icons/Edit';
import KPPDFDisplay from '../../inputs/KPPDFDisplay';

import ResourceDownloadForm from '../../organisms/ResourceDownloadForm';
import { injectHttps } from '../../../utils/general'

//utils
import { pickRandomNum } from '../../../utils/general';
//sass

import {white, primaryColor100, primaryColor10} from '../../../sass/vars.scss';


const KPResourcesBlock = (props) => {

  const [triggerForm, setTriggerForm] = useState(false);

  const [downloadLink, setDownloadLink]= useState('');

  const {_CaptureDownloaderDeets} = useSelector((state) => state.environment.envConfig.deployment);
  

  const handleClick = (link) => {
    if (_CaptureDownloaderDeets && props.trackDownload) {
      setDownloadLink(link);
      setTriggerForm(true);

    } else {
      window.open(injectHttps(link));
    }
  }

  const generateResourceThumbnail = (d) => {
    switch (true) {
      case ['pdf'].indexOf(d.format) !== -1:
        return <KPPDFDisplay src={d.publicUrl} />;
      case ['jpg', 'png', 'gif', 'webp'].indexOf(d.format) !== -1:
        return <img src={d.mediaLink} style={{ width: '100%' }} />
      default: //meaning its a 'raw' resource type
        return <h2 className='h2 u-case' style={{ alignSelf: 'flex-start' }}> .{d.format}</h2>
    }
  }

  const getResourceLinkInfo = (d) => {
    switch (true) {
      case ['pdf'].indexOf(d.format) !== -1:
        return { link: d.publicUrl || d.mediaLink, buttonComp: d.publicUrl ? 'ArrowLineUpRight' : 'DownloadSimple' }; //all conditionalities here are for backwards compat 
      case ['jpg', 'png', 'gif', 'webp'].indexOf(d.format) !== -1:
        return { link: d.publicUrl || d.mediaLink, buttonComp: d.publicUrl ? 'ArrowLineUpRight' : 'DownloadSimple' };
      default: //meaning its a 'raw' resource type
        return { link: d.mediaLink, buttonComp: 'DownloadSimple' };
    }
  }

  // const actionComps = {
  //   DownloadSimple,
  //   ArrowLineUpRight
  // }

  const generateResourceActions = d => {
    if (props.readOnly) {
      
      return (
        <a
          className='kp-resource__action kp-resource__action--download'
          href={getResourceLinkInfo(d).link}
          target={'_blank'}
        >
          <ButtonSecondary
            icon={getResourceLinkInfo(d).buttonComp}
            invert={props.invert}
          />
            {/* <ActionComp size={24} color={white} weight="light" /> */}
          {/* </ButtonSecondary> */}
        </a>)
    } else {
      return (
        <Fragment>
          <ButtonSecondary 
            // type="danger" 
            // className='kp-resource__action kp-resource__action--delete' 
            onClick={() => props.removeResource(d.id)}
            icon="Trash"
            style={{flex: '1 0 auto'}}
          >
            {/* <TrashSimple size={24} color={white} weight="light" /> */}
          </ButtonSecondary>
          <KPFileUploadBtn
            btnProps={{ comp: 'ButtonSecondary', props:{icon:"PencilSimple"} }}
            className="kp-resource__action kp-resource__action--edit"
            onChange={props.uploadOnChange}
            multiple={props.multiple}
            id={`${props.id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
          >
            {/* <PencilSimple size={24} color="#ffffff" weight="light" /> */}
          </KPFileUploadBtn>
        </Fragment>)
    }
  }



  const generateResource = d => {
    // let ActionComp = actionComps[getResourceLinkInfo(d).buttonComp]
    switch (true) {
      case !props.readOnly:
        return (
          <div className='kp-resource-wrapper' style={{ backgroundColor: primaryColor10 }}>
            { generateResourceThumbnail(d)}
            <div className='kp-resource__actions-wrapper'>
              {generateResourceActions(d)}
            </div>
          </div>)
      case props.readOnly && props.displayType === 'thumbnail':
        return (
          <div className='kp-resource-wrapper' style={{ backgroundColor: primaryColor10 }}>
            { generateResourceThumbnail(d)}
            <div className='kp-resource__actions-wrapper'>
              {generateResourceActions(d)}
            </div>
          </div>)

      default:
        return (
          // <a className='kp-resource__action kp-resource__action--download' href={d.mediaLink }>
          <ButtonSecondary
            onClick={() => handleClick(getResourceLinkInfo(d).link)}
            icon={getResourceLinkInfo(d).buttonComp}
            invert={props.invert}
            value={props.displayName || 'Download Resource'}
          >
            {/* <ActionComp size={24}  /> */}
            {/* {props.displayName || 'Download Resource'} */}
          </ButtonSecondary>
          // </a> 
        )
    }
  }

  return (
    <Fragment>
      { _CaptureDownloaderDeets && props.trackDownload &&
        <ResourceDownloadForm
          triggerForm={triggerForm}
          setTriggerForm={setTriggerForm}
          onFormSubmit={() => { setTriggerForm(false); setDownloadLink(''); }}
          link={downloadLink}
        />}
      <div className="kp-resources-block kp-row">
        {
          props.resources.map(d => (
            <div className='kp-col'>
              { generateResource(d)}
            </div>
          ))
        }
      </div>
    </Fragment>
  )
}

KPResourcesBlock.defaultProps = {
  displayType: 'thumbnail' //alt : btn
}

export default KPResourcesBlock;
