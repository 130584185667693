import * as React from "react"

function UnderlineBtn(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11.806 16.092c.364 0 .688-.06.973-.182.29-.121.532-.292.728-.51.2-.22.352-.486.455-.799.107-.313.16-.663.16-1.05V7.49h1.884v6.062c0 .602-.098 1.16-.294 1.673-.191.509-.47.95-.833 1.323a3.882 3.882 0 01-1.323.868c-.518.205-1.101.308-1.75.308s-1.232-.103-1.75-.308c-.518-.21-.96-.5-1.323-.868a3.912 3.912 0 01-.833-1.323 4.753 4.753 0 01-.287-1.673V7.49h1.883v6.055c0 .387.051.737.154 1.05.107.313.259.581.455.805.196.22.436.39.72.511.29.121.617.182.98.182zM6.71 19.011h10.192v1.12H6.71v-1.12z"
        fill="#fff"
      />
    </svg>
  )
}

export default UnderlineBtn
