import React, { useState, useEffect, Fragment, useRef } from "react";
import { useRouteMatch} from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
//comps
import { KPTextInput } from '../KPInputs';
import Loader from '../../generalUI/Loader';
import Meta from '../../typo/Meta';
//actions
import {
  getUsersListing, 
  getProfilesListing, 
  getPublishedListing, 
  getMixedListing, 
  getTagsListing, 
  getPlacesApiListing, 
  getParticipantsListing,
  getSubResourceListing
} from '../../../actions/listings';
import {getParticipants} from '../../../actions/participants';
//sass
import {  none, red } from '../../../sass/vars.scss';

import { 
  set_getValOffQueryString as s_gVal, 
  set_getValOffQueryStringV2 as s_gValV2,
  compareValPathToValue
} from '../../../utils/general';
import { genYearsArray, genMonthsArray } from '../../../utils/momentManipulate';
import { genLanguagesArray } from '../../../utils/lang';


const KPDynamicSearchResultsBlock = ({
  id,
  reduxState,
  staticListing,
  listings,
  participants : { short , loading},
  listingName,
  getListingActionName,
  getListingArgsArray,
  searchString,
  listingPropertyToMatch,
  getProfilesListing,
  getParticipantsListing, //phase out eventually
  getParticipants,
  getPublishedListing,
  getUsersListing,
  getMixedListing,
  getTagsListing,
  getSubResourceListing,
  getPlacesApiListing,
  resultCompRenderer,
  selected,
  onSelect,
  onSearchResultsChange,
  excludeThisResFromSearchList, // this can be confusing. let me explain with example. say you have a dynamic resource input in an org page called 'THIS ORG', that allows user to tag org pages. Now, if you dont the user to be able to tag "THIS ORG" within itself, then you set this prop to true
  filterListingConfig,
  noResultsText 
}) => { //col settings must be the bootstrap codes

  const { onlineStatus} = useSelector(state => state.environment)
  const match = useRouteMatch();
  const thisResId = match.params.id;

  let otherListings = {
    participants : { ...short,loading} //restructuring so that we can access loading
  }

  
  

  let resultsGroupRef = useRef();
  const [ resultsGroupXPosAnchor, setResultsGroupXPosAnchor] = useState('left')
  //list of all the actions we might need for this comp
  const ListingActions = {
    getProfilesListing : (...args) => getProfilesListing(...args),
    getParticipantsListing : (...args) => getParticipantsListing(...args), //phase out eventually
    getParticipants : (...args) => getParticipants(...args),
    getPublishedListing: (...args) => getPublishedListing(...args),
    getUsersListing : (...args) => getUsersListing(...args),
    getMixedListing : (...args) => getMixedListing(...args),
    getTagsListing : (...args) => getTagsListing(...args),
    getPlacesApiListing : (...args) => getPlacesApiListing(...args),
    getSubResourceListing : (...args) => getSubResourceListing(...args)
  }

  const StaticListingFns = {
    genYearsArray : (...args) => genYearsArray(...args),
    genMonthsArray : (...args) => genMonthsArray(...args),
    genLanguagesArray : (...args) => genLanguagesArray(...args)
  }

  const spreadListingArysArray = () => [...(getListingArgsArray ? getListingArgsArray : [])]

  //if static listing is passed, all listing data will be fetched from it, and not from action - API - reducer
  let listingToFetch = staticListing
                       ? { type: 'static', data: StaticListingFns[getListingActionName](...spreadListingArysArray()) }
                       : listings[listingName]
                          ? listings[listingName]
                          : otherListings[listingName]; //to accomodate for the participants listing which is not part of the 'listings' state obj

  

  useEffect(()=>{
    let resultsGroupXPos = resultsGroupRef.current.getBoundingClientRect().x;
    resultsGroupXPos > window.innerWidth/2
    ? setResultsGroupXPosAnchor('right')
    : setResultsGroupXPosAnchor('left')

    
    if(['static', 'externalApi'].indexOf(listingToFetch.type) === -1){
      let argsToPass = [...spreadListingArysArray()];
      if(listingName === 'mixedListing' || getListingActionName === 'getPublishedListing') argsToPass.push(listings); //very messy, gotta standardize
      if(listingName !== 'participants') argsToPass.push(listingToFetch.data);
      ListingActions[getListingActionName](...argsToPass);
      // getListingArgsArray
      // ? ListingActions[getListingActionName](...spreadListingArysArray(), listingToFetch.data)
      // : ListingActions[getListingActionName](listingToFetch.data);
    }
  },[])

  useEffect(() => {
    searchString &&
    onSearchResultsChange && onSearchResultsChange(handleDynamicSearch(listingToFetch.data, searchString)); //this long ass thing does a really stupid thing. it tell the 'plus' button to either be there or not be there/

  },[searchString.length, listingToFetch])

  useEffect(() => {
    if(listingToFetch.type === 'externalApi' && searchString.length > 0){
      getPlacesApiListing(searchString);
      // getListingArgsArray
      // ? ListingActions[getListingActionName](...spreadListingArysArray(), searchString)
      // : ListingActions[getListingActionName]( searchString)
    }
  },[searchString])

  const isStringMatch = ( string, subString ) => {
    return string.toLowerCase().indexOf(subString.toLowerCase()) !== -1; //returns true if substring occurs at the start of the string
  }

  const handleStringFetching = (d, listingPropertyToMatch) => {
    if(Array.isArray(listingPropertyToMatch)){ //this happens in the case of mixedListing when there can be multiple paths from which data is being searched for
      let val = null;
      for(var i = 0; i < listingPropertyToMatch.length; i++){
        val = s_gVal('get', d, listingPropertyToMatch[i]);
        if(val) break;
      }
      return val;
    }else{
      return s_gVal('get', d, listingPropertyToMatch);
    }
  }

  const handleDynamicSearch = (listing, searchString) => {
      let newResults = [];
      
      let filteredListing = [...listing];
      
      if(filterListingConfig && onlineStatus !== 'offline'){ //do not filter if app is offline. causes problems for the participants selection thing
        let newFilteredListing = [];
        filteredListing.map(d => {
          let validation = [];
          filterListingConfig.map(config => {
            if(config.value && config.value.getValueFromReduxStore){
              let isValid = compareValPathToValue(d, config.valuePath, s_gVal('get', reduxState, config.value.path));
              validation.push(isValid);
            } else 
            if (['number', 'string', 'boolean'].indexOf(typeof config.value) !== -1){
              let isValid = compareValPathToValue(d, config.valuePath, config.value);
              validation.push(isValid);
            }
          })
          if(validation.every(v => v === true)){
            newFilteredListing.push(d);
          }
        })
        filteredListing = [...newFilteredListing]  
      }
      

      
      
      filteredListing.map(d => {
        // let string = listingToFetch.type === 'static' //PEDNING : can get rid of this condition by passing 'null' as the set_get arg : listingPropertyToMatch
        //              ? d
        //              : handleStringFetching(d, listingPropertyToMatch);
        let string = handleStringFetching(d, listingPropertyToMatch);
        
        if( isStringMatch( string, searchString) === true ){
          newResults.push(d);
        } })
        
      return newResults;

  }

  const addClassIfSelected = (d) => {
    switch(true){
      case listingToFetch.type === 'externalApi':
      return selected.some(v => v.place_id === d.place_id) === true ? 'selected' : '';
      case listingToFetch.type === 'static':
      return selected.some(v => v === d) === true ? 'selected' : '';
      default:
      return selected.some(v => v._id === d._id) === true ? 'selected' : ''
    }
  }

  const genExternalApiResults = () => {
    
    return (
      <Fragment>
        { listings.loading 
        ? <Loader type='inline-loader' />
        : <Fragment>
          { listingToFetch.data.length > 0 &&
            <ul className='kp-dynamic-search-input__results-group-wrapper' style={{ [resultsGroupXPosAnchor] : 0}}>
              <ul className='kp-dynamic-search-input__results-group'>
            { listingToFetch.data.map((d,i) => {
                
                return(
                  <li
                    key={i}
                    className={
                      `kp-dynamic-search-input__result-wrapper
                       ${addClassIfSelected(d)}`}
                    onClick={() => onSelect(d)}
                    >
                    {resultCompRenderer(d)}
                  </li>
                )
              }) }
              </ul>
            </ul> }
          </Fragment> }
        </Fragment>
    )
  }

  const genStaticResults = () => {
    return(
        <Fragment>
        { listingToFetch.data.length > 0 &&
          handleDynamicSearch(listingToFetch.data, searchString).length > 0 &&
          <ul className='kp-dynamic-search-input__results-group-wrapper' style={{ [resultsGroupXPosAnchor] : 0}}>
            <ul className='kp-dynamic-search-input__results-group'>
          { handleDynamicSearch(listingToFetch.data, searchString).map((d,i) => {
              return(
                <li
                  key={i}
                  className={`kp-dynamic-search-input__result-wrapper ${selected.some(v => v._id === d._id) === true ? 'selected' : ''}`}
                  onClick={() => onSelect(d)}
                  >
                  {resultCompRenderer(d)}
                </li>
              )
            }) }
            </ul>
          </ul> }
        </Fragment>
    )
  }

  const genDynamicResults = () => {
    return (
      <Fragment>
        { (listings.loading || listingToFetch.loading)
        ? <Loader type='inline-loader' />
        : <Fragment>
          { listingToFetch.data.length > 0
            ? handleDynamicSearch(listingToFetch.data, searchString).length > 0 &&
            <ul className='kp-dynamic-search-input__results-group-wrapper' style={{ [resultsGroupXPosAnchor] : 0}}>
              <ul className='kp-dynamic-search-input__results-group'>
            { handleDynamicSearch(listingToFetch.data, searchString).map((d,i) => {
                if(!excludeThisResFromSearchList || d._id !== thisResId){
                  return(
                    <li
                      key={i}
                      className={`kp-dynamic-search-input__result-wrapper ${selected.some(v => v._id === d._id) === true ? 'selected' : ''}`}
                      onClick={() => onSelect(d)}
                      >
                      {resultCompRenderer(d)}
                    </li>
                  )
                }
              }) }
              </ul>
            </ul> 
            : <Meta style={{color: red}}>{noResultsText || 'No Results Found :('}</Meta>
            }
          </Fragment> }
        </Fragment>
    )
  }


  return (
      <div className='kp-dynamic-search-input__results-group-container' ref={resultsGroupRef}>
      { listingToFetch.type === 'static'
        ? genStaticResults()
        : listingToFetch.type === 'externalApi'
          ? genExternalApiResults()
          : genDynamicResults() }
      </div>
  )
}

KPDynamicSearchResultsBlock.propTypes = {
  listings: PropTypes.object.isRequired,
  getProfilesListing: PropTypes.func.isRequired,
  getParticipantsListing: PropTypes.func.isRequired, //phase out eventually
  getParticipants: PropTypes.func.isRequired,
  getPublishedListing: PropTypes.func.isRequired,
  getUsersListing: PropTypes.func.isRequired,
  getMixedListing: PropTypes.func.isRequired,
  getTagsListing: PropTypes.func.isRequired,
  getPlacesApiListing: PropTypes.func.isRequired,
  getSubResourceListing: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  reduxState : state,
  listings: state.listings,
  participants: state.participants
});

export default connect(
  mapStateToProps, {
    getProfilesListing,
    getParticipantsListing, //phase out eventually
    getParticipants,
    getPublishedListing,
    getUsersListing,
    getMixedListing,
    getTagsListing,
    getPlacesApiListing,
    getSubResourceListing
  })(KPDynamicSearchResultsBlock);
