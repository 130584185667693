import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Phone_solid_SM(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.282 12.9c3.266 3.82 6.707 4.744 10.326 2.775a1.224 1.224 0 00.624-.892 1.2 1.2 0 00-.347-1.029l-1.582-1.56a1.238 1.238 0 00-1.67-.064l-1.55 1.307c-2.044-.435-3.813-2.173-5.307-5.212.552-.555 1-1.005 1.35-1.347A1.206 1.206 0 008.13 5.16l-.002-.002-1.97-1.943a1.233 1.233 0 00-.97-.352 1.243 1.243 0 00-.9.502c-1.887 2.595-1.224 5.772 1.994 9.535z"
        fill={primaryColor100}
      />
    </svg>
  )
}

export default Phone_solid_SM
