import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function CType_Stories(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <path
        d="M3 1.3a.3.3 0 01.3-.3h13.4a.3.3 0 01.3.3v17.4a.3.3 0 01-.3.3H3.3a.3.3 0 01-.3-.3V1.3z"
        stroke={props.stroke || primaryColor100}
      />
      <path
        d="M5.5 6h9M5.5 9.5h9M5.5 13h9"
        stroke={props.stroke || primaryColor100}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default CType_Stories
