
import {rmArray, set_getValOffQueryString as s_gVal} from './general';
import store from '../store';

export const genLanguagesArray = () => { //this has to be a function because thats how kpdynamicsearchresultsblock works
  const writeInLangs = store.getState().environment.envConfig.languages.writeInLangs;
  return writeInLangs;
}

export const setTplLang = (kp_language, contentType) => {
  const _TPL_LANG = store.getState().environment.envConfig.languages._TPL_LANG;
  const getLocaleLangFromTpl = langMeta => {
    if(!langMeta) return null;

    //else
    let langMetaObj = Array.isArray(langMeta) ? rmArray(langMeta) : langMeta;

    if(_TPL_LANG[contentType].some(d => d.value === langMetaObj.value)){
      return langMetaObj;
    }
    //else
    return null;
  }

  return (
    getLocaleLangFromTpl(kp_language) || 
    _TPL_LANG[contentType][0]
  )
}


export const populateLocale = (initProps, props, locales, language, options={}) => {
  let newProps = props; //we are maintaining  direct reference to the og props and modifying it directly. cuz, otherwise, it will mess with React.memo usage
  
  Object.values(initProps).map((prop, i) => {
    let propKey = Object.keys(newProps)[i];

    /** 
     * 
     * we dont do this population on value and option props, 
     * because those are populated at the atomic level
     * 
     * uncomment this 'if' wrapper when we want to start this 
     * implementation of passing the entire
     * locale object for the option.display prop.
     * 
     * Details about this implementation have been documented on notion under 
     * 'Locale Aware Option-Select Inputs'
     * 
     */

    // if(['value', 'options'].indexOf(propKey) === -1 || ['KPRadioInput'].indexOf(options.comp) === -1){ 

      switch(true){

        //old implementation. can remove after all tpls have been shifted to new local implementation
        case Array.isArray(prop) && prop[0] === 'locale':
        newProps[propKey] = s_gVal(
          'get',
          locales[language.value],
          prop[1]
        );
        break;
        
        //new implementation
        case prop && !!prop.locale :
        newProps[propKey] = prop.locale[language.value]
        break;

        case Array.isArray(prop) && prop[0] !== 'locale':
        prop.map((childProp, j) => {
          if (typeof childProp === 'object' && childProp !== null) { //if aryVal is obj
            populateLocale(childProp, Object.values(props)[i][j], locales, language);
          }
        })
        break;

      }
  
    // }
    
  })
  
}

/**
 * used for extracting the display of options as well 
 * as values set from those options.
 * 
 * primary usage i believe will be in radio input, checkbox input, dropdowns
 */

 export const extractOptionDisplay = (display, lang) => {
  if(typeof display === 'string' || typeof display === 'number') return display;
  //else
  if(display.locale){ //if display is an object
    return display.locale[lang.value];
  }
}


