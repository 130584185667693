import axios from 'axios';
import store from '../store';
import uuid from 'uuid';
import {
    SET_PARTICIPANTS_LOADING,
    SET_PARTICIPANTS,
    SET_PARTICIPANTS_LONG,
    SET_PARTICIPANTS_ERROR,
    UPDATE_PARTICIPANTS,
    GET_PARTICIPANTS_FROM_STORE,
    RESET_PARTICIPANTS_SHORT
} from './types';
import {setAlert} from './alert';
import { getThisDomain, propertyExists, sortDataV2 } from '../utils/general';
import { setInitActiveSorts, updateActiveSorts } from '../utils/tableElemUtils';
import gocBoys__interviewId_to_userEmail_map from '../staticData/gocBoys__interviewerId_to_userEmail_map';


export const getParticipants = (filter = {}, options={}) => async dispatch => {
    try{
        dispatch({type: SET_PARTICIPANTS_LOADING, payload : true})
        let resType = options.resType && options.resType === 'long'
        ? 'long'
        : 'short'

        if(store.getState().participants[resType === 'long' ? 'long' : 'short'].data.length > 0){
            dispatch({type: GET_PARTICIPANTS_FROM_STORE})
            return;
        }
        let filterQueryString = `filter=${encodeURIComponent(JSON.stringify(filter))}`;
        let filterIfClashInQueryString = options.filterIfClashIn ? '&filterIfClashIn='+options.filterIfClashIn : '' ; 
        let onlyShowAssignedQueryString = options.onlyShowAssigned ? '&onlyShowAssigned='+options.onlyShowAssigned : '' ; //options.onlyShowAssigned is equal to the name of the current content type
        let res = await axios.get(`/api/participants?${filterQueryString}${options.resType ? '&resType='+ options.resType : ''}${filterIfClashInQueryString}${onlyShowAssignedQueryString}`);

        //sort by the default sort col defined in table config
        if(options.tableConfig){
            let defaultSortColIdx = options.tableConfig.cellData.findIndex(d => d.defaultSort === true);
            if(defaultSortColIdx !== -1){
                let firstSortConfig = options.tableConfig.cellData[defaultSortColIdx].sortConfig; 
                sortDataV2( res.data, firstSortConfig, 'a');
            }
        }
        
        
        dispatch({
            resType,
            type: SET_PARTICIPANTS,
            payload: {
                data: res.data,
                activeSorts: options.tableConfig && setInitActiveSorts(options.tableConfig)
            }
        })
    }catch(err){
        dispatch({
            type: SET_PARTICIPANTS_ERROR,
            payload : err.response ? err.response.data : 'oops something went wrong while fetching participant data' + err
        })
    }
}


export const updateParticipant = (updateId, updateBody, participantsData, callback = null) => async dispatch => {
    try{
        // dispatch({type: SET_PARTICIPANTS_LOADING, payload : true})
        

        let config = { headers : { "Content-Type" : "application/json"} }
        let apiUri = `/api/participants/${updateId}`;
        let res = await axios.post(apiUri, updateBody, config);
        let newParticipantsData = [...participantsData];
        console.log({res, newParticipantsData});
        let idx = newParticipantsData.findIndex(d => d._id === updateId);
        newParticipantsData[idx] = {
            ...newParticipantsData[idx],
            ...res.data //so that we dont overwrite the interviews status
        };

        dispatch({
            resType : 'long', //cuz we only update participant from the participant manager, which uses the long data structurex
            type: UPDATE_PARTICIPANTS,
            payload : {
               data: newParticipantsData
            }
        })

        callback && callback();
        
        dispatch(setAlert('Shortlist Status Updated!', 'success'))

    }catch(err){
        dispatch({
            type: SET_PARTICIPANTS_ERROR,
            payload : err.response ? err.response.data : 'oops something went wrong while updating participant data ' + err
        })

        callback && callback();
    }
}

export const updateParticipantInterviewsData = (thisParticipantData, updateBody, participantsData) => async dispatch => {
    try{
        // dispatch({type: SET_PARTICIPANTS_LOADING, payload : true})

        let updateId = thisParticipantData._id;

        let { interviewsStatus } = thisParticipantData

        let thisInterview = interviewsStatus.data.filter( i => i.interviewContentType === updateBody.interviewContentType)[0];
        
        const getAssigneeData = (interviewsData, interviewContentType) => {
            console.log({interviewsData, interviewContentType});
            let idx = interviewsData.findIndex(d => d.interviewContentType === interviewContentType);
            if(idx !== -1){
              return interviewsData[idx].assignedInterviewerId;
            }
            //else
            return undefined
        }

        let config = { headers : { "Content-Type" : "application/json"} }
        let apiUri = `/api/participants/interviewsData/${updateId}`;

        let res = await axios.post(apiUri, updateBody, config);
        let newParticipantsData = [...participantsData];
        console.log({res, newParticipantsData});
        let idx = newParticipantsData.findIndex(d => d._id === updateId);
        newParticipantsData[idx] = {
            ...newParticipantsData[idx],
            interviewsStatus : {
                ...newParticipantsData[idx].interviewsStatus,
                data : newParticipantsData[idx].interviewsStatus.data.map(d => {
                    const {interviewsData} = res.data
                    return {
                      ...d,
                      assignedInterviewer : getAssigneeData(interviewsData, d.interviewContentType)
                    }
                })   
            },
            ...res.data //so that we dont overwrite the interviews status
        };

        // console.log({ assignedId: updateBody.assignedInterviewerId});

        
        /**
         * generate url that has the template type & settings encoded
         * in it. anyone who opens this link in the browser ( or the CHOOOSE_CT_FP_TPL textInput )
         * can access the template directly, with the settings pre-filled
         */
        if(updateBody.assignedInterviewerId && propertyExists(updateBody.assignedInterviewerId.internal)){

            const getInterviewerCode = () => {
                let code = gocBoys__interviewId_to_userEmail_map.filter(d => d.email === updateBody.assignedInterviewerId.internal[0].email )[0].interviewerCode;
                return { display: code, value: code }
            }

            const urlData = {
                meta : {
                    kp_content_tpl : thisInterview.interviewTplId,
                    kp_content_type: updateBody.interviewContentType,
                    kp_language : { display: 'Hindi', value: 'hi'},
                    interviewer :  getInterviewerCode(), 
                    participantDbId : {
                        internal: [{ 
                            _id: thisParticipantData._id, 
                            participantId: thisParticipantData.participantId
                        }], //needs couple more datapoints like id string and stuff
                        searchString: ''
                    }
                },
                main : {}
            }

            // console.log({location: window.location.href.replace(window.location.pathname, '')});
            
            let thisDomain = getThisDomain();
            const genTplUrl = `${thisDomain}/direct-gen-tpl/${encodeURIComponent(JSON.stringify(urlData))}/${uuid.v4()}` ;
            // console.log({genTplUrl});
            window.prompt('Share this link with field researcher:', genTplUrl);
            

        }

        dispatch(setAlert('Interviewer Asssigned Successfully!', 'success'))

        dispatch({
            resType : 'long', //cuz we only update participant from the participant manager, which uses the long data structurex
            type: UPDATE_PARTICIPANTS,
            payload : {
                data: newParticipantsData
            }
        })

    }catch(err){
        dispatch({
            type: SET_PARTICIPANTS_ERROR,
            payload : err.response ? err.response.data : 'oops something went wrong while updating participant interview data ' + err
        })
    }
}

export const sortParticipants = (thisColConfig) => dispatch => {
    let newData = [...store.getState().participants.long.data];
    let {activeSorts} = store.getState().participants.long;
    let newActiveSorts = updateActiveSorts(activeSorts, thisColConfig)
    let sortBy = newActiveSorts[thisColConfig.colId];
    sortDataV2(newData, thisColConfig.sortConfig,sortBy);
    dispatch({
        resType : 'long', //cuz we only update participant from the participant manager, which uses the long data structurex
        type: UPDATE_PARTICIPANTS,
        payload : {
            data: newData,
            activeSorts: newActiveSorts
        }
    })
}

export const resetParticipants = (resType) => dispatch => dispatch({
    type: resType === 'long' ? '' : RESET_PARTICIPANTS_SHORT
})