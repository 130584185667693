import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import store from '../../../store';
import { KPIconBtn } from '../../generalUI/KPButtons';
import Close from '../../icons/Close';

import {setModalOverlay} from '../../../actions/environment';

const ModalShell = (props) => {

  useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("style", "overflow: hidden");
    if(props.blockOverflowIds) {
      props.blockOverflowIds.map(id => {
          document.getElementById(id).setAttribute("style", "overflow: hidden");
      })
    }
    store.dispatch(setModalOverlay(true));
    return () => {
      document.getElementsByTagName("html")[0].removeAttribute("style");
      if(props.blockOverflowIds) {
        props.blockOverflowIds.map(id => {
            document.getElementById(id).removeAttribute("style");
        })
      }
      store.dispatch(setModalOverlay(false));
    }
  },[])

  return (
    <div className={`kp-modal-container kp-container-fluid ${props.className}`}>
      <div className={`kp-modal-wrapper kp-offset-container ${ props.colSettings || 'col-xl-8 offset-xl-2'}`}>
        <div className='kp-modal'>
          <div className='kp-modal__scroll-area'>
          { props.children }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalShell;
