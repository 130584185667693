import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function Mail24(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 6.3v11.49a1.5 1.5 0 001.5 1.5h17.394a1.5 1.5 0 001.5-1.5V6.3a1.5 1.5 0 00-1.5-1.5H3.9a1.5 1.5 0 00-1.5 1.5v0z"
        fill="#E0F0FD"
        stroke={primaryColor100}
      />
      <path
        d="M2.4 6l9.225 7.866a1.5 1.5 0 001.945 0L22.794 6"
        stroke={primaryColor100}
      />
    </svg>
  )
}

export default Mail24
