import React, {Fragment} from "react";
import uuid from 'uuid'
import KPRichInlineImage from '../../inputs/KPRichInlineImage';
import KPResourceUpload from '../../inputs/KPResourceUpload';
import KPPDFDisplay from '../../inputs/KPPDFDisplay';
import KPFAQComp from '../../inputs/KPFAQComp';
import {KPTextInput} from '../../inputs/KPInputs'
import KPCardLinksDisplay from '../../inputs/KPCardLinksDisplay';
import KPLikeContactChannelsBlock from '../../inputs/KPLikeContactChannelsBlock';
import KPPPTEmbed from '../../inputs/KPPPTEmbed';
import KPVideoEmbed from '../../inputs/KPVideoEmbed';
import KPTable from '../../inputs/KPTable';
import AudioEmbed from '../../inputs/AudioEmbed';
import {ButtonSecondary} from '../../generalUI/KPButtons';

const KPRichInlineBlockRenderer = props => {

  const Components = {
    KPTextInput,
    KPRichInlineImage,
    KPResourceUpload,
    KPCardLinksDisplay,
    KPPDFDisplay,
    KPPPTEmbed,
    KPVideoEmbed,
    KPFAQComp,
    KPTable,
    AudioEmbed,
    ButtonSecondary,
    KPLikeContactChannelsBlock
  }

  const entityKey = props.block.getEntityAt(0); //automatically passed by renderAtomicBlock function
  
  let entity, Component, compProps;
  if(entityKey){
    entity = props.contentState.getEntity( entityKey ); //automatically passed by renderAtomicBlock function
    Component = Components[entity.getType()]; //automatically passed by renderAtomicBlock function
    compProps = {...entity.getData(), ...props.blockProps};
  }
  
  //props.blockProps === returned Object property 'props'. this is also preset by draftjs
  //entity.getData is === the last object that is passed into the createAtomicBlockEntity function. eg: { src: "", loading: true }

  return (
    <Fragment>
    { Component &&
      <Component
        block = {props.block}
        isInEditor = {true}
        entityKey = {entityKey}
        {...compProps}
        id = {uuid.v4()} //just ensuring every block rendered has a unique id. important for blocks containing file uploaders to work properly
        /> }
    </Fragment>
    
  )
};

export default KPRichInlineBlockRenderer;
