import React, {Fragment, useState, useEffect} from "react";
import PropTypes from 'prop-types';
//import ReactPlayer from 'react-player'
import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData} from '../../../utils/richInputUtils'
import { KPTextInput } from '../KPInputs';
import KPLabel from '../../generalUI/KPLabel';

const jsdom = require("jsdom");
/*
   -> Entity Update
   -> Wrap the component in shell
   -> handleSetTempReadOnly()
*/
const KPPPTEmbed = ({
  label,
  sublabel,
  id,
  className,
  value,
  placeholder,
  onChange : parentOnChange,

  isInEditor,
  editor,
  entityKey,
  handleSetTempReadOnly,
  block,

  errorMsgs,
  readOnly,
  isRequired
}) => {

  const [url, setUrl] = useState(value || null);



  function fetchSlideShareURL(address) {
    jsdom.env(
      address,
      ["http://code.jquery.com/jquery.js"],
      function (errors, window) {
        var $ = window.$ ,
            presentation = $(".twitter_player").attr('value')

        if(presentation === undefined){
          return({error: true})
        }
        else{
          return(presentation)
        }
      }
    )
  }

  function filterGoogleSlidesURL(address) {
    if(address.search('/edit') > -1){
      return ((address.split('/edit'))[0]);
    } else {
      return address;
    }
  }

  function filterPPTURL(address) {
    if(address){
      if (address.search('google') > -1) {
        return filterGoogleSlidesURL(address)
      } else if (address.search('slideshare') > -1) {
        return fetchSlideShareURL(address)
      }
    }
  }


  const onChangeUrl = v => {
    setUrl(v);
    parentOnChange && parentOnChange(id, v)

    if(isInEditor === true){
      replaceEntityData(editor, entityKey, {value: v })
    }
  }

  const createPPTPreview = () => {
    switch(true){
      case url === null || url.trim() === '': // No URL
      return <h5 className='h5 bold'>paste a url above to view preview of Google Slide</h5>;
      default: // Load iFrame for URL
      return (
        <div className='kp-ppt-embed-wrapper'>
          <iframe
            className='kp-ppt-embed'
            src={filterPPTURL(url)+"/embed?start=false&loop=false&delayms=3000"}
            frameborder="0"
            width="100%"
            height="100%"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            >
          </iframe>
        </div>
      )
    }
  }

  const inputBlock = () => (
    <Fragment>
      <KPTextInput
        type='url'
        placeholder={placeholder}
        onChange={(k,v) => onChangeUrl(v)}
        value={url}
      />
    <div className='kp-ppt-embed__preview'>{createPPTPreview()}</div>
    </Fragment>
  )

  const displayBlock = () => (
    <div className='kp-ppt-embed__display'>
    { url &&
      <div className='kp-ppt-embed-wrapper'>
        <iframe
          className='kp-ppt-embed'
          src={filterPPTURL(url)+"/embed?start=false&loop=false&delayms=3000"}
          frameborder="0"
          width="100%"
          height="100%"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          >
        </iframe>
      </div> }
    </div> )

  const createBlock = () => {
    switch(true){
      case readOnly:
      return displayBlock();
      default:
      return inputBlock();
    }
  }

  return (

      <ContentBlockShell
        richInputProps = {{
          isInEditor : isInEditor,
          editor: editor,
          block: block,
          handleSetTempReadOnly: handleSetTempReadOnly
        }}
        readOnly = { readOnly }
        className= { `kp-ppt-embed-container ${className}` }
        id={id}
      >
        <KPLabel
          label={label}
          sublabel={sublabel}
          isRequired={isRequired}
          readOnly={readOnly}
          />
        {createBlock()}
      </ContentBlockShell>

  )
};

KPPPTEmbed.defaultProps = {}


KPPPTEmbed.propTypes = {

}


export default KPPPTEmbed
