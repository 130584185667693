import React, { Fragment } from 'react';
import { set_getValOffQueryString } from './general';
import {drilldownSubSection} from './contributionFlowUtils';
import KPLabel from '../components/generalUI/KPLabel';
import KPList from '../components/generalUI/KPList';

export const getLabelProps = props => {
  //1 collect the label props.
  const labelPropKeysRef = [
    'id',
    'srNo',
    'label',
    'sublabel',
    'readOnly',
    'errorMsgs',
    'isRequired',
    'inputOnlyLabel',
    'invert'
  ]

  const labelProps = {};

  Object.keys(props).map((propKey, i) => {
    if (labelPropKeysRef.includes(propKey)) {
      labelProps[propKey] = props[propKey]
    }
  })

  return labelProps;
}

export const simpleBlockGenerator = (customCompBlockDataAry, parentCompProps, blocksGroupClassName) => {

  const Components = {
    KPLabel,
    KPList
  }

  const generate = block => {
    const Component = Components[block.comp];
    const value = block.valuePath && set_getValOffQueryString('get', parentCompProps, block.valuePath);
    return (
      <Component
        {...block.props}
        value={value}
      />
    )
  }

  return (
    <Fragment>
      { customCompBlockDataAry &&
        <div className={`kp-simple-block-generator__blocks-group ${blocksGroupClassName || ''}`}>
          {drilldownSubSection(customCompBlockDataAry, (block, subSectionName) => generate(block), 0)}
        </div>}
    </Fragment>
  )
}


export const handleDynamicInputKeyPress = (e, allowKeyAction, searchString, callback) => {
  
  if(allowKeyAction === true){ 
    if (e.key ==='Enter' && searchString !== "") {
      // TODO - Remove special characters, sanitize input.
      callback(searchString);
    }
    if((e.key === ' ' || e.key === 'Spacebar') && searchString[searchString.length - 1] === ','){
      callback(searchString.split(',')[0]);
    }
  }

}
