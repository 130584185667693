import React, {Fragment} from "react";


const TitleSerif__M = (props) => {

  

  return (
    <h3 className={`titleSerif--M ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h3>
  );

}



export default TitleSerif__M;
