import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import KPImageInput from "../../inputs/KPImageInput";
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import Label__M from '../../typo/Label__M';
import { primaryColorText } from '../../../sass/vars.scss';
import { ButtonSecondary} from '../../generalUI/KPButtons';


export function CardUserProfile(props) {
  const {
    profileImage,
    title,
    summary,
    link,
    label,
    className,
    style,
    customClickHandler,
    onRemoveCard,
    readOnly
  } = props;

  const genCardContent = () => (
    <Fragment>
      {
        profileImage &&
        <div className="Card__displayImg-wrapper">
          <ProfileImgDisplay
            avatar={profileImage.avatar}
            size={12}
            placeholderInitial={title && title.trim()[0]}
            containerShape={profileImage.containerShape}
          />
        </div>
      }
      <div className="Card__details">
        {title && <h4 className="h4 Card__title">{title}</h4>}
        {label &&
          <Label__M
            style={{ color: primaryColorText, margin:".5rem 0", textAlign: 'center' }}
          >{label.value}</Label__M>}
        {
          summary &&
          <h4 className="Card__summary"> {summary} </h4>
        }
      </div>
    </Fragment>
  )

  return (
    <div style={style} className={`${className} Card--UserProfile`}>
      { onRemoveCard && !readOnly && //only show if a remove card function is passed AND we are not in read-only mode
        <ButtonSecondary
          className='kp-content-card-wrapper__remove-btn'
          // type='danger'
          // size='small'
          onClick={onRemoveCard}
          icon="Close_S"
          >
          {/* <Close_S fill={white} /> */}
        </ButtonSecondary>
       }
      { link && link.type === 'internal'
        ? <Link to={link.url} className="Card-linkWrapper">
          {genCardContent()}
          </Link>
        : link && link.type === 'external'
          ? <a href={link.url} target={'_blank'} className="Card-linkWrapper">
              {genCardContent()}
            </a>
          : <div className="Card-linkWrapper" onClick={customClickHandler}>
              {genCardContent()}
            </div> }
    </div>
  )
}

export function CardOrganisation(props) {
  const {
    title,
    summary,
    link,
    image,
    label,
    className
  } = props;
  return (
    <div className={`${className} Card--Organisation`}>

      <Link to={link.url} className="Card-linkWrapper" >
        {
          image &&
          <div className="Card__displayImg-wrapper">
            <KPImageInput
              value={image.data}
              heightRatio={image.heightRatio && image.heightRatio}
              placeholderColor={image.placeholderColor}
              containerShape={image.containerShape}
              height={image.height && image.height}
              width={image.width && image.width}
              imgBlockComp={image.imgBlockComp}
              backgroundSize={image.backgroundSize && image.backgroundSize}
              placeholderInitial={image.placeholderInitial}
              readOnly={true}
            />
          </div>
        }
        <div className="Card__details">
          {title && <h4 className="h4 Card__title">
            {title}</h4>}

          {
            label &&
            <Label__M
              style={{ color: primaryColorText, marginTop: ".5rem" }} 
              >{label.key}</Label__M>
          }
          {
            summary &&
            <h4 className="h4 Card__summary"> {summary} </h4>
          }
        </div>
      </Link>
    </div>
  )
}

export function CardGroup(props) {
  const {
    title,
    summary,
    link,
    image,
    multiProfilesDisplayData,
    className
  } = props;
  return (
    <div className={`${className} Card--Group`}>
      <Link to={link.url} className="Card-linkWrapper" >
        {
          image &&
          <div className="Card__displayImg-wrapper">
            <KPImageInput
              value={image.data}
              heightRatio={image.heightRatio && image.heightRatio}
              placeholderColor={image.placeholderColor}
              containerShape={image.containerShape}
              height={image.height && image.height}
              width={image.width && image.width}
              imgBlockComp={image.imgBlockComp}
              backgroundSize={image.backgroundSize && image.backgroundSize}
              placeholderInitial={image.placeholderInitial}
              readOnly={true}
            />
          </div>
        }
        <div className="Card__details">
          {title && <h4 className="h4 Card__title">{title}</h4>}
          {
            summary &&
            <h4 className="h5 Card__summary"> {summary} </h4>
          }
          {
            multiProfilesDisplayData &&
            <p className="Card__members">
              {multiProfilesDisplayData.value.length} members
        </p>
          }
        </div>
      </Link>
    </div>
  )
}

