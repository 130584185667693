import * as React from "react"

function ItalicBtn(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12.193 17.37h-1.827L11.605 7.25h1.827l-1.24 10.122z"
        fill="#fff"
      />
    </svg>
  )
}

export default ItalicBtn
