import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import store from './store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {setAnnouncement} from './actions/alert';
import {setAppOffline, setAppBackOnline} from './actions/environment';
import {
  // setSaveStatusToLocalSave, 
  setSaveStatusToSavedToDb} from './actions/contributions';
import {preCacheApis} from './utils/swUtils';
import './index.scss';



ReactDOM.render(
  <Provider store={store}><App/></Provider>,
  document.getElementById( 'root' )
);

// serviceWorkerRegistration.register({
      
//   onUpdate : (registration) => store.dispatch(
//     setAnnouncement(
//       "New Update Available!",
//       'success -full-page-',
//       null, //null timeout means keep this annoucement till infinity
//       { 
//         cta : { text: 'Update Now', action: 'skipWaitingAndReload'},
//         sw : registration.waiting
//       }
//     )
//   ),

//   onNewSWActivated : () => preCacheApis(),

//   onFirstInstall : () => store.dispatch(
//     setAnnouncement(
//       'Offline Ready!',
//       'success',
//       null,
//       {
//         id: 'offlineReady',
//         cta:{
//             action : 'removeAnnouncement',
//             text: 'close'
//         }
//       }
//     )
//   ),
  
//   // onAppOffline : () => store.dispatch(setAppOffline()),
//   // onAppBackOnline : () => store.dispatch(setAppBackOnline()),
//   // onLocalSave : () => store.dispatch(setSaveStatusToLocalSave()),
//   onSavedToDb : ({Contribution}) => store.dispatch(setSaveStatusToSavedToDb(Contribution))

// });

serviceWorkerRegistration.unregister();