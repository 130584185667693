import {
  SET_PARTICIPANTS_LOADING,
  SET_PARTICIPANTS,
  SET_PARTICIPANTS_ERROR,
  UPDATE_PARTICIPANTS,
  GET_PARTICIPANTS_FROM_STORE,
  RESET_PARTICIPANTS_SHORT

} from '../actions/types';

const initialState = {
  loading : true,
  long : {
    data : []
  },
  short : {
    data : []
  },
  error : undefined
};

export default function(state = initialState, action) {
  const {type, payload, resType} = action

  switch (type) {

    case SET_PARTICIPANTS_LOADING: 
      return {
        ...state,
        loading: payload
      }
    case SET_PARTICIPANTS:
    case UPDATE_PARTICIPANTS:
      return {
        ...state,
        [resType]: {
          ...state[resType],
          /**
           * eventually initData has to come to handle search. 
           * but this will be a bit tricky
           * wrt. keeping initData and data in sync when things 
           * like status update and assignee updates happen
           */
          data: payload.data,
          activeSorts: payload.activeSorts 
            ? payload.activeSorts
            : state[resType].activeSorts
        },
        loading: false
      }
    case SET_PARTICIPANTS_ERROR:
      return {
        ...state,
        error : payload,
        loading : false
      }
    case GET_PARTICIPANTS_FROM_STORE:
      return { 
        ...state,
        loading: false 
      }
    case RESET_PARTICIPANTS_SHORT:
      return { 
        ...state,
        loading : true,
        short : {
          data : []
        },
        error : undefined
      }
    default:
      return state;
  }
}
