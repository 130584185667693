import React, { Fragment, useState, Component } from 'react'
import { UploadSimple } from 'phosphor-react'
import KPResourcesBlock from '../KPResourcesBlock'

import { setAlert } from '../../../actions/alert';
import store from '../../../store';
import { KPFileUploadBtn } from '../../generalUI/KPButtons';
import KPLabel from '../../generalUI/KPLabel';
import ContentBlockShell from '../../generalUI/ContentBlockShell';
//icons
import UploadImage from '../../icons/UploadImage';
import Delete from '../../icons/Delete';

import { imageUploader } from '../../../utils/general'

//utils
import { convertResToGcsStructure } from '../../../utils/general';
import { replaceEntityData } from '../../../utils/richInputUtils';

const KPResourceUpload = (props) => {

  const { onChange: parentOnChange } = props;
  const initResourcesState = [];
  const [resources, setResources] = useState(
    props.value
      ? convertResToGcsStructure(props.value)
      : initResourcesState
  );
  const [uploading, setUploading] = useState(false);

  const callback = (status, resData) => {
    if (status === "success") {
      const newResources = props.multiple === true
        ? [...resources, ...resData]
        : resData

      setResources(newResources);
      parentOnChange && parentOnChange(props.id, newResources, props.passChangeHandlerOps && props);

      //this only runs if comp is rendered inside rich editor
      if (props.isInEditor === true) {
        replaceEntityData(props.editor, props.entityKey, { value: newResources })
        props.handleSetTempReadOnly(false);
      }
    }
    setUploading(false);
  }

  const onChange = e => {
    const files = Array.from(e.target.files)
    setUploading(true);

    const formData = new FormData()
    files.forEach((file, i) => { formData.append(i, file) })
    formData.set("folderName", props.folderName);
    formData.set("allowedFormats", props.allowedFormats);
    // formData.set("flags", "attachment");
    imageUploader(formData, { type: 'files' }, callback);
  }

  const removeResource = id => {
    let newResources = resources.filter(d => d.id !== id);
    setResources(newResources);
    parentOnChange && parentOnChange(props.id, newResources, props.passChangeHandlerOps && props);

    if (props.isInEditor === true) {
      replaceEntityData(
        props.editor,
        props.entityKey,
        { value: newResources }
      )
    }
  }

  const content = () => {
    switch (true) {
      case uploading:
        return (
          <div className="kp-resource-uploading-icon-wrapper">Uploading...</div>
        )

      case resources.length > 0:
        return (
          <Fragment>
            <KPResourcesBlock
              resources={resources}
              removeResource={(id) => removeResource(id)}
              displayType={props.displayType}
              displayName={props.displayName}
              readOnly={props.readOnly}
              uploadOnChange={onChange}
              multiple={props.multiple}
              trackDownload={props.trackDownload}
            />
            { !props.readOnly && props.multiple &&
              <KPFileUploadBtn
                btnProps={{
                  comp: 'ButtonSecondary',
                  props: { invert: props.invert || false }
                }}
                // comp="KPBtnPrimary"
                onChange={onChange}
                multiple={props.multiple}
                id={`${props.id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
              >
                Upload More Resources
          </KPFileUploadBtn>}
          </Fragment>

        )

      default:
        return (
          <Fragment>
            { !props.readOnly &&
              <KPFileUploadBtn
                btnProps={{
                  comp: 'ButtonSecondary',
                  props: {
                    icon: "UploadSimple",
                    invert: props.invert || false,
                    value: props.fileUploadBtnText || 'Upload Resources'
                  }
                }}
                onChange={onChange}
                multiple={props.multiple}
                id={`${props.id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
              >
                {/* {props.fileUploadBtnText || 'Upload Resources'} */}
              </KPFileUploadBtn>}
          </Fragment>
        );
    }
  }

  return (
    <ContentBlockShell
      richInputProps={{
        isInEditor: props.isInEditor,
        editor: props.editor,
        block: props.block,
        handleSetTempReadOnly: props.handleSetTempReadOnly
      }}
      readOnly={props.readOnly}
      className={`kp-resource-uploader-wrapper ${props.className}-wrapper`}
    >
      <KPLabel
        label={props.label}
        sublabel={!props.readOnly && props.sublabel}
      />
      <div
        id={props.id}
        className={`kp-resource-uploader ${props.className}`}
      >
        {content()}
      </div>
    </ContentBlockShell>
  )
}

KPResourceUpload.defaultProps = {
  id: "kp-resource-uploader", //required
  // label: "resources", //optional
  multiple: true, //required
  className: "", //optional
  folderName: `attachments`, //required
  allowedFormats: ["jpg", "png", "pdf", "doc", "docx", "xls", "xlsx", "zip", "ppt", "pptx", "mp4", "csv"], //required
  readOnly: false
}

export default React.memo(KPResourceUpload);
