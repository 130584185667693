import React, {Fragment} from "react";


const TitleSans__L = (props) => {

  

  return (
    <h2 className={`titleSans--L ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h2>
  );

}



export default TitleSans__L;
