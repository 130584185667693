import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function AddXS(props) {
  return (
    <svg width={15} height={15} viewBox="0 0 15 15" fill="none" >
      <path
        stroke={props.stroke || primaryColor100}
        strokeWidth={2}
        strokeLinecap="round"
        d="M7.767 1.346v13M1.306 7.884h13"
      />
    </svg>
  )
}

export default AddXS
