import React, { useState, useEffect, Fragment } from "react";
import {useHistory } from 'react-router-dom';
import uuid from 'uuid';

import KPLabel from '../../generalUI/KPLabel';
import { ButtonTertiary } from '../../generalUI/KPButtons';
import { KPTextInput } from '../KPInputs';
import KPRadioInput from '../../inputs/KPRadioInput'

import KPLink from '../../generalUI/KPLink';
import TitleSans__S from '../../typo/TitleSans__S';
import AddXS from '../../icons/AddXS';
import KPRichInput from '../../inputs/KPRichInput';
import { KPDropdown } from '../../inputs/KPDropdowns';
import { convertToRichText, makeAryFromLen, blockIsPopulatedConditions } from '../../../utils/general';
import { getLabelProps } from '../../../utils/contentBlock';
import { useParseContentBlockValue } from '../../../utils/customHooks';


//local utils
import {
  genInitVal,
  handleAddRow,
  handleAddCol,
  handleCellHover,
  handleEdgeHover,
  genEdgeCursor
} from './functions';


const KPTable = props => { //col settings must be the bootstrap codes


  let {
    id,
    label,
    sublabel,
    isRequired,
    errorMsgs,
    readOnly,
    alwaysReadOnly,
    initValue, //if preset, readOnly cell data are being passed in
    value,
    onChange,
    initRows,
    initCols,
    canResizeRowsAndCols,
    canAddRows,
    canAddCols,
    hasTableHeader,
    createSrNoCol,
    colContentBlocks,
    colContentBlockProps,
    cellBorderWidth,
    passChangeHandlerOps,
    setParentValOnMount
  } = props;


  let history = useHistory();
  
  //cell hover and edge hover can be single individual states. would be more optimized.
  //realign col & row width
  //merge cells
  //basic bg color setting


  const removeRedundantRows = value => {
    if (!value) return null;
    if (value && !readOnly) return value;
    if (value && alwaysReadOnly) return value;
    //else
    let cleanedUpVal = [];
    value.map(row => {
      let cellData = row.cellData || row;
      if( cellData.every(cell => cell.readOnly === true)){ //if either it is a totally readOnly row  
        //then show that row
        cleanedUpVal.push(row);
      }else if( cellData.some(cell => !cell.readOnly && blockIsPopulatedConditions(cell, cell.value)) ){ //or it is a user input capable row, and it does infact have some user input
        //then show that row
        cleanedUpVal.push(row);
      }
    })

    return cleanedUpVal;
  }

  const val = removeRedundantRows(value) || genInitVal(props);

  useEffect(() => {
    setParentValOnMount && onChange && onChange(id, val, passChangeHandlerOps && props);
  }, [])

  const CellContentBlocks = {
    KPRichInput,
    KPDropdown,
    KPTextInput,
    KPRadioInput,
    TitleSans__S
  }

  const CellContentBlockDefaultProps = {
    KPRichInput: {
      richFormattable: false,
      inlineFormattable: true,
      trackEditorFocus: false
    },
    KPDropdown: {
      size: 'small'
    },

  }

  const handleCellInputChange = React.useCallback((id, v, ops = null) => {
    const { rowIdx, cellIdx, cellComp } = ops;
    let newVal = [...val];
    newVal[rowIdx][cellIdx].value = v;
    onChange && onChange(id, newVal, passChangeHandlerOps && props);
  }, [val.length, val[0].length]) //reinitiate function when number of rows or cols changes, otherwise the value passed through isnt updated.

  const handleKeyPress = (e) => {
    if (e.keyCode === 9) {
      if (canAddRows) {
        handleAddRow(val, props);
      }
    }
  }

  return (
    <div className='kp-table'>
      <KPLabel {...getLabelProps(props)} />
      <div style={{display: 'flex'}}>
        <div style={{border: `${cellBorderWidth}px solid #e6e6e6`, flexGrow: 1}}>
          { val.map((row, rowIdx) => (
            <div onClick={() => row.link && history.push(row.link)} style={{display: 'flex'}} key={"row_"+rowIdx}>
            { (row.cellData ? row.cellData : row).map((cell, cellIdx) => {
              
              let CellComp = CellContentBlocks[cell.comp];
              let cellProps = {...CellContentBlockDefaultProps[cell.comp], ...(cell.props ? cell.props : {})};

              return(
              <div 
                key={cell.id}
                id = {`kp_table__cell__${cell.id}`}
                className = {
                  `kp-table__cell 
                  ${hasTableHeader && rowIdx === 0 ? 'kp-table__cell--header' : ''}
                  ${cell.readOnly && !readOnly ? 'disabled-cell-style' : '' /* we apply the disabled cell style only when user is in edit mode, when user is in view mode, we dont need to show this, because all cells are readonly in such a scenario */}
                  `
                    }
                    style={{
                      border: `${cellBorderWidth}px solid #e6e6e6`,
                      width: `${cell.width}%`,
                      minHeight: '30px',
                      cursor: genEdgeCursor(cell)
                    }}
                    onMouseEnter={() => canResizeRowsAndCols && handleCellHover(true, rowIdx, cellIdx, val)}
                    onMouseLeave={() => canResizeRowsAndCols && handleCellHover(false, rowIdx, cellIdx, val)}
                    onMouseMove={(e) => canResizeRowsAndCols && handleEdgeHover(e, rowIdx, cellIdx, val, props)}
                    onKeyDown={
                      (e) => {
                        if (rowIdx === val.length - 1) {
                          handleKeyPress(e)
                        } else {
                          return;
                        }
                      }}
                  >
                    <CellComp
                      {...cellProps}
                      id={`kp_table_cell__${cell.id}__rich_input` /*dont mess with this id nomenclature. it needs to stay this way. check out the handleCellInputChange fn to understand */}
                      value={cell.value}
                      readOnly={readOnly ? readOnly : cell.readOnly /* if the table itself is readonly, then make every cell readonly, no questions asked. if not, then depend on what the readonly status of each individual cell is */}
                      rowIdx={rowIdx} //passed through only to pass it back through onChange. need to do this to make useCallback work on handleCellInputChange
                      cellIdx={cellIdx} //same as above
                      cellComp={cell.comp} //same as above
                      passChangeHandlerOps={true}
                      onChange={handleCellInputChange}
                    />
                  </div>
                )
              })}
            </div>
          ))}
        </div>
        {!readOnly && canAddCols &&
          <ButtonTertiary
            // size='small' 
            // type='tertiary' 
            icon="Plus"
            onClick={() => handleAddCol(val, props)} />}
      </div>
      { !readOnly && canAddRows &&
        <ButtonTertiary
          // size='small' 
          // type='tertiary'
          icon="Plus"
          onClick={() => handleAddRow(val, props)} />}
    </div>
  )
}

KPTable.defaultProps = {
  initRows: 2,
  initCols: 2,
  canResizeRowsAndCols: true,
  canAddRows: true,
  canAddCols: false,
  hasTableHeader: true,
  createSrNoCol: true,
  cellBorderWidth: 1,
  colContentBlocks: [], //an empty array means, no comp is defined for any of the cols, in which case it defaults to KPRichInput
  colContentBlockProps: [],
  setParentValOnMount: false
};

KPTable.propTypes = {};

// export default KPTable;
export default React.memo(KPTable
  // , ( prevProps, nextProps) => {
  //   return JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value)
  // }
);

export { KPTable as KPTable__NoMemo }


