import React, { Fragment, useEffect, useState, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import history from "./history";
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import PrimaryHeader from './components/nav/PrimaryHeader';
import Footer from './components/nav/Footer';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';
import VettedPlusRoute from './components/routing/VettedPlusRoute';
import TitleSans__L from './components/typo/TitleSans__L';
import TitleSans__S from './components/typo/TitleSans__S';

//content
import Announcement from './components/generalUI/Announcement';
import Alert from './components/generalUI/Alert';
import Loader from './components/generalUI/Loader';
import Grid from './components/generalUI/Grid';
import {ButtonSecondary} from './components/generalUI/KPButtons';

//redux
import store from './store';
import { loadUser } from './actions/auth';
import { getClientConfig } from './actions/config'
import setAuthToken from './utils/setAuthToken';
import { updateOnlineStatus, updateScreenWidth } from './actions/environment';





import './App.scss';


console.log("REACT VERSION: ", React.version);


const AdminDashboard = React.lazy(() => import('./pageTpls/AdminDashboard'));
const Home = React.lazy(() => import('./pageTpls/Home'));
const Playground = React.lazy(() => import('./pageTpls/Playground'));
const DirectGenTpl = React.lazy(() => import('./pageTpls/DirectGenTpl'));
const ContributeConfigure = React.lazy(() => import('./pageTpls/ContributeConfigure'));
const Search = React.lazy(() => import('./pageTpls/Search'));
const Subscribe = React.lazy(() => import('./pageTpls/Subscribe'));
const ContributeTpl = React.lazy(() => import('./pageTpls/ContributeTpl'));
const ViewProfile = React.lazy(() => import('./pageTpls/ViewProfile'));
const EditProfile = React.lazy(() => import('./pageTpls/EditProfile'));
const UserProfilesListing = React.lazy(() => import('./pageTpls/UserProfilesListing'));

const GroupedPublishedListing = React.lazy(() => import('./pageTpls/GroupedPublishedListing'));
const PublishedListing = React.lazy(() => import('./pageTpls/PublishedListing'));
const PublishedPage = React.lazy(() => import('./pageTpls/PublishedPage'));
const ResetPwdPg = React.lazy(() => import('./pageTpls/ResetPwdPg'));
const RecommendationsPage = React.lazy(() => import('./pageTpls/RecommendationsPage'));
const MediaUploader = React.lazy(() => import('./pageTpls/MediaUploader'));
const Data = React.lazy(() => import('./pageTpls/Data'));
const DataPATHDrilldown1 = React.lazy(() => import('./pageTpls/DataPATHDrilldown1'));
const DataPATHDrilldown2 = React.lazy(() => import('./pageTpls/DataPATHDrilldown2'));

const Login_Registration = React.lazy(() => import('./pageTpls/Login_Registration'));
const VerifEmail = React.lazy(() => import('./pageTpls/VerifEmail'));
const VerifyResetPwd = React.lazy(() => import('./pageTpls/VerifyResetPwd'));
const BatchRegister = React.lazy(() => import('./pageTpls/BatchRegister'));
const ModQueue = React.lazy(() => import('./pageTpls/ModQueue'));
const GoogleAuth = React.lazy(() => import('./pageTpls/GoogleAuth'));
const OKE404 = React.lazy(() => import('./pageTpls/OKE404'));

//reSynth
const ReSynthActivitiesListing = React.lazy(() => import('./pageTpls/ReSynthActivitiesListing'));
const ReSynthActivitiesClustered = React.lazy(() => import('./pageTpls/ReSynthActivitiesClustered'));
const ReSynthActivitiesClusteredResponses = React.lazy(() => import('./pageTpls/ReSynthActivitiesClusteredResponses'));

const ENDPOINT = "/";

// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }




const App = ({ updateScreenWidth, modalOverlay, auth, envConfigLoading, envConfig }) => {

  const handleResize = () => updateScreenWidth(window.innerWidth)
  const handleOnlineStatusChange = (status) => store.dispatch(updateOnlineStatus(status))

  /**
   * shouldnt use useSelector here to access store variables cuz 
   * it for some reason triggers off several rerenders. 
   * 
   * so instead we use the connect HOC
   */

  useEffect(() => {
    store.dispatch(getClientConfig())
    store.dispatch(loadUser());
    window.addEventListener('resize', handleResize); //add listener to get window resize
    window.addEventListener('offline', () => handleOnlineStatusChange('offline'));
    window.addEventListener('online', () => handleOnlineStatusChange('online'));
    updateScreenWidth(window.innerWidth);
    
    if(window.navigator.onLine){ handleOnlineStatusChange('online') } else
    { handleOnlineStatusChange('offline') }

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('offline', () => handleOnlineStatusChange('offline'));
      window.removeEventListener('online', () => handleOnlineStatusChange('online'));
    }
  }, [])

  const [showOkeDevTools, setShowOkeDevTools] = useState(false);
  const [showWarningScreen, setShowWarningScreen] = useState(true);

  
  return (
    <Fragment>
    { envConfigLoading
      ? <Loader/>
      : <Fragment>
          {['staging'].indexOf(envConfig.deployment._Env) !== -1 && showWarningScreen &&
          <div className='OKE-WarningScreen'>
            <img src={envConfig.deployment.logo} style={{height : '50px'}}/>
            <TitleSans__L>This website is for testing purposes only.</TitleSans__L>
            <TitleSans__L style={{marginBottom: '1rem'}}>Any data entered here is likely to be erased.</TitleSans__L>
            <TitleSans__S>{`If you intended to visit the live '${envConfig.deployment._DeploymentDisplayName}' platform, please go here : `}<a className='OKE-WarningScreen__link' href='#'>{'<PENDING PROD URL CONFIG>'}</a></TitleSans__S>
            <TitleSans__S>Else, if you know why you are here, <span className='OKE-WarningScreen__link' onClick={() => setShowWarningScreen(false)}>click here to proceed</span></TitleSans__S>
          </div>}
          <Router history={history}>
          { envConfig.deployment._UnderMaintenence.is
            ? <div className='kp-container-fluid' style={{ textAlign: 'center' }}>
              <img src={envConfig.deployment.logo} alt={envConfig.deployment._DeploymentDisplayName} />
              <h1 className='h1 semibold'>New exciting updates are on their way!</h1>
              <h3 className='h3 semibold'>Come back here on 29th July, post 10AM IST. We shall be up and running!</h3>
            </div>
            : <Fragment>
              { showOkeDevTools && <Grid/> }
              { auth && auth.env === 'dev' && 
                <ButtonSecondary className='show-dev-tools-btn' onClick={() => setShowOkeDevTools(!showOkeDevTools)}>
                  Show Grid
                </ButtonSecondary> }
              <Announcement />
              <PrimaryHeader style={modalOverlay === true ? { zIndex: 0 } : { zIndex: 1000000 }} />
              <div className="kp-content">
                <Alert />
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact={true} path='/' component={Home} />
                    <Route exact={true} path='/login_registration' component={Login_Registration} />
                    <Route exact={true} path='/login_registration/:authType' component={Login_Registration} />
                    <Route exact={true} path='/subscribe' component={Subscribe} />
                    <PrivateRoute exact={true} path='/reset-password' component={ResetPwdPg} />
                    <AdminRoute exact={true} path='/admin-dashboard' component={AdminDashboard} />
                    <Route exact={true} path='/verifyEmail' component={VerifEmail} />
                    <Route exact={true} path='/verifyEmail/:token' component={VerifEmail} />
                    <Route exact={true} path='/verifyResetPasswordMail' component={VerifyResetPwd} />
                    <Route exact={true} path='/verifyResetPasswordMail/:token' component={VerifyResetPwd} />
                    <Route exact={true} path='/data' component={Data} />
                    <Route exact={true} path='/data/:drilldown1param' component={DataPATHDrilldown1} />
                    <Route exact={true} path='/data/:drilldown1param/:drilldown2param' component={DataPATHDrilldown2} />
                    <PrivateRoute exact={true} path='/mod-queue' component={ModQueue} />
                    <Route exact={true} path='/playground' component={Playground} />
                    <PrivateRoute exact={true} path='/media-uploader' component={MediaUploader} />
                    <Route exact={true} path='/grouped-published-listing/:groupKey' component={GroupedPublishedListing} />
                    <PrivateRoute exact={true} path='/published-listing/:contentType' apiAccessCheck={['GET_publisheds', ':contentType']} component={PublishedListing} /> 
                    <PrivateRoute exact={true} path='/published-page/:content_type' apiAccessCheck={['GET_contribution_view', ':content_type']} component={PublishedPage} />
                    <PrivateRoute exact={true} path='/batch-register' component={BatchRegister} />
                    <Route exact={true} path='/profile/:profileType/:id' component={ViewProfile} />
                    <Route exact={true} path='/community-listing/:profileType' component={UserProfilesListing} />
                    <Route exact={true} path='/recommendations-page/:recoObjectIds/:answerFilters' component={RecommendationsPage} />
                    <Route exact={true} path='/search' component={Search} />
                    <Route exact={true} path="/googleAuth/:token" component={GoogleAuth} />
                    <PrivateRoute exact={true} path='/direct-gen-tpl/:settings' component={DirectGenTpl} />
                    <PrivateRoute exact={true} path='/contribute-config' component={ContributeConfigure} />
                    <PrivateRoute exact={true} path='/edit/:content_type' component={ContributeTpl} />
                    <PrivateRoute exact={true} path='/moderate/:content_type' component={ContributeTpl} />
                    <PrivateRoute exact={true} path='/edit/:profileType/:id' component={EditProfile} />
                    <PrivateRoute exact={true} path='/synth/activities' component={ReSynthActivitiesListing} />
                    <PrivateRoute exact={true} path='/synth/activities/:interviewType' component={ReSynthActivitiesClustered} />
                    <PrivateRoute exact={true} path='/synth/activities/:interviewType/:subSectionIdx/:blockValuePath' component={ReSynthActivitiesClusteredResponses} />
                    <Route component={OKE404} />
                  </Switch>
                </Suspense>
              </div>
              <Footer/>
            </Fragment>}
        </Router> 
      </Fragment> }
    </Fragment>
  
  )
}

App.propTypes = {
  updateScreenWidth: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  envConfigLoading: state.environment.envConfigLoading,
  envConfig: state.environment.envConfig,
  modalOverlay: state.environment.modalOverlay,
  auth: state.auth
});
export default connect(mapStateToProps, { updateScreenWidth })(App);