import React, {Fragment, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import axios from 'axios';
import { KPTextInput } from '../KPInputs';
import KPLabel from '../../generalUI/KPLabel';
import { KPFileUploadBtn} from '../../generalUI/KPButtons';
import ContentBlockShell from '../../generalUI/ContentBlockShell';
import {replaceEntityData} from '../../../utils/richInputUtils'

const KPVideoEmbed = ({
  id,
  className,
  label,
  sublabel,
  isRequired,
  errorMsgs,
  
  value,
  onChange: parentOnChange,

  isInEditor,
  editor,
  entityKey,
  block,
  handleSetTempReadOnly,

  placeholder,
  readOnly,
  light : lightPlayer,
  youtubeUploadIsActive
}) => {

  const [url, setUrl] = useState(value || null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [googleAuth, setGoogleAuth] = useState(false);

  var uploadToken = (window.location.href).split( '&token=' );
  var videoFile = (window.location.href).split( '?video=' );

  const reactPlayerRootStyle = {
    
  }

  useEffect(()=> {
    if(uploadToken[1] && videoFile[1]){
      setGoogleAuth(true);
      startUpload();
    }
  },[])

  const startUpload = () => {
    var video = videoFile[1].replace('&token='+uploadToken[1], '');
    var formData = new FormData();
    formData.append('code', uploadToken[1]);
    formData.append('video', video);
    formData.append('videoTitle', 'Testing Video Title 123');       // Replace with content title variable
    formData.append('videoDesc', 'Testing Video Description 123');  // Replace with content description variable
    const res = axios.post('/api/youtube/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      setUrl(res.data);
    });
  }

  // const getYouTubeUploadToken = () => {
  //   if(googleAuth){
  //     return 'Uploading...'
  //   } else {
  //     return 'Paste YouTube video link or upload a video'
  //   }
    
  // }

  function updateURL( data ) {
    setUrl(data);
  }

  const onChange = e => {
    const file = Array.from(e.target.files)
    setUploading(true);
    var formData = new FormData();
    formData.append('video', file[0]);
    formData.append('callbackURL', window.location.href);
    var config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => setUploadProgress(Math.round( (progressEvent.loaded * 100) / progressEvent.total )) // Upload Progress
    };
    const res = axios.post('/api/youtube', formData, config)
    .then(res => {
      window.location = res.data; // redirect to Google Sign In for Video Upload
    });
    //setUrl(`http://youtube.com/watch?v=${res.data.id}`);
  }

  const onChangeUrl = v => {
    let canPlay = ReactPlayer.canPlay(v); // what is this doing...?? there was a prupose to this. maybe to check if vidoe is playable, and only thewn updating state. but it is clearly not being used right now.
    setUrl(v);
    parentOnChange && parentOnChange(id, v)
    if(isInEditor === true){
      replaceEntityData(editor, entityKey, {value: v })
    }
  }
  const createVideoPreview = () => {
    switch(true){
      case url === null || url.trim() === '': //no url has been entered or is an empty string
      return <h5 className='h5 sans-serif'>paste a url above to view preview of video</h5>;
      case ReactPlayer.canPlay(url) === false:
      return <h5 className='h5 sans-serif'>woops, something is wrong with the link you have pasted. we can't seem to play this video.</h5>
      default:
      return <ReactPlayer url={url} controls={true} width='100%' height='0' className='kp-video-embed__player' style={reactPlayerRootStyle} />;
    }
  }

  const inputBlock = () => (
    <Fragment>
      { youtubeUploadIsActive &&
        <Fragment>
       { googleAuth 
         ? '' 
         : <KPFileUploadBtn
            btnProps={{
              comp: 'ButtonPrimary',
              props: { icon: 'UploadSimple'  }
            }}
            onChange={onChange}
            multiple='false'
            id= {`${id}_resources_uploader` /*v.v.v.important that it has a unique id*/}
            accept={"videp/mp4"}
            readOnly={ uploading == false ? false : true }
            >
          { uploading == false ? 'Upload to YouTube' : 'Uploading  '+uploadProgress}
          </KPFileUploadBtn> }
        </Fragment> }
      <KPTextInput type='url' placeholder={placeholder} onChange={(k,v) => onChangeUrl(v)} value={url} />
      <div className='kp-video-embed__preview'>{createVideoPreview()}</div>
    </Fragment>
  )
  const displayBlock = () => (
    <div className='kp-video-embed__display'>
      <ReactPlayer
        className='kp-video-embed__player'
        url={value}
        controls={true}
        width='100%'
        height='0'
        style={reactPlayerRootStyle}
        light={lightPlayer}
        />
    </div>
  )
  const createBlock = () => {
    switch(true){
      case readOnly:
      return displayBlock();
      default:
      return inputBlock();
    }
  }
  return (
    <ContentBlockShell
      richInputProps = {{
        isInEditor,
        editor,
        block,
        handleSetTempReadOnly
      }}
      readOnly = { readOnly }
      className={`kp-video-embed ${className}`}
      id={id}
    >
      <KPLabel
        label={label}
        sublabel={sublabel}
        isRequired={isRequired}
        readOnly={readOnly}
        />
      {createBlock()}
      {/*getYouTubeUploadToken()*/}
    </ContentBlockShell>
  )
};
KPVideoEmbed.defaultProps = {
  youtubeUploadIsActive  : false
}
KPVideoEmbed.propTypes = {
}
export default KPVideoEmbed