import React, { Fragment, forwardRef, useState, useEffect, Component, useImperativeHandle } from 'react'
import axios from "axios";
import KPLabel from '../../generalUI/KPLabel';
import KPImageBlock from '../KPImageBlock'
import KPBgImageBlock from '../KPBgImageBlock'
import { setAlert } from '../../../actions/alert';
import store from '../../../store';
import { KPIconBtn, KPFileUploadBtn, ButtonTertiary } from '../../generalUI/KPButtons';
import Loader from '../../generalUI/Loader'
//icons
import UploadImage from '../../icons/UploadImage';
import { imageUploader } from '../../../utils/general'
import { getLabelProps } from '../../../utils/contentBlock'

import { darkPrimary, primaryColor100, primary100SL, primaryColor10, white } from '../../../sass/vars.scss';
//utils
import { pickRandomNum, convertToGcsStructure } from '../../../utils/general';


const KPImageInput = forwardRef((props, ref) => {

  /*
  * @NOTE
  *
  * right now this whole component has been built assuming the image array will only have one image. so a lot of update functions have been written simply, 
  * with this assumption in mind. 
  *
  * whenever we deal with multi image uploads through this component we will have to change that
  * 
  */

  const { onChange: parentOnChange } = props;

  const [uploading, setUploading] = useState(false);

  const initImages = [];
  const images = props.value
  ? convertToGcsStructure(props.value) //backward compat
  : initImages

  
  // const [images, setImages] = useState(
  //   props.value
  //     ? convertToGcsStructure(props.value) //backward compat
  //     : initImages
  // );

  // useEffect(() => {
  //   props.value && setImages(convertToGcsStructure(props.value)); //backward compat
  // }, [props.value])

  const onChange = e => {
    const files = Array.from(e.target.files)
    setUploading(true);

    const formData = new FormData()
    files.forEach((file, i) => { formData.append(i, file) })
    formData.set("folderName", props.folderName);
    formData.set("allowedFormats", props.allowedFormats);
    if(props.uploadBucketName){ // i think this is used only for our media-uploader page
      formData.set("uploadBucketName", props.uploadBucketName);
    }
    
    imageUploader(formData, { type: 'images' }, (status, resData) => {
      if (status === "success") {
  
        // const imagesStateObj = { imgData : resData, imgCropData: {x : 0, y: 0} }
        // setImages(resData);
        console.log('image data response', resData)
        parentOnChange && parentOnChange(props.id, [...images, ...resData]);
      }
      setUploading(false);
    });
  }

  const removeImage = idToRemove => {
    // setImages(initImages);
    parentOnChange && 
    parentOnChange(props.id, images.filter(img => img.id !== idToRemove));
  }

  const handleCropChange = (idx, thisImg, val) => {
    let newImgs = [...images]
    newImgs[idx] = {...thisImg, ...val};
    parentOnChange && parentOnChange(props.id, newImgs)
  }

  const content = () => {
    switch (true) {
      case uploading:
        return (
          <div
            className={`kp-image-input__placeholder-block ${props.containerShape}`}
            style={{ 
              paddingBottom: !props.height ? props.heightRatio : '0', 
              height: props.height, 
              width: props.width,
              ...(props.multiple ? {width: '100%'} : {})  
            }}
            >
            <div
              className='kp-image-input__placeholder-block-content'
              style={{ backgroundColor: !props.readOnly ? primaryColor10 : `hsl(${pickRandomNum(360)},${primary100SL})` }}
              >
              <div className='kp-image-loader-icon-wrapper'><Loader type='inline-loader' /></div>
            </div>
          </div>
        )

      case images.length > 0:
        
        return (
          <Fragment>
            { [...(props.multiple ? images : [images[0]])].map((img, i) => (
              <Fragment key={img && img.asset_id ? img.asset_id : i }>
                { props.imgBlockComp === 'KPImageBlock'
                  ? <KPImageBlock
                    id = {props.id}
                    image={img}
                    removeImage={removeImage}
                    containerShape={props.containerShape}
                    heightRatio={props.heightRatio}
                    multiple={props.multiple}
                    readOnly={props.readOnly}
                    enableCaptions={props.enableCaptions}
                    onChange={(val) => handleCropChange(i, img, val)}
                    defaultImageSpread={props.defaultImageSpread}
                    imageActions={props.imageActions}
                  />
                  : <KPBgImageBlock
                    image={img}
                    removeImage={removeImage}
                    height={props.height}
                    width={props.width}
                    containerShape={props.containerShape}
                    backgroundSize={props.backgroundSize}
                    readOnly={props.readOnly}
                  />}
              </Fragment>
            ))}
          </Fragment>
        )

      default:
        return (
          <div
            className={`kp-image-input__placeholder-block ${props.containerShape}`}
            style={{ 
              paddingBottom: !props.height ? props.heightRatio : '0', 
              height: props.height, 
              width: props.width,
              ...(props.multiple ? {width: '100%'} : {}) 
            }}
          >
            <div
              className='kp-image-input__placeholder-block-content'
              style={{
                /*backgroundColor : !props.readOnly ? primaryColor10 : `hsl(${pickRandomNum(360)},${primary100SL})`*/
                backgroundColor: props.placeholderColor
              }}
            >
              {props.readOnly &&
                <h6 className={`h3 light kp-image-block__placeholder-initial`}>{props.placeholderInitial}</h6>}
              {!props.readOnly &&
                <div
                  className={`kp-image-upload-btn-wrapper ${props.containerShape}`}
                // onClick={() => document.getElementById(`${props.id}_image_upload_btn`).click()}
                >

                  <KPFileUploadBtn
                    label={<KPLabel {...getLabelProps(props)}/>}
                    onChange={onChange}
                    multiple={props.multiple}
                    btnProps={{ comp: 'ButtonGhost', props: { icon: "UploadImage"  } }}
                    id={`${props.id}_image_upload_btn` /*v.v.v.important that it has a unique id*/}
                    >
                    {/* <UploadImage/> */}
                  </KPFileUploadBtn>
                </div>}
            </div>
          </div>
        );
    }
  }

  const [visibleImgIdx, setVisibleImgIdx] = useState(0);

  const genImagesThumbnails = () => (
    <div
      className='ImageInput__imgThumbnails'
      >
    { images.map((img,i) => (
      <Fragment>
      <div 
        key={img.id}
        className='ImageInput__imgThumbnail' 
        style={{
          backgroundImage: `url("${img.publicUrl}")`,
          border: visibleImgIdx === i ? `2px solid ${primaryColor100}` : 'none'
        }}
        onClick={() => setVisibleImgIdx(i)}
        >
      </div>
      { !props.readOnly && i === images.length -1 && 
        <div className='ImageInput__imgThumbnail' style={{backgroundColor: white}}>
          <KPFileUploadBtn
            onChange={onChange}
            multiple={props.multiple}
            btnProps={{ comp: 'ButtonTertiary', props: { icon: "UploadImage"  } }}
            id={`${props.id}_image_upload_btn` /*v.v.v.important that it has a unique id*/}
            >
            {/* <UploadImage/> */}
          </KPFileUploadBtn>
        </div>}
        </Fragment>
    ))}
    </div>
  )

  return (
    <Fragment>
      <div
        id={props.id}
        className={`kp-image-input ${props.className}`}
        style={{
          ...(props.multiple ? {overflow: 'hidden'} : {})
        }}
        >
      { props.multiple
        ? <Fragment>
            <div 
              className='ImageInput__content-flexWrapper'
              style={{ 
                display: 'flex',
                transform: `translateX(${-(visibleImgIdx * 100)}%)`
              }}
              >
            { content() }
            </div>
            { images.length > 0 && genImagesThumbnails() }
          </Fragment>
        : content() }
      </div>
      
    </Fragment>
  )
})

KPImageInput.defaultProps = {
  id: "kp-image-uploader", //required
  label: "Upload an image", //optional
  multiple: false, //required
  className: "", //optional
  folderName: `images`, //required
  allowedFormats: ["jpeg", "jpg", "png"], //required
  readOnly: false,
  heightRatio: '100%',
  imgBlockComp: 'KPImageBlock',
  containerShape: 'shape-square'
}

export default KPImageInput;
