import React, { useState, useRef, useEffect, Fragment } from 'react'
import { KPIconBtn, ButtonSecondary } from '../../generalUI/KPButtons';
import Delete from '../../icons/Delete';
import imgLoadingSVG from '../../../images/imgLoading.svg';
import { primaryColor10, white, greyColor10, none } from '../../../sass/vars.scss';
import Loader from '../../generalUI/Loader';
import Label__M from '../../typo/Label__M';
import KPRichInput from '../../inputs/KPRichInput';
import { checkIfRichInputIsPopulated } from '../../../utils/general';

const KPImageBlock = (props) => {
  //#1 : should pull screen width from redux in here, then the image will adjust itself to fit container whenever screen size changes.
  //#2 : need to calc max translate in cases where, vertical img has been translated to some -x, and now is in a even more vetical container, so we have an empty gap at the bottom of the container. we need to recalculate a max translate to fix this using : 100 - ( rendered img height / curr. container height * 100) —> max translateY
  let imageActions = props.imageActions || ['*'] //defaults to all image actions.
  // just to note, delete action and later, edit action will always be available by default without depending on this variable

  let { width, height, publicUrl, id: imgId, caption } = props.image && props.image;

  const [imgWidth, setImgWidth] = useState(width);
  const [imgHeight, setImgHeight] = useState(height);
  const [imgLazyLoaded, setImageLazyLoaded] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (publicUrl) {
      const img = new Image();
      img.src = publicUrl;
      img.onload = function () {
        setTimeout(() => {
          setImageLazyLoaded(true);
        }, 1000)
      }
    } else {
      setImageLazyLoaded(true)
    }
  }, [publicUrl])

  const [imageSpread, setImageSpread] = useState(props.image.imageSpread || props.defaultImageSpread || 'cover'); /* alt: contain */
  const [imageRotate, setImageRotate] = useState(props.image.imageRotate || 0); /* alt: contain */
  const { cropX, cropY } = props.image;

  const [repositionDirection, setRepositionDirection] = useState('horizontal');

  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [initialX, setInitialX] = useState(null);
  const [xOffset, setXOffset] = useState(0);
  const [imgPosX, setImgPosX] = useState(cropX ? cropX : 0);

  const [initialY, setInitialY] = useState(null);
  const [yOffset, setYOffset] = useState(0);
  const [imgPosY, setImgPosY] = useState(cropY ? cropY : 0);

  useEffect(() => {
    props.image.cropX ? setImgPosX(cropX) : setImgPosX(0);
    props.image.cropY ? setImgPosY(cropY) : setImgPosY(0);
  }, [props.image])

  useEffect(() => {
    //wrapperRef is set only after page is rendered, hence we need these lines in here.
    const wrapperRatio = wrapperRef.current && wrapperRef.current.clientHeight / wrapperRef.current.clientWidth;
    const imgRatio = imgHeight / imgWidth;

    if (imgRatio > wrapperRatio) {
      setImgPosX(0); setRepositionDirection("vertical");
    } else {
      setImgPosY(0); setRepositionDirection("horizontal");
    }
    setRepositionDirection(imgRatio > wrapperRatio ? "vertical" : "horizontal");
  })

  const onMouseDown = e => {
    setMouseIsDown(true);
    let clientX = e.touches ? e.touches[0].clientX : e.clientX
    let clientY = e.touches ? e.touches[0].clientY : e.clientY
    repositionDirection === 'horizontal' ? setInitialX(clientX - xOffset) : setInitialY(clientY - yOffset)
    document.getElementsByTagName("html")[0].setAttribute("style", "overflow: hidden");
  };

  const onMouseUp = () => {
    setMouseIsDown(false);
    props.onChange({ cropX: imgPosX, cropY: imgPosY });
    document.getElementsByTagName("html")[0].removeAttribute("style");
  }

  const onMouseOver = () => setMouseIsDown(false)

  const repositionVertical = (e) => {
    if (mouseIsDown === true) {
      let clientY = e.touches ? e.touches[0].clientY : e.clientY
      let currentY = clientY - initialY;
      let renderedImgHeight = (imgHeight * wrapperRef.current.clientWidth) / imgWidth;  //actual rendered height of image
      let wrapperHeight = wrapperRef.current.clientHeight;
      if (currentY <= 0 && currentY >= - (renderedImgHeight - wrapperHeight)) {
        setYOffset(currentY);
        setImgPosY((currentY / renderedImgHeight) * 100); //finally set the y-translate in percentage so that it works in different container sizes
      }
    }
  }

  const repositionHorizontal = (e) => {
    if (mouseIsDown === true) {
      let clientX = e.touches ? e.touches[0].clientX : e.clientX
      let currentX = clientX - initialX;
      let renderedImgWidth = (imgWidth * wrapperRef.current.clientHeight) / imgHeight;  //actual rendered width of image
      let wrapperWidth = wrapperRef.current.clientWidth;
      if (currentX <= 0 && currentX >= - (renderedImgWidth - wrapperWidth)) {
        setXOffset(currentX);
        setImgPosX((currentX / renderedImgWidth) * 100); //finally set the x-translate in percentage so that it works in different container sizes
      }
    }
  }

  const handleSwitchCoverContain = () => {
    if (imageSpread === 'cover') {
      setImageSpread('contain');
      props.onChange({ cropX: 0, cropY: 0, imageSpread: 'contain' });
    } else {
      setImageSpread('cover');
      props.onChange({ imageSpread: 'cover' })
    }
  }

  const handleRotate = () => {
    let newRotate = imageRotate + 180;
    setImageRotate(newRotate);
     //reset cropping
    setImgPosX(0);
    setImgPosY(0)
    props.onChange({ imageRotate: newRotate, cropX: 0, cropY: 0 })
  }

  const genEditImgActions = () => (
    <div className='ImageBlock__editImageActions' style={{ display: 'flex', margin: '0 -0.5rem' }}>
      <div style={{ padding: '0 0.5rem' }}>
      { (imageActions.includes('*') || imageActions.includes('contain')) &&
        <ButtonSecondary
          icon={imageSpread === 'cover' ? 'ArrowsIn' : 'ArrowsOut' }
          onClick={() => handleSwitchCoverContain()}
        /> }
      </div>
      <div style={{ padding: '0 0.5rem' }}>
      { (imageActions.includes('*') || imageActions.includes('rotate')) &&
        <ButtonSecondary onClick={() => handleRotate()} icon='ArrowClockwise'/> }
      </div>
      <div style={{ padding: '0 0.5rem' }}>
        <ButtonSecondary
          onClick={() => props.removeImage(imgId)}
          // type="danger"
          // className='kp-delete-cover-img-btn'
          icon="Trash"
        >
          {/* <Delete stroke={white} /> */}
        </ButtonSecondary>
      </div>
    </div>
  )

  const genImgLayoutGrid_and_EventsDetector = () => (
    <Fragment>
      <div className='kp-img-layout-grid'>
        <div className='hor-grid'></div>
        <div className='ver-grid'></div>
      </div>
      <div
        style={{ top: '0', cursor: 'move' }}
        className="kp-image-block__mouse-event-detector"
        onMouseDown={(e) => onMouseDown(e)}
        onTouchStart={(e) => {
          onMouseDown(e)
        }}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
        onMouseOver={onMouseOver}
        onMouseMove={(e) => repositionDirection === "vertical" ? repositionVertical(e) : repositionHorizontal(e)}
        onTouchMove={(e) => {
          repositionDirection === "vertical" ? repositionVertical(e) : repositionHorizontal(e)
        }}
      >
      </div>
    </Fragment>
  )

  const handleSetCaption = (caption) => {
    props.onChange({ caption });
  }

  const genCaptionComp = () => (
    <div className={`ImageBlock__caption ${props.readOnly ? '-read-only-' : ''}`}>
      <KPRichInput
        placeholder='write caption here'
        value={caption}
        onChange={(k, v) => handleSetCaption(v)}
        readOnly={props.readOnly}
        richFormattable={false}
      />
    </div>
  )

  const genImgTranslateString = (imageSpread, imgPosX, imgPosY) => {
    return imageSpread === 'cover'
    ? `translate(${imgPosX}%, ${imgPosY}%)`
    : repositionDirection === 'horizontal' ? `translateY(-50%)` : `translateX(-50%)`
  }

  return (
    <div 
      className='ImageBlock' 
      style={{
        position: 'relative',
        ...(props.multiple ? { width: '100%', flexShrink: 0 } : {})
      }}
    >
      { !props.readOnly && genEditImgActions() /* this needs to be outside for visibility purposes in the case of containerShape = round*/}
      { props.enableCaptions && (!props.readOnly || (props.readOnly && checkIfRichInputIsPopulated(caption))) &&
        genCaptionComp()}
       <div
          ref={wrapperRef}
          className={`kp-image-block ${props.containerShape} ${props.className ? props.className : ""}`}
          style={{
            paddingBottom: props.heightRatio,
            backgroundColor: publicUrl //no bg color if image url is available
                              ? none 
                              : props.placeholderStyle ? props.placeholderStyle.backgroundColor : primaryColor10
          }}
        >
          { publicUrl && imgLazyLoaded
            ? <img
              className='kp-image-block__img'
              src={publicUrl}
              loading="lazy"
              style={{
                width: imageSpread === 'cover' //means we are in crop mode
                  ? repositionDirection === "vertical" ? '100%' : 'auto'
                  : repositionDirection === "vertical" ? 'auto' : '100%',
                height: imageSpread === 'cover' //means we are in crop mode
                  ? repositionDirection === "horizontal" ? '100%' : 'auto'
                  : repositionDirection === "horizontal" ? 'auto' : '100%',
                transform: `${genImgTranslateString(imageSpread, imgPosX, imgPosY)} rotate(${imageRotate}deg)`,
                ...(imageSpread === 'contain'
                  ? (repositionDirection === 'horizontal' ? { top: '50%' } : { left: '50%' })
                  : {})

              }}
            />
            : <Label__M 
                style={{
                  color : white,
                  fontSize : props.placeholderInitialSize ? props.placeholderInitialSize+'px' : undefined,
                  fontWeight: 500,
                  letterSpacing: 0
                  }}
                className='ImageBlock__placeholderInitial'
                >
                {props.placeholderInitial}
              </Label__M> }
          {!props.readOnly && imageSpread === 'cover' && genImgLayoutGrid_and_EventsDetector()}
        </div>


    </div>
  )
}

KPImageBlock.defaultProps = {
  heightRatio: '100%',
  containerShape: 'shape-square'
}

export default KPImageBlock;
