
import React, {Fragment, useEffect, useState} from "react";
import {useSelector, useDispatch, connect} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FullScreenMenuHeader from '../FullScreenMenuHeader';
import { ButtonGhost, ButtonPrimary } from '../../generalUI/KPButtons'
import KPLink from '../../generalUI/KPLink';
import PageContainer from '../../generalUI/PageContainer';
//icons

import Label__M from '../../typo/Label__M';
import {getPublishedListing} from '../../../actions/listings';

//config
import {
  _KnowledgeMenuOptions,
  __GetStaticPageLinkData,
  __GenNavLink,
  _HeaderSubMenuOptions,
  genKnowledgeListingOption,

} from '../../../utils/nav';

const HamburgerMenu = (props) => {

  const { 
    deployment : {
      _ContributeCTAText
    },
    _Nav 
  } = useSelector((state) => { 
    return state.environment.envConfig;
  }); 

  const {listings} = props; 

  const genKnowledgeMenuOptions = () => {
    return (
      Array.isArray(_Nav.secondary)
      ? _Nav.secondary.map(d => genKnowledgeListingOption(d.knowledgeListing, d.display))
      : _KnowledgeMenuOptions()
    )
  }
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublishedListing('staticPages', 'long', listings))
  },[])

  let history = useHistory();
  let location = useLocation();
  
  // Manage Scroll action in Hamburger Menu
  useEffect(() => {
    props.hamburgerOpen === true ? document.body.classList.add('kp-hamburger-menu__body--noScroll') : document.body.classList.remove('kp-hamburger-menu__body--noScroll');
  });

  const handleContributeCLick = () => {
    props.setHamburgerOpen(false);
    history.push('/contribute-config');
  }

  const linkSectionWrapper = (comp, className='') => (
    <div className={`HamburgerMenu__linkSection ${className}`}>{comp}</div>
  )

  return (
    <div className={`kp-hamburger-menu ${props.hamburgerOpen === true ? "open" : "closed"}`}>
      <FullScreenMenuHeader
        className='kp-hamburger-menu__header'
        leftCompContent={
          <Fragment>
            <ButtonGhost
              invert={true}
              icon="X"
              onClick={() => { props.setHamburgerOpen(false) }}>
            </ButtonGhost> 
          </Fragment>
        }
      />
      <PageContainer className='HamburgerMenu__body-container'>
        {linkSectionWrapper(
          <ButtonPrimary
            invert={true} 
            onClick={() => handleContributeCLick()}
            >
            {_ContributeCTAText || 'Contribute'}
          </ButtonPrimary>,
          'HamburgerMenu__publishBtnSection'
        )}
        { _Nav.secondary && 
              <Fragment>
              { linkSectionWrapper(
                <Fragment>
                  <Label__M className='HamburgerMenu__linkSectionTitle'>{'knowledge'}</Label__M>
                  {genKnowledgeMenuOptions().map(op => {
                    return __GenNavLink(op.value, op.display, location, op.loading, '', () => props.setHamburgerOpen(false))
                  }) }
                </Fragment>) }
              </Fragment> }
      { _Nav.primary.map(config => {
          if(config.staticPage) {
            let {value, display, loading} =  __GetStaticPageLinkData(config.staticPage, listings);
            return linkSectionWrapper(__GenNavLink(value, display, location, loading, '', () => props.setHamburgerOpen(false))); 
          } else
          if(config.route) {            
            return linkSectionWrapper(__GenNavLink(config.route, config.display, location, null, '', () => props.setHamburgerOpen(false))); 
          } else
          if(config.subMenu){
            let options;
            if(typeof config.subMenu === 'string'){
              options = _HeaderSubMenuOptions()[config.subMenu];
            }else{
              options = [];
              config.subMenu.map(d => {
                if(typeof d === 'string'){ options = [...options, ..._HeaderSubMenuOptions()[d]] } else
                if(d.knowledgeListing){ options.push(genKnowledgeListingOption(d.knowledgeListing, d.display)) } else
                if(d.staticPage){ options.push(__GetStaticPageLinkData(d.staticPage, listings)) }
              })
            }
            return linkSectionWrapper(
              <Fragment>
                {config.display && 
                <Label__M className='HamburgerMenu__linkSectionTitle'>{config.display}</Label__M>}
                {options.map(op => {
                  return __GenNavLink(op.value, op.display, location, null, '', () => props.setHamburgerOpen(false));
                })}
              </Fragment>
            )
          }
        })}
      </PageContainer>
    </div>
  )
};

HamburgerMenu.defaultProps = {
  className: ""
}

const mapStateToProps = state => ({
  listings : state.listings
})

export default connect(mapStateToProps, { })(HamburgerMenu);


