import React from "react"
import { primaryColor100 } from '../../../sass/vars.scss';

const Close = (props) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.376 3.455a.713.713 0 011.007 0l11.422 11.421a.712.712 0 11-1.008 1.008L3.376 4.463a.712.712 0 010-1.008z"
        fill={props.stroke || primaryColor100 }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.376 15.884a.712.712 0 010-1.008L14.797 3.455a.712.712 0 111.008 1.007L4.384 15.884a.713.713 0 01-1.008 0z"
        fill={props.stroke || primaryColor100 }
      />
    </svg>
  )
}

export default Close
