import {
    SET_OVERVIEW_LOADING,
    SET_OVERVIEW_DATA,
    SET_OVERVIEW_ERROR,
    SET_OVERVIEW_ACTIVE_VIEW,
    GET_OVERVIEW_DATA_FROM_STORE,

    SET_SPOTLIGHT_DATA_LOADING,
    GET_SPOTLIGHT_DATA_FROM_STORE,
    SET_SPOTLIGHT_DATA,
    SET_SPOTLIGHT_DATA_ERROR,
    SET_SPOTLIGHT_ACTIVE_VIEW,
    SET_SPOTLIGHT_ACTIVE_DATAPOINTS,
    UPDATE_SPOTLIGHT_DATA,

    SET_DISTRICTS_DATA_LOADING,
    GET_DISTRICTS_DATA_FROM_STORE,
    SET_DISTRICTS_DATA,
    SET_DISTRICTS_DATA_ERROR,
    SET_DISTRICTS_ACTIVE_DATAPOINT,

    UPDATE_DATAPOINTS,

    UPDATE_PRIMARY_ACTIVE_VIEW,
    UPDATE_DATE_SETTING
   
    

} from '../actions/types';

import { datePresets } from '../pageTpls/DataPATH/config';

const initialState = {
    primaryViews: [],
    primaryActiveView: undefined,
    datePresets: datePresets,
    activeDatePreset: datePresets[0],
    dateRange: undefined,
    overview: {
        data: undefined,
        initData: undefined,
        views: [],
        activeView: undefined,
        loading: true,
        errors: undefined
    },
    districts: {
        data: undefined,
        initData: undefined,
        dataPoints: [],
        activeDataPoint: [], //2 values [0] is for first map, [1] is for second map
        loading: true,
        errors: undefined
    },
    spotlight: {
        data: undefined,
        initData: undefined,
        views: [],
        activeView: undefined,
        dataPoints: [],
        activeDataPoints: [],
        dataPoints2: [],
        activeDataPoints2: [],
        positivityRateConfig: [],
        activePositivityRateConfig : [],
        loading: true,
        errors: null
    }
};

export default function (state = initialState, action) {
    const { type, payload } = action

    const stateObj = type.includes('OVERVIEW') 
                        ? 'overview' 
                        : type.includes('SPOTLIGHT')
                          ? 'spotlight'
                          : type.includes('DISTRICTS')
                          && 'districts'

    switch (type) {

        //common

        case SET_OVERVIEW_LOADING:
        case SET_SPOTLIGHT_DATA_LOADING:
        case SET_DISTRICTS_DATA_LOADING:
            return {
                ...state,
                [stateObj]: {
                    ...state[stateObj],
                    loading: payload
                }
            };
        
        case SET_OVERVIEW_ERROR:
        case SET_SPOTLIGHT_DATA_ERROR:
        case SET_DISTRICTS_DATA_ERROR:
            return {
                ...state,
                [stateObj]: {
                    ...state[stateObj],
                    errors : payload,
                    loading : false
                }
            };

        case GET_OVERVIEW_DATA_FROM_STORE:
        case GET_SPOTLIGHT_DATA_FROM_STORE:
        case GET_DISTRICTS_DATA_FROM_STORE:
            return {
                ...state,
                [stateObj]: {
                    ...state[stateObj],
                    loading: false
                }
            };

        //primary view

        case UPDATE_PRIMARY_ACTIVE_VIEW:
            return {
                ...state,
                primaryActiveView: payload
            }
        
        //date setting

        case UPDATE_DATE_SETTING:
            return {
                ...state,
                ...payload
            }

        //overview

        case SET_OVERVIEW_DATA:
            return {
                ...state,
                overview: {
                    ...state.overview,
                    initData : payload.initData,
                    data : payload.data,
                    views : payload.views,
                    activeView : payload.activeView,
                    loading: false
                }
            };

        case SET_OVERVIEW_ACTIVE_VIEW:
            return {
                ...state,
                overview : {
                    ...state.overview,
                    activeView: payload
                }
            }

        
        //spotlight

        case SET_SPOTLIGHT_DATA:
            return {
                ...state,
                primaryViews : createPrimaryViews(payload.views),
                primaryActiveView: {display: 'All States', value: '/'},
                spotlight: {
                    ...state.spotlight,
                    shape : payload.shape,
                    initData : payload.initData,
                    data : payload.data,
                    plotTypes : payload.plotTypes,
                    sevenDayMovingAvgToggle : payload.sevenDayMovingAvgToggle,
                    sevenDayActive : false,
                    activePlotType : payload.activePlotType,
                    views : payload.views,
                    activeView : payload.activeView,
                    dataPoints : payload.dataPoints,
                    activeDataPoints : payload.activeDataPoints,
                    dataPoints2 : payload.dataPoints2,
                    activeDataPoints2 : payload.activeDataPoints2,
                    positivityRateConfig : payload.positivityRateConfig,
                    activePositivityRateConfig : payload.activePositivityRateConfig,
                    loading: false
                }
            };

        case UPDATE_SPOTLIGHT_DATA:
            return {
                ...state,
                spotlight: {
                    ...state.spotlight,
                    data: payload
                }
            }
        
        case UPDATE_DATAPOINTS:
            return {
                ...state,
                spotlight: {
                    ...state.spotlight,
                    ...payload
                }
            }

        case SET_SPOTLIGHT_ACTIVE_VIEW:
            return {
                ...state,
                spotlight : {
                    ...state.spotlight,
                    activeView: payload
                }
            }
        

        case SET_SPOTLIGHT_ACTIVE_DATAPOINTS:
            return {
                ...state,
                spotlight : {
                    ...state.spotlight,
                    ...payload
                }
            }
        
        //districts

        case SET_DISTRICTS_DATA:
            let dataPoints = insertValuePropIntoDataPoints(
                payload.dataPoints
            ).map(d => ({...d, display: d.mapDisplay}));
            let primaryViews = state.primaryViews.length === 0 
                ? createPrimaryViews(payload.views)
                : state.primaryViews ;
            let primaryActiveView = primaryViews.filter(v => v.id === payload.thisDistrictId)[0]
            return {
                ...state,
                primaryViews,
                primaryActiveView, 
                districts: {
                    ...state.districts,
                    initData : payload.initData,
                    data : payload.data,
                    dataPoints,
                    activeDataPoint : [dataPoints[0], dataPoints[1]],
                    loading: false
                }
            }
        
        case SET_DISTRICTS_ACTIVE_DATAPOINT:
            return {
                ...state,
                districts: {
                    ...state.districts,
                    activeDataPoint : handleSetActiveDistrictsDataPoint(
                        state.districts.activeDataPoint,
                        payload.value,
                        payload.mapIdx
                    )
                }
            }
        
        default:
            return state;
    }
}


const insertValuePropIntoDataPoints = (dataPoints) => {
    return dataPoints.map(d => ({...d, value: d.id}));
}

const handleSetActiveDistrictsDataPoint = (currActive, value, mapIdx) => {
    let newActive = [...currActive];
    newActive[mapIdx] = value;
    return newActive;
}


const createPrimaryViews = (payloadViews) => {
    return [{display: 'All States', value: '/'}, ...payloadViews.map(v => ({...v, value: '/'+v.id}))]
}

