import * as React from "react"
import { Bell } from 'phosphor-react';
import {red, white, greyColor100} from '../../../../sass/vars.scss';


const OkeBell = (props) => {

  let notifNumStyle = {
    position: 'absolute',
    top: '0.4rem',
    right: '1rem',
    backgroundColor: red,
    padding: '0 0.5rem',
    borderRadius: '0.5rem',
    color: white,
    transform: 'translate(100%,-50%)'
  }

  return (
    <div style={{position: 'relative'}}>
    { props.number > 0 && 
      <h5 className='h5 medium' style={notifNumStyle}>
        {props.number}
      </h5> }
      <Bell {...props} />
    </div>
  )
}

export default OkeBell
