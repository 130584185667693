import React, { useState, Fragment } from "react";

import { white, red, yellow, green, black, darkGrey, lightYellow } from '../../../sass/vars.scss';

const RadioInputElement = ({ isSelected }) => {

  return (
    <button
      className={`radioInputElement ${isSelected ? 'selected' : '' }`}
      >
    { isSelected &&
      <div className='radioInputElement__fill'></div> }
    </button>
  );
}

export default RadioInputElement
