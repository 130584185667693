import React, { useState, Fragment, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import history from "../../../history";
//comps
import MetaBlock from "../../generalUI/MetaBlock";
import MetaBlock__Toolkits from "../../generalUI/MetaBlock__Toolkits";
import CardTag from "../CardTag";
import { KPIconBtn, ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../generalUI/KPButtons";

import KPLabel from "../../generalUI/KPLabel";
import KPImageInput from "../../inputs/KPImageInput";
import KPVideoEmbed from '../../inputs/KPVideoEmbed';
import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import KPMultiProfilesDisplay from '../../generalUI/KPMultiProfilesDisplay';
import KPMetaPrimary from "../../generalUI/KPMetaPrimary";
import KPMetaSecondary from "../../generalUI/KPMetaSecondary";
import KPLink from '../../generalUI/KPLink';
import KPMetaHero from "../../generalUI/KPMetaHero";
import ExternalLinkCard from "../ExternalLinkCard";
import KPInlineAlert from '../../generalUI/KPInlineAlert';
//icons
import Delete from "../../icons/Delete";
import Close_S from "../../icons/Close_S";
import Edit from "../../icons/Edit";
import KPTagsInput from "../../inputs/KPTagsInput";
import Download__nofill from '../../icons/Download__nofill';
import LinkOutSM from '../../icons/LinkOutSM';
import {StatusTagOutlineStyle} from '../../generalUI/StatusTag';
//sass
import { white } from "../../../sass/vars.scss";
import store from "../../../store";
//actions
import { deleteDraft } from "../../../actions/profiles";
import CardTitle from "../../typo/CardTitle";
import BodySerif__L from "../../typo/BodySerif__L";

import {mediaQuery} from '../../../utils/general';



const ListElemContent = ({
  colSetting,
  className,
  link,
  image, // of the format {backgroundImage : x, backgroundPosition : y}
  videoEmbed,
  imgContainerStyle,
  listElemLabel,
  title,
  metaBlockData, //of the format [{icon: xComp, text: y},...]
  resourceLink,
  metaHero,
  actions,
  tags,
  multiProfilesDisplayData,
  cardLink,
  style,
  readOnly,
  statusTag,
  onRemoveCard,
  customClickHandler
}) => {

  const MetaBlockComps = {
    MetaBlock,
    MetaBlock__Toolkits,
  };

  
  
  const { screenWidth } = useSelector(state => state.environment);

  const MetaBlockComp = metaBlockData && MetaBlockComps[metaBlockData.comp];

  
  const cardActions = [ ...(resourceLink ? [resourceLink] : []), ...(actions ? actions : [])];
  const cardActionsRef = useRef(null);

  //check to see if click hasnt been on an action within the card. in which case, follow the card link.
  const handleCardClick = (e) => {
    if (!cardActionsRef.current || !cardActionsRef.current.contains(e.target)) {
      console.log({customClickHandler, link})
      customClickHandler
      ? customClickHandler()
      : link.type === "internal"
        ? history.push(link.url)
        : window.open(link.url, "_blank");
      
    }
  };

  const genCardActions = () => (
    <div ref={cardActionsRef} className='ListEl__actions'>
    { cardActions.map(action => (
      <ButtonSecondary
        onClick={
          action.onClick ||
          (() => window.open(action.value || action.link, '_blank'))
        }
        className='ListEl__action'
        icon={action.type
          ? action.type === 'uploaded' 
            ? "DownloadSimple" 
            : "ArrowLineUpRight"
          : action.icon || undefined
        }
        children={ mediaQuery('sm', screenWidth,) ? (action.display || undefined) : undefined}
      /> )) }
    </div>
  )

  const genRmCardBtn = (onRemoveCard) => (
    <ButtonPrimary
      // className='kp-content-card-wrapper__remove-btn'
      // type='danger'
      // size='small'
      onClick={onRemoveCard}
      icon="Close_S"
    >
      {/* <Close_S fill={white} /> */}
    </ButtonPrimary>
  )

  const statusTypeMap = {
    neutral : 'neutral',
    success : 'success',
    danger : 'danger'
  }

  return (
    <div className={`${colSetting} ${className} ListEl-Wrapper--Content`} style={style}>
      <div className='ListEl--Content' onClick={ handleCardClick }>
        
        <div className='ListEl__contentModule-1'>
        { (listElemLabel || statusTag) &&
          <div className={`ListEl__label-wrapper ${image || videoEmbed ? '-has-media-thumb-' : ''}`}>
          { listElemLabel && //we apply these wrapper divs on the elements below, cuz we have to apply padding to them, and we dont want to do this directly to the components inner style. this sort of stuff creates all sorts of conflicts later
            <div className="ListEl__label"> 
              <KPMetaPrimary data={ listElemLabel } />
            </div>}
          { statusTag && (
            Array.isArray(statusTag)
            ? statusTag.map(tag => (
              <div className="ListEl__label">
                <StatusTagOutlineStyle 
                  value = {tag}
                  statusTypeMap = {statusTypeMap}
                  /> 
              </div> ))
            : <div className="ListEl__label">
                <StatusTagOutlineStyle 
                  value = {statusTag}
                  statusTypeMap = {statusTypeMap}
                  /> 
              </div> )
             }
          </div> }
        { metaHero && 
          <KPMetaHero className='ListEl__date' data={ metaHero } /> }
          <BodySerif__L className={`ListEl__title ${image || videoEmbed ? '-has-media-thumb-' : ''}`}><span className='ListEl__title-span'>{ title }</span></BodySerif__L>
          {metaBlockData && (
            <MetaBlockComp
              className="ListEl__metaBlock"
              data={metaBlockData.data}
            />)}
        { cardActions.length > 0 &&
          genCardActions() }
        </div>

        <div className='ListEl__contentModule-2'>

          {videoEmbed &&
            <div className="ListEl__video-container">
              <KPVideoEmbed
                value={videoEmbed.value}
                readOnly={true}
                light={true}
              />
            </div>}
          {image &&
            <div className="ListEl__image-container" style={imgContainerStyle}>
              <KPImageInput
                value={image.data}
                heightRatio={image.heightRatio && image.heightRatio}
                placeholderColor={image.placeholderColor}
                containerShape={image.containerShape}
                height={image.height && image.height}
                width={image.width && image.width}
                imgBlockComp={image.imgBlockComp}
                backgroundSize={image.backgroundSize && image.backgroundSize}
                placeholderInitial={image.placeholderInitial}
                readOnly={true}
              />
            </div>}

        </div>
      </div>
    </div>

  );
};



export default ListElemContent;
