import React, { useState, Fragment } from "react";
import { set_getValOffQueryString as s_gVal } from '../../../utils/general';

import ProfileImgDisplay from '../../generalUI/ProfileImgDisplay';
import KPLink from '../../generalUI/KPLink';
import KPLabel from '../../generalUI/KPLabel';
import { greyColor70 } from '../../../sass/vars.scss';
import { propertyExists } from '../../../utils/general';

const KPMultiProfilesDisplay = ({
  className,
  label,
  textStyle,
  noOfNamesToDisplay,
  andXOthersText,
  value,
  imgValPath,
  nameValPath, //can be an array with at most 2 values, in case we want to give an either or option for name value path.
  linkPath, // { id: <some_path>, resType: <some_path> }
  profileType //only needed if linkIdPath has also been passed.

}) => {

  value = value.filter(d => d && ( d.resId === undefined || d.resId !== null)); //basically if there is no resId at all (undefined), then keep the obj, or if there is a resId then it better have a value, so only if it is not null, then keep it.

  let totNumber = value.length;

  const gVal_fromPathAry = (parentValue, valPath) => { //used if multiple possible value paths may or may not have been provided. if multiple are provided, they are provided in the form of an array. (at most 2)
    return ( Array.isArray(valPath)
             ? ( s_gVal('get', parentValue, valPath[0])
                 ? s_gVal('get', parentValue, valPath[0])
                 : s_gVal('get', parentValue, valPath[1]) )
             : s_gVal('get', parentValue, valPath) )
  }

  // let idx = value.findIndex(d => gVal_fromPathAry(d, imgValPath) && gVal_fromPathAry(d, imgValPath).imgData[0]);
  // if(idx >= 0){
  //   let valToShift = value[idx];
  //   value.splice(idx, 1);
  //   value.unshift(valToShift)
  // }


  const mapToACount = count => {
    let ary = [];
    for(var i = 0; i < count; i++){
      ary.push('blank');
    }
    return ary;
  }

  const genContent = () => (
    <Fragment>
    { mapToACount(noOfNamesToDisplay).map((d, i) =>{
      let separatorStyle = i < (noOfNamesToDisplay-1) ? {paddingRight: '0.5rem'} : {paddingLeft: '0.5rem'};
      let nameVal = value[i] && gVal_fromPathAry(value[i], nameValPath)

      let linkIdVal = value[i] && s_gVal('get', value[i], linkPath.id);
      let linkResVal = value[i] && s_gVal('get', value[i], linkPath.resType);

      let othersText = [
        (andXOthersText && andXOthersText[0]) || 'and',
        (andXOthersText && andXOthersText[1]) || 'others',
      ]

      return (
        <Fragment>
        { linkIdVal
          ? <KPLink
               //this is specifically meant to cancel out the ellipsis css written for the wg and sn cards in the wg_sn_card_style scss.}
              style={{padding: 0, maxWidth: (totNumber - noOfNamesToDisplay) === 0 && '100%'}}
              textStyle={textStyle}
              text={nameVal}
              linkType='internal'
              link={`/profile/${linkResVal}/${linkIdVal}` /* the d.meta && is a strange hack that i dont understand. to avoid the link fuckup that happens when in edit mode. */}
              />
            : <h4 className={textStyle}>{nameVal}</h4> }
          <h4 className={textStyle} style={separatorStyle}>
            { i < (noOfNamesToDisplay-1) && (i !== totNumber - 1)
              ? ", "
              : ( (totNumber - noOfNamesToDisplay) > 0
                  ? ` ${othersText[0]} ${totNumber - noOfNamesToDisplay} ${othersText[1]}`
                  : ""  ) }
          </h4>
        </Fragment>
      )
    }) }
    </Fragment>
  )

  return (
    <Fragment>
    { propertyExists(value) &&
      <div>
      { label &&
        <KPLabel label={label} />}
        <div className={`kp-multi-profiles-display ${className}`} style={{display: 'flex', alignItems: 'center'}}>
        { /*gVal_fromPathAry(value[0], imgValPath) && gVal_fromPathAry(value[0], imgValPath).imgData[0] &&
          <ProfileImgDisplay avatar={{ userUploaded: gVal_fromPathAry(value[0], imgValPath) }} size='3rem' />  */}
        {/* <ProfileImgDisplay
          placeholderInitial= {
            gVal_fromPathAry(value[0], nameValPath) &&
            gVal_fromPathAry(value[0], nameValPath).charAt(0).toUpperCase()
          }
          avatar={{
            userUploaded: gVal_fromPathAry(value[0], imgValPath)
          }}
          size='2.5rem'
          /> */}
        { genContent()  }
        </div>
      </div> }
    </Fragment>
  );
}

KPMultiProfilesDisplay.defaultProps = {
  textStyle : 'h5'
}

export default KPMultiProfilesDisplay
