import {
  SET_UPDATE_TPL_LOADING,
  SET_UPDATE_TPL,
  SET_EMPTY_FIELD_ERROR,
  SET_UPDATE_CONTENT_UPDATE,
  SELECT_UPDATE_BLOCK,
  PUBLISH_UPDATED_CONTENT,
  RESET_UPDATE_INITIAL_STATE,
  PUBLISH_UPDATED_CONTENT_START,
  RESET_UPDATED_CONTENT,
} from "../actions/types";

const initialState = {
  tpl: null,
  mode: null,
  errors: ["defaultError"], /** for disabling update buton on first render */
  content: { main: {}, meta: {} },
  contentSubTypeList: [],
  selectedContentSubType: null,
  published: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RESET_UPDATE_INITIAL_STATE:
      return {
        ...initialState,
      };
    case SET_UPDATE_TPL_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case SET_UPDATE_TPL:
      return {
        ...state,
        tpl: payload.tpl,
        contentSubTypeList: getContentSubTypeList(
          payload.tpl,
          payload.content.meta.kp_language
        ),
        mode: payload.mode,
        content: payload.content,
        loading: false,
      };

    case SELECT_UPDATE_BLOCK:
      return {
        ...state,
        selectedContentSubType: payload,
        // content: { main: {}, meta: { kp_language: setTplLang(null, "updates") } }
      };

    case SET_UPDATE_CONTENT_UPDATE:
      return {
        ...state,
        content: payload,
        contentSubTypeList: getContentSubTypeList(
          state.tpl,
          state.content.meta.kp_language
        ),
      };

    case SET_EMPTY_FIELD_ERROR:
      return {
        ...state,
        errors: payload,
      };
    case PUBLISH_UPDATED_CONTENT_START:
      return {
        ...state,
        published: payload,
      };
    case PUBLISH_UPDATED_CONTENT:
      return {
        ...state,
        published: payload,
      };
    case RESET_UPDATED_CONTENT:
      return{
        ...state,
        content: payload
      }
    default:
      return state;
  }
}

const getContentSubTypeList = (payload, activeLang) => {

  const subjectSelect = payload.kp_settings.filter(
    (data) => data.sectionName === "subjectSelect"
  );
  const options = subjectSelect[0].blocks[0].props.options;
  const lists = [];
  options.map((data) => {
    let type = {
      title: data.title.locale[activeLang.value],
      display: data.title.locale[activeLang.value],
      subtitle: data.subtitle.locale[activeLang.value],
      value: data.value,
      status: data.status,
      desc: data.subtitle.locale[activeLang.value],
    };

    lists.push(type);
  });
  return lists;
};
