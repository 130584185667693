import React, { useState, useEffect, Fragment } from "react";

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import store from '../../../store';

import { KPTextInput } from '../../inputs/KPInputs';
import {ButtonSecondary} from '../../generalUI/KPButtons';
import ToggleBar from '../../generalUI/ToggleBar';
import ModalShell from '../../modals/ModalShell';
import ModalHeader from '../../modals/ModalHeader';
import Loader from '../../generalUI/Loader';
import {setAlert} from '../../../actions/alert'
//actions
import {getOgs, resetOgs} from '../../../actions/ogs'
//config
import { __GetContentTypeConfig } from '../../../utils/gettersV2';
import { _ProfileTypes } from '../../../_configs/profileTypes/config';
//utils
import { set_getValOffQueryString } from '../../../utils/general';

const KPExternalResSelect = ({
  id,
  onConfirm : parentOnConfirm,
  onCloseModal,
  // from redux
  ogs,
  getOgs
}) => { //col settings must be the bootstrap codes

  //--api call / fetch from store as per config
  //searchbar to search through list
  //dropdown to select specific content type or all
  //prop to set max limit of select
  //selected preview

  //--parent comp that allow to trigger this modal
  //--parent 'value' state var, which gets updated when selections here are confirmed
  //--appropriate cards rendered as per 'value'


  const [inputString, setInputString] = useState('');
  const handleSetInputString = value => setInputString(value)

  const handlePreviewLink = () => inputString !== '' && getOgs(inputString)

  const handleInsertLink = () => {
    if(ogs.ogsMeta === null && inputString !== ''){
      getOgs(inputString, (ogsMeta) => {
        parentOnConfirm({type: 'external', data: ogsMeta});
        store.dispatch(resetOgs());
        onCloseModal()
      })
    }else{
      parentOnConfirm({type:'external', data: ogs.ogsMeta })
      store.dispatch(resetOgs());
      onCloseModal()
    }

  }

  useEffect(() => { //PENDING: when the component mounts refetch the link so that you get the latest data connected to that link...or not... for later anyway...

  },[])


  return (
      <Fragment>
        <div className='kp-external-resource-select'>
          <KPTextInput
            id = 'card_link_input_external'
            placeholder = 'write/paste your link here'
            value = {inputString}
            onChange = {(k,v)=>handleSetInputString(v)}
            actionBtn = {{
              onClick: () => handlePreviewLink(),
              text: 'Preview Link'
            }}
            />
          { ogs.loading
            ? <Loader type='inline-loader' />
            : ogs.error
              ? <h4 className='h3'>woops! unable to load a link for the above url</h4>
              : ogs.ogsMeta
                ? <div className='kp-link-input-view__preview-container'>
                    <h4 className='h5 bold u-case ls-10 kp-link-input-view__preview-container'>preview</h4>
                    <div style={{display: 'flex'}}>
                      <div style={{width: '150px'}}>
                        <img
                          style={{width: '100%', height: 'auto'}}
                          src={
                            ogs.ogsMeta.data.data.ogImage.url && (
                              ogs.ogsMeta.data.data.ogImage.url.includes('/static/')
                              ? ogs.ogsMeta.data.requestUrl+ogs.ogsMeta.data.data.ogImage.url
                              : ogs.ogsMeta.data.data.ogImage.url
                            )
                             }
                          alt="" />
                      </div>
                      <div>
                      <h4 className='h4 bold'>{ogs.ogsMeta.data.data.ogTitle}</h4>
                      <h4 className='h4'>{ogs.ogsMeta.data.data.ogDescription}</h4>
                      </div>
                    </div>
                  </div>
                : <h4>paste a link about to see a preview of it here</h4> }
          {/*need a card comp that is generated here as a preview by drawing in the ogs*/}
          </div>
        <ButtonSecondary disabled = {(inputString.length > 0) === false} onClick={() => handleInsertLink() }>Insert Link</ButtonSecondary>
      </Fragment>
  )
}

KPExternalResSelect.propTypes = {
  ogs: PropTypes.object.isRequired,
  getOgs: PropTypes.func.isRequired
};

KPExternalResSelect.defaultProps = {

}

const mapStateToProps = state => ({
  ogs: state.ogs
});

export default connect(mapStateToProps, {getOgs})(KPExternalResSelect);
