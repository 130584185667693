import React from "react"

function ToolkitsIcon(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19.492 7.164l2.383.562a.2.2 0 01.125.3l-1.282 2.091a.2.2 0 00.054.267l2.002 1.44a.2.2 0 010 .325l-2.002 1.44a.2.2 0 00-.054.267l1.283 2.093a.2.2 0 01-.123.3l-2.415.586a.2.2 0 00-.15.225l.383 2.434a.2.2 0 01-.229.229l-2.432-.383a.2.2 0 00-.226.152l-.562 2.383a.2.2 0 01-.3.125l-2.091-1.282a.2.2 0 00-.267.054l-1.44 2.002a.2.2 0 01-.325 0l-1.44-2.002a.2.2 0 00-.267-.054l-2.093 1.283a.2.2 0 01-.299-.123l-.587-2.415a.2.2 0 00-.225-.15l-2.434.383a.2.2 0 01-.229-.229l.383-2.432a.2.2 0 00-.151-.226L2.1 16.247a.2.2 0 01-.124-.3l1.304-2.09a.2.2 0 00-.053-.269l-2.002-1.44a.2.2 0 01-.001-.324l1.979-1.44a.2.2 0 00.053-.266L1.972 8.024a.2.2 0 01.123-.299l2.415-.587a.2.2 0 00.15-.225l-.383-2.434a.2.2 0 01.229-.229l2.432.383a.2.2 0 00.226-.151L7.726 2.1a.2.2 0 01.3-.124l2.09 1.304a.2.2 0 00.27-.053l1.44-2.002a.2.2 0 01.323-.001l1.44 1.979a.2.2 0 00.266.053l2.094-1.284a.2.2 0 01.3.123l.586 2.415a.2.2 0 00.225.15l2.434-.383a.2.2 0 01.229.229l-.383 2.432a.2.2 0 00.152.226z"
        fill="none"
        stroke="#E3F2FD"
      />
      <path
        d="M11.58 14.102c0-.477.06-.856.177-1.14.119-.282.351-.592.699-.928.35-.34.572-.582.666-.725.143-.219.214-.455.214-.71 0-.336-.084-.592-.252-.767-.165-.18-.408-.269-.73-.269-.308 0-.557.088-.747.263-.186.172-.28.407-.28.704h-1.304c.007-.634.222-1.135.644-1.504.426-.369.988-.553 1.687-.553.72 0 1.28.182 1.68.548.405.365.608.875.608 1.53 0 .584-.272 1.159-.817 1.724l-.66.65c-.237.269-.358.66-.366 1.177H11.58zm-.091 1.67a.68.68 0 01.198-.51c.133-.133.312-.199.538-.199.229 0 .41.068.542.204.133.133.199.3.199.505a.68.68 0 01-.193.494c-.13.133-.312.199-.548.199-.237 0-.42-.066-.548-.199a.69.69 0 01-.188-.494z"
        fill="#fff"
      />
    </svg>
  )
}

export default ToolkitsIcon