import React, {Fragment} from "react";


const BodySans__M__Bold = (props) => {

  

  return (
    <h5 className={`bodySans--M--Bold ${props.className || ''}`} style={props.style} id={props.id || ''}>{props.children}</h5>
  );

}



export default BodySans__M__Bold;
