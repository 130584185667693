import * as React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function AddLG(props) {
  return (
    <svg width={42} height={42} viewBox="0 0 42 42" fill="none" {...props}>
      <circle cx={21} cy={21} r={21} fill={props.backgroundFill || "none"} />
      <path
        stroke={props.stroke || primaryColor100}
        strokeWidth='2px'
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.5 20.5h14.556M20.985 27.571V13.486"
      />
    </svg>
  )
}

export default AddLG
