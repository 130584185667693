import React from "react"
import {primaryColor100} from '../../../sass/vars.scss';

function ChevronLeft(props) {
  return (
    <svg width={7} height={12} viewBox="0 0 7 12" fill="none">
      <path d="M6 1L1 6l5 5" stroke={props.stroke || primaryColor100} strokeWidth={2} />
    </svg>
  )
}

export default ChevronLeft
